import {
  BILLING_ACCOUNT_MAX_LENGTH,
  BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH,
  ELECTRONIC_ADDRESS_REGEX,
} from './validationUtils.js';
import {
  BillingAccount,
  BillingAccountDeliveryMethod,
  ContactRole,
  ContactType,
  DuplicateContactResult,
} from '../../generated/api/models.js';
import { CREATE_NEW_CONTACT_OPTION_VALUE } from '../react-hook-form/fields/ContactDropdown.js';
import { addEmptyFieldValidationError, addErrorsFromUpsertAddress, addValidationError } from './errorUtils.js';
import {
  billingAccountExtensionNameMsg,
  costCenterMsg,
  eInvoiceMsg,
  emailInvoiceMsg,
  englishMsg,
  fieldValueTooLong,
  finnishMsg,
  notAbleToProcessContactRequestMsg,
  paperInvoiceMsg,
  payerDetailsMsg,
  pcsMsg,
  referenceMsg,
  swedishMsg,
  t,
} from '../i18n/index.js';
import { createBillingAccount, upsertContactUser } from '../fetch.js';
import { formatSum } from './priceUtils.js';
import type * as CLT from '@design-system/component-library';
import type {
  BillChannel,
  BillingAccountHeader,
  Contact,
  DuplicateContact,
  GenericErrorDuplicateContact,
  PostPersonBillingAccountRequest,
  PutContactResponse,
} from '../../generated/api/models.js';
import type { BillingAccountCreateFormValues } from '../../components/CreateBillingAccount/CreateBillingAccount.js';
import type { BillingAccountsState } from '../types/states.js';
import type { CommonError } from '../types/errors.js';
import type { WizardParamsLite } from '../types/wizard.js';

export enum BillChannelType {
  PAPER = 'paper',
  EMAIL = 'email',
  NETWORK = 'network',
}
export type UpsertPersonBillingAccountRequest = Omit<
  PostPersonBillingAccountRequest,
  'contactId' | 'personalIdentityCode'
>;

export enum BillingAccountSourceSystem {
  SFDC = 'SFDC',
  MIPA = 'MIPA',
  TELLUS = 'TELLUS',
}

export type ReceiverType = 'SAME_AS_CONTACT' | 'OTHER';

export const ReceiverTypes = {
  SAME_AS_CONTACT: 'SAME_AS_CONTACT' as ReceiverType,
  OTHER: 'OTHER' as ReceiverType,
};

export interface BaInvoiceType {
  displayValue: () => string;
  value: string;
}

export interface BillingAccountOption {
  displayValue?: string;
  value: string;
}

export const billingAccountInvoiceTypes: BaInvoiceType[] = [
  {
    displayValue: () => t.U5N7('Consolidated invoice with itemised details'),
    value: BillingAccount.BillFormatTypeEnum.CONSOLIDATED_INVOICE_WITH_ITEMIZATION,
  },
  {
    displayValue: () => t.OWUZ('Consolidated invoice without itemised details'),
    value: BillingAccount.BillFormatTypeEnum.CONSOLIDATED_INVOICE_WITHOUT_ITEMIZATION,
  },
  {
    displayValue: () => t.D9PT('Cost center invoice'),
    value: BillingAccount.BillFormatTypeEnum.COST_CENTRE_INVOICE,
  },
  {
    displayValue: () => t.UEMQ('Cost center invoice with itemised details'),
    value: BillingAccount.BillFormatTypeEnum.COST_CENTRE_INVOICE_WITH_ITEMIZATION,
  },
];

// FIXME why isn't this part of BillingAccountSourceSystem, if it's a possible value for it?
export const ConsumerBA = 'Consumer BA-SFDC';

export const CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE = 'ADD_NEW';

export const findBillingAccountById = (
  id: string,
  useDisplayId: boolean,
  billingAccounts?: BillingAccount[],
  invoicesBillingAccounts?: BillingAccount[]
): BillingAccount | undefined => {
  let billingAccount: BillingAccount | undefined;
  if (billingAccounts) {
    billingAccount = billingAccounts.find(({ billingAccountId, billingAccountDisplayId }) =>
      useDisplayId ? billingAccountDisplayId === id : billingAccountId === id
    );
  }
  if (!billingAccount && invoicesBillingAccounts) {
    billingAccount = invoicesBillingAccounts.find(({ billingAccountId, billingAccountDisplayId }) =>
      useDisplayId ? billingAccountDisplayId === id : billingAccountId === id
    );
  }
  return billingAccount;
};

export const isBillingAccountInSfdc = (billingAccount: BillingAccount | BillingAccountHeader): boolean => {
  return billingAccount.sourceSystem === BillingAccountSourceSystem.SFDC;
};

export const getFilteredBillingAccounts = (
  billingAccountState?: BillingAccountsState
): Array<BillingAccountHeader> | undefined => {
  return billingAccountState?.searchResults?.map(searchResult => searchResult.result)?.filter(isBillingAccountInSfdc);
};

export const getDefaultBillingAccountId = (
  billingAccounts: Array<BillingAccount | BillingAccountHeader> | undefined
) => {
  if (!billingAccounts || billingAccounts.length === 0) {
    return CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE;
  }
  return billingAccounts.reduce(
    (acc: BillingAccount | BillingAccountHeader, val: BillingAccount | BillingAccountHeader) => {
      if (!acc) {
        return val;
      }
      // Use the billing account with the latest modified is last as default
      return acc.lastModified! > val.lastModified! ? acc : val;
    }
  ).billingAccountId!;
};

export const filterAndGetDefaultBillingAccountId = (billingAccountState?: BillingAccountsState): string => {
  return getDefaultBillingAccountId(getFilteredBillingAccounts(billingAccountState));
};

export const getDefaultDeliveryMethod = (
  billingAccounts?: Array<BillingAccount | BillingAccountHeader>
): BillingAccountDeliveryMethod | undefined => {
  if (!billingAccounts) {
    return undefined;
  }

  const deliveryMethods = new Set(billingAccounts.map(ba => ba.deliveryMethod));

  if (deliveryMethods.size === 1) {
    return deliveryMethods.values().next().value;
  }

  return undefined;
};

export const getDefaultBillingContactId = (
  billingAccounts?: Array<BillingAccount | BillingAccountHeader>
): string | undefined => {
  if (!billingAccounts) {
    return undefined;
  }

  const billingContactIds = new Set(billingAccounts.map(ba => ba.billingContactId));

  if (billingContactIds.size === 1) {
    return billingContactIds.values().next().value;
  }

  return undefined;
};

export const formatDisplayBillingAccountOption = (
  ba: BillingAccount | BillingAccountHeader,
  detailedView: boolean
): string => {
  if (!detailedView) {
    return `${ba.billingAccountDisplayId} ${ba.payerName}`;
  } else {
    return [
      ba.billingAccountDisplayId,
      ba.payerName,
      ba.payerNameExtension,
      ba.customerReference1,
      ba.customerReference2,
    ]
      .filter(Boolean)
      .join(' \u00A0\u00A0\u00A0\u00A0 ');
  }
};

const getCreateNewBillingAccountOption = (addNewText: string): BillingAccountOption => ({
  displayValue: addNewText,
  value: CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
});

/**
 * For RP.ComboBox we need something like this.
 */
export const formatBillingAccountOptions = (
  billingAccounts: Array<BillingAccount | BillingAccountHeader>,
  addNewText?: string,
  detailedView?: boolean
): {
  displayOptions: string[];
  options: BillingAccountOption[];
} => {
  const displayOptions: string[] = [];
  const options: BillingAccountOption[] = [];
  if (addNewText) {
    const createNewBillingAccountOption = getCreateNewBillingAccountOption(addNewText);
    displayOptions.push(createNewBillingAccountOption.displayValue!);
    options.push(createNewBillingAccountOption);
  }
  billingAccounts.forEach(ba => {
    const displayValue: string = formatDisplayBillingAccountOption(ba, detailedView ?? false);
    const value: string = ba.billingAccountId!;
    displayOptions.push(displayValue);
    options.push({ displayValue, value });
  });
  return {
    displayOptions,
    options,
  };
};

export const billingAccountNonVisibleFields = [
  {
    name: 'customerReference1',
    getTitle: () => `${t.ZLAU(referenceMsg)} 1`,
  },
  {
    name: 'customerReference2',
    getTitle: () => `${t.ZLAU(referenceMsg)} 2`,
  },
  {
    name: 'customerReference3',
    getTitle: () => `${t.ZLAU(referenceMsg)} 3`,
  },
  {
    name: 'customerReference4',
    getTitle: () => `${t.ZLAU(referenceMsg)} 4`,
  },
  {
    name: 'billingAccountExtensionName',
    getTitle: () => t.KUTS(billingAccountExtensionNameMsg),
  },
  {
    name: 'payerNameExtension',
    getTitle: () => t.YLAI(payerDetailsMsg),
  },
  {
    name: 'billingAccountSubscriptionsCostCenter',
    getTitle: () => t.L2OG(costCenterMsg),
  },
];

export const getContactDisplayValue = (contact?: Contact): string => {
  if (contact?.person && contact.contactType === ContactType.PERSON) {
    const { firstName, lastName } = contact.person;
    return `${firstName || ''} ${lastName || ''}`;
  } else {
    return contact?.commonFunction?.name || '';
  }
};

export const findContact = (billingAccount: BillingAccount, contacts: Contact[] = []) => {
  return contacts.find(({ contactId }) => contactId === billingAccount.billingContactId);
};

export const getDeliveryMethod = (method: string): string => {
  switch (method) {
    case BillingAccountDeliveryMethod.ELECTRONIC.toString():
      return t.IK1D(eInvoiceMsg);
    case BillingAccountDeliveryMethod.EMAIL.toString():
      return t.NNV9(emailInvoiceMsg);
    case BillingAccountDeliveryMethod.PAPER.toString():
      return t.TER4(paperInvoiceMsg);
    default:
      return t.C6WY('Other');
  }
};

export const getElectronicInvoiceOperatorOptions = (billChannels?: BillChannel[]) => {
  if (billChannels) {
    const networkBillChannel = billChannels.find(billChannel => billChannel.type === 'network');
    if (networkBillChannel && networkBillChannel.operators) {
      return networkBillChannel.operators.map(operator => ({
        displayValue: `${operator.name} (${operator.id})`,
        value: operator.id,
      }));
    }
  }
  return [];
};

export const getElectronicInvoiceOperatorDisplayValue = (
  electronicInvoiceOperatorValue: string,
  billChannels?: BillChannel[]
): string => {
  return (
    getElectronicInvoiceOperatorOptions(billChannels)?.find(b => b.value === electronicInvoiceOperatorValue)
      ?.displayValue ?? ''
  );
};

export const getDeliveryMethodDisplayValue = (deliveryMethod: BillingAccountDeliveryMethod) => {
  switch (deliveryMethod) {
    case BillingAccountDeliveryMethod.EMAIL:
      return t.NNV9(emailInvoiceMsg);
    case BillingAccountDeliveryMethod.PAPER:
      return t.TER4(paperInvoiceMsg);
    case BillingAccountDeliveryMethod.ELECTRONIC:
      return t.IK1D(eInvoiceMsg);
    default:
      return BillingAccountDeliveryMethod.UNKNOWN.toString();
  }
};

export const getDeliveryMethodOptions = (paperBillPrice?: string, deliveryMethodCustomType?: string | undefined) => {
  const paperBillDisplayValue = deliveryMethodCustomType
    ? deliveryMethodCustomType
    : `${t.TER4(paperInvoiceMsg)} (${paperBillPrice})`;

  return [
    {
      displayValue: t.NNV9(emailInvoiceMsg),
      value: BillingAccountDeliveryMethod.EMAIL.toString(),
    },
    {
      displayValue: paperBillDisplayValue,
      value: BillingAccountDeliveryMethod.PAPER.toString(),
    },
    {
      displayValue: t.IK1D(eInvoiceMsg),
      value: BillingAccountDeliveryMethod.ELECTRONIC.toString(),
    },
  ];
};

export const getErrorsFromUpsertBillingAccount = (
  upsertedBillingAccount: BillingAccount,
  validationErrors?: CommonError[],
  commonFunction?: Contact,
  isAnonymousView = true
) => {
  const errors: CommonError[] = [];

  // Payer address
  addErrorsFromUpsertAddress(errors, upsertedBillingAccount.payerAddress, 'payerAddress');

  // Delivery method
  if (upsertedBillingAccount.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC) {
    if (!upsertedBillingAccount.billElectronicAddress || upsertedBillingAccount.billElectronicAddress.length === 0) {
      addEmptyFieldValidationError(errors, 'billElectronicAddress');
    }
    if (!ELECTRONIC_ADDRESS_REGEX.test(upsertedBillingAccount.billElectronicAddress!)) {
      addValidationError(
        errors,
        t.NMXU(
          'Check the format of the e-invoicing address. The correct format begins with 0037, TE0037, or FI. The e-invoicing address may contain the letters A-Z and the numbers 0-9.'
        ),
        'billElectronicAddress'
      );
    }
    if (!upsertedBillingAccount.billElectronicOperator || upsertedBillingAccount.billElectronicOperator.length === 0) {
      addEmptyFieldValidationError(errors, 'billElectronicOperator');
    }
  } else if (upsertedBillingAccount.deliveryMethod === BillingAccountDeliveryMethod.EMAIL) {
    if (upsertedBillingAccount.billReceiverType === ContactType.COMMON_FUNCTION) {
      if (!upsertedBillingAccount.billReceiverName || upsertedBillingAccount.billReceiverName.length === 0) {
        addEmptyFieldValidationError(errors, 'billReceiverName');
      }
    }
  }

  // Validations for creating new BA
  if (!upsertedBillingAccount.billingAccountId && isAnonymousView) {
    // Billing contact
    if (!upsertedBillingAccount.billingContactId || upsertedBillingAccount.billingContactId.length === 0) {
      addEmptyFieldValidationError(errors, 'billingContactId');
    }
  }

  if (!upsertedBillingAccount.billingAccountName) {
    addEmptyFieldValidationError(errors, 'billingAccountName');
  } else if (upsertedBillingAccount.billingAccountName.length > BILLING_ACCOUNT_MAX_LENGTH) {
    addValidationError(errors, t.XOYE(fieldValueTooLong, `${BILLING_ACCOUNT_MAX_LENGTH}`), 'billingAccountName');
  }

  if ((upsertedBillingAccount.billingAccountExtensionName?.length ?? 0) > BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH) {
    addValidationError(
      errors,
      t.XOYE(fieldValueTooLong, `${BILLING_ACCOUNT_NAME_EXT_MAX_LENGTH}`),
      'billingAccountExtensionName'
    );
  }

  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
};

export const getReceiverType = (billingAccount: BillingAccountCreateFormValues | BillingAccount | undefined) =>
  !billingAccount?.billReceiverId || billingAccount?.billReceiverId === billingAccount?.billingContactId
    ? ReceiverTypes.SAME_AS_CONTACT
    : ReceiverTypes.OTHER;

export const prepareBillingAccountSave = (
  billingAccount: BillingAccount,
  values: Partial<BillingAccount>,
  receiverType: ReceiverType
) => {
  const updatedBillingAccount = { ...billingAccount, ...values };

  if (updatedBillingAccount.deliveryMethod !== BillingAccountDeliveryMethod.EMAIL) {
    // Delete all bill receiver fields from the payload, as they might have been left behind from previous edits
    // and aren't currently used for anything else than email
    delete updatedBillingAccount.billReceiverId;
    delete updatedBillingAccount.billReceiverType;
    delete updatedBillingAccount.billReceiverName;
  } else if (updatedBillingAccount.billReceiverType === undefined) {
    // This means that the default value for EMAIL is used which is PERSON but the as no clicking has been done, the
    // object has not been updated.
    updatedBillingAccount.billReceiverType = ContactType.PERSON;
  }

  if (receiverType === ReceiverTypes.SAME_AS_CONTACT) {
    // Set the name and id back to the same as the billingContact
    updatedBillingAccount.billReceiverId = updatedBillingAccount.billingContactId;
    updatedBillingAccount.billReceiverName = updatedBillingAccount.billingContactName;
  }

  return updatedBillingAccount;
};

export const onBillingAccountSaveValues = (
  billingAccount: BillingAccount,
  values: Partial<BillingAccount>,
  onUpsertBillingAccount: (
    updatedBillingAccount: BillingAccount,
    validationErrors?: CommonError[],
    wizardParams?: WizardParamsLite
  ) => void,
  receiverType: ReceiverType,
  validationErrors?: CommonError[],
  wizardParams?: WizardParamsLite
) => {
  const updatedBillingAccount = prepareBillingAccountSave(billingAccount, values, receiverType);
  onUpsertBillingAccount(updatedBillingAccount, validationErrors, wizardParams);
};

export const searchContacts = (searchText: string, contacts?: Contact[]): Contact[] => {
  const searchTextLowerCase = searchText.toLowerCase();
  return contacts
    ? contacts?.filter(
        c =>
          c.person?.phoneNumber?.includes(searchTextLowerCase) ||
          c.person?.email?.toLocaleLowerCase().includes(searchTextLowerCase) ||
          c.person?.firstName?.toLowerCase().includes(searchTextLowerCase) ||
          c.person?.lastName?.toLowerCase().includes(searchTextLowerCase)
      )
    : [];
};

export const getPaperBillPrice = (billChannels?: BillChannel[]): string | undefined => {
  if (billChannels) {
    const paperBillChannel = billChannels.find(billChannel => billChannel.type === BillChannelType.PAPER);
    if (paperBillChannel) {
      return `${formatSum(paperBillChannel.price)}/${t.B3MG(pcsMsg)}`;
    }
  }
  return undefined;
};

export const getPaperBillDeliveryMethodCustomType = (
  billingAccount: BillingAccount,
  paperBillPrice?: string
): string | undefined => {
  const paperPrice = paperBillPrice || '?';
  const directPaymentPrice = formatSum(billingAccount.billPrice) || paperPrice;
  const directPaymentPaperInvoiceCustomType = `${t.NY9Q(
    'Direct payment + paper invoice'
  )} (${directPaymentPrice}/${t.B3MG('invoice')})`;

  const paperInvoiceCustomType = `${t.TER4(paperInvoiceMsg)} (${formatSum(billingAccount.billPrice)}/${t.B3MG(
    'invoice'
  )})`;

  if (billingAccount.deliveryMethodCustomType === 'DIRECT') {
    return directPaymentPaperInvoiceCustomType;
  } else if (billingAccount.billPrice) {
    return paperInvoiceCustomType;
  }
  return undefined;
};

export const getDeliveryMethodDropDownOptions = (
  billingAccount: BillingAccount,
  billChannels?: BillChannel[]
): Array<CLT.DropDownItem> => {
  const paperBillPrice = getPaperBillPrice(billChannels);
  const paperBillDisplayValue =
    getPaperBillDeliveryMethodCustomType(billingAccount, paperBillPrice) ||
    `${t.TER4(paperInvoiceMsg)} (${paperBillPrice})`;

  return [
    {
      value: BillingAccountDeliveryMethod.EMAIL,
      label: t.NNV9(emailInvoiceMsg),
    },
    {
      value: BillingAccountDeliveryMethod.PAPER,
      label: paperBillDisplayValue,
    },
    {
      value: BillingAccountDeliveryMethod.ELECTRONIC,
      label: t.IK1D(eInvoiceMsg),
    },
  ];
};

export const getLanguageOptions = () => {
  return [
    { label: t.EXXX(finnishMsg), value: 'FI' },
    { label: t.CYY2(swedishMsg), value: 'SV' },
    { label: t.R1PB(englishMsg), value: 'EN' },
  ];
};

export const getElectronicInvoiceOperatorDropDownOptions = (billChannels?: BillChannel[]): CLT.DropDownItem[] => {
  const networkBillChannel = billChannels?.find(billChannel => billChannel.type === BillChannelType.NETWORK);
  return (
    networkBillChannel?.operators?.map(operator => {
      return {
        value: operator.id,
        label: `${operator.name} (${operator.id})`,
      };
    }) || []
  );
};

export const getEmptyBillingAccount = (payerName: string) => ({
  billFormatType: BillingAccount.BillFormatTypeEnum.CONSOLIDATED_INVOICE_WITH_ITEMIZATION,
  billLanguage: 'FI',
  billingContactId: '',
  deliveryMethod: BillingAccountDeliveryMethod.ELECTRONIC,
  payerAddress: {
    countryCode: 'FIN',
    line1: '',
    postOffice: '',
    postalCode: '',
  },
  payerName,
  billingContactPhone: '',
  billElectronicAddress: '',
});

export interface ContactPersonFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface DuplicateContactResponse {
  type: DuplicateContact.DuplicateTypeEnum;
  duplicateContact?: GenericErrorDuplicateContact;
}

export const isDuplicateContactError = (response: PutContactResponse | DuplicateContactResponse) =>
  'type' in response &&
  (response.type === DuplicateContactResult.TypeEnum.DUPLICATE_CONTACT ||
    response.type === DuplicateContactResult.TypeEnum.POSSIBLE_CONTACT_DUPLICATE);

const createContact = async (
  contactFormValues: ContactPersonFormValues,
  mdmId?: string
): Promise<PutContactResponse | DuplicateContactResponse> => {
  const { email, firstName, lastName, phoneNumber } = contactFormValues;

  const contact = {
    contactType: ContactType.PERSON,
    person: {
      email,
      firstName,
      lastName,
      phoneNumber,
      roles: [ContactRole.BILLING_CONTACT],
    },
  };

  const response = await upsertContactUser(contact, false, false, true, mdmId);
  const responseData = await response.json();

  if (response.ok || isDuplicateContactError(responseData)) {
    return responseData;
  }

  if (response.status === 409 && responseData.type === DuplicateContactResult.TypeEnum.CONTACT_SUPPORT) {
    throw new Error(t.EGFA(notAbleToProcessContactRequestMsg));
  }

  throw new Error(t.RG74('Failed to create the new contact.'));
};

interface CreateBillingAccountArgs {
  newBillingAccount: BillingAccount;
  billReceiverSelection: ReceiverType;
  newContact: ContactPersonFormValues;
  newBillReceiverContact: ContactPersonFormValues;
  contacts?: Contact[];
  setDuplicateContactFieldName: (
    fieldName: 'billingAccount.billingContactId' | 'billingAccount.billReceiverId'
  ) => void;
  setSubmittedBillingContactId: (contactId?: string) => void;
  submittedBillingContactId?: string;
  mdmId?: string;
}

export const createNewBillingAccount = async ({
  newBillingAccount,
  billReceiverSelection,
  newContact,
  newBillReceiverContact,
  contacts,
  setDuplicateContactFieldName,
  setSubmittedBillingContactId,
  submittedBillingContactId,
  mdmId,
}: CreateBillingAccountArgs) => {
  let effectiveContact;
  if (newBillingAccount.billingContactId === CREATE_NEW_CONTACT_OPTION_VALUE) {
    const upsertContactResponse = await createContact(newContact, mdmId);
    if (isDuplicateContactError(upsertContactResponse)) {
      setDuplicateContactFieldName('billingAccount.billingContactId');
      return upsertContactResponse;
    }
    effectiveContact = upsertContactResponse as PutContactResponse;
    setSubmittedBillingContactId(effectiveContact.contactId);
  } else {
    effectiveContact = contacts?.find(c => c.contactId === newBillingAccount.billingContactId);
  }

  if (!effectiveContact && !submittedBillingContactId) {
    throw new Error(t.WIMV('Selected contact is not valid, please check the selection.'));
  }

  newBillingAccount.billingContactId = effectiveContact?.contactId || submittedBillingContactId;

  if (newBillingAccount.deliveryMethod === BillingAccountDeliveryMethod.EMAIL) {
    if (billReceiverSelection === ReceiverTypes.SAME_AS_CONTACT) {
      newBillingAccount.billReceiverId = effectiveContact?.contactId || submittedBillingContactId;
    } else if (newBillingAccount.billReceiverId === CREATE_NEW_CONTACT_OPTION_VALUE) {
      const upsertContactResponse = await createContact(newBillReceiverContact, mdmId);
      if (isDuplicateContactError(upsertContactResponse)) {
        setDuplicateContactFieldName('billingAccount.billReceiverId');
        return upsertContactResponse;
      }
      newBillingAccount.billReceiverId = (upsertContactResponse as PutContactResponse).contactId;
    }
  } else {
    delete newBillingAccount.billReceiverId;
    delete newBillingAccount.billReceiverType;
  }

  try {
    return await createBillingAccount(newBillingAccount, mdmId);
  } catch (err) {
    throw new Error(t.EBJW('Adding a new billing account failed. Please try again later.'));
  }
};
