import * as CL from '@design-system/component-library';
import { forwardRef, useId } from 'react';
import type { InputHTMLAttributes, ReactNode, Ref } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  error?: string;
  hint?: ReactNode;
  label?: ReactNode;
  tooltip?: ReactNode;
  wrapperClassName?: string;
}

export const Input = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  const id = useId();
  const { children, error, hint, label, tooltip, wrapperClassName, ...rest } = props;

  return (
    <div
      className={[
        'ds-input',
        error && 'ds-input-error',
        rest.disabled && 'ds-input-disabled',
        rest.readOnly && 'ds-input-readonly',
        wrapperClassName,
      ]
        .filter(i => i)
        .join(' ')}
    >
      <div className="ds-input--labelarea">
        {label && (
          <>
            <label id={`${id}-label`} className="ds-input--labelarea-label" htmlFor={`${id}-input`}>
              {label}
            </label>
            {tooltip && tooltip}
          </>
        )}
      </div>
      <div className="ds-input--inputarea">
        <input
          {...rest}
          ref={ref}
          id={`${id}-input`}
          className={['ds-input--inputarea-input', props.className].filter(i => i).join(' ')}
          aria-labelledby={props.label ? `${id}-label` : undefined}
          aria-invalid={Boolean(error)}
          aria-errormessage={error ? `${id}-error` : undefined}
          aria-describedby={[error && `${id}-error`, hint && `${id}-hint`].filter(i => i).join(' ')}
        />
        {children}
      </div>
      {error && (
        <p
          id={`${id}-error`}
          className="ds-inputerror ds-display--inline-flex ds-align-items--center"
          role="alert"
          aria-live="polite"
        >
          <CL.Icon color="error" icon="error" size="s" className="ds-margin-right--1" />
          <span>{error}</span>
        </p>
      )}
      {hint && (
        <p id={`${id}-hint`} className="ds-inputhelp">
          {hint}
        </p>
      )}
    </div>
  );
});
