import { paths } from '../constants/pathVariables.js';

export const getBrandPagePath = (brand?: string): null | string => {
  const knownBrandsWithPages = [
    'apple',
    'asus',
    'beats',
    'burrel',
    'caterpillar',
    'dji',
    'doro',
    'emax',
    'emporia',
    'epos',
    'e-way',
    'fujitsu',
    'garmin',
    'google',
    'harman-kardon',
    'honor',
    'hp',
    'huawei',
    'insmat',
    'irobot',
    'jabra',
    'jbl',
    'lenovo',
    'lg',
    'linq',
    'logitech',
    'motorola',
    'ninebot',
    'nivona',
    'nokia',
    'nothing',
    'oneplus',
    'oppo',
    'panzerglass',
    'poco',
    'polar',
    'poly',
    'reolink',
    'samsung',
    'sandisk',
    'shokz',
    'sumup',
    'suunto',
    'targus',
    'tech21',
    'teltonika',
    'uovision',
    'vivanco',
    'whirlpool',
    'wilfa',
    'xiaomi',
    'xtorm',
    'zte',
    'zyxel',
  ];
  const brandURLMap: { [key: string]: string } = {
    google: 'google-pixel',
  };
  const cleanBrand = brand?.toLowerCase().trim().replace(/ /g, '-');
  if (cleanBrand && knownBrandsWithPages.includes(cleanBrand)) {
    const mappedBrand = brandURLMap[cleanBrand];
    return mappedBrand ? `${paths.WEB_SHOP}/${mappedBrand}` : `${paths.WEB_SHOP}/${cleanBrand}`;
  }
  return null;
};
