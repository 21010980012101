import * as CL from '@design-system/component-library';
import { DeliveryAddressType } from '../DeliveryAddress/DeliveryAddress.js';
import { DeliveryType, OnlineOrder } from '../../generated/api/models.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { shippingAddressMsg, t } from '../../common/i18n/index.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';

interface DeviceCheckoutThankYouDeliveryDetailsProps {
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  companyName: string;
  skipDelivery: boolean;
  shipmentType?: OnlineOrder.ShipmentTypeEnum;
}

const isDeliveryToAddress = (deliveryMethodType: DeliveryType) =>
  [
    DeliveryType.MATKAHUOLTO_JAKOPAKETTI,
    DeliveryType.POSTI_EXPRESS_BUSINESS,
    DeliveryType.POSTI_EXPRESS_MORNING,
  ].includes(deliveryMethodType);

export const DeviceCheckoutThankYouDeliveryDetails = ({
  deliveryDetails,
  companyName,
  skipDelivery,
  shipmentType,
}: DeviceCheckoutThankYouDeliveryDetailsProps) => {
  const isDelivery = isDeliveryToAddress(deliveryDetails.deliveryMethodType);
  return skipDelivery ? (
    <></>
  ) : (
    <div className="of-device-checkout-thankyou__delivery-details">
      <CL.Grid>
        <CL.GridRow>
          <CL.GridCol colsXS={1} colsS={1} className="of-device-checkout__images">
            <CL.Icon icon={isDelivery ? 'delivery' : 'location'} size="xl" type="light" />
          </CL.GridCol>
          <CL.GridCol colsXS={3} colsS={5}>
            <CL.Grid>
              <CL.GridRow>
                <CL.GridCol colsXS={4} colsS={5} className="ds-margin-bottom--4">
                  <h3 className="ds-margin-vertical--2">{deliveryDetails.deliveryMethodDescription}</h3>
                  {isDelivery
                    ? deliveryDetails.address && (
                        <div className="of-device-checkout-thankyou__delivery-details--address">
                          {t.IHO6(shippingAddressMsg)}:
                          <div>
                            {deliveryDetails.addressType === DeliveryAddressType.HOME_ADDRESS ? '' : companyName}
                          </div>
                          <div>{deliveryDetails.address.line1}</div>
                          <div>{deliveryDetails.address.line2}</div>
                          <div>
                            {deliveryDetails.address.postalCode}, {deliveryDetails.address.postOffice}
                          </div>
                        </div>
                      )
                    : deliveryDetails.pickupPoint?.address && (
                        <div className="of-device-checkout-thankyou__delivery-details--address">
                          {t.IHO6(shippingAddressMsg)}:<div>{deliveryDetails.pickupPoint.name}</div>
                          <div>{deliveryDetails.pickupPoint.address}</div>
                        </div>
                      )}
                  {shipmentType === OnlineOrder.ShipmentTypeEnum.TOTAL_DELIVERY && (
                    <div>
                      <h4 className="ds-h4">{t.BSE3('Delivery')}:</h4>
                      <div>{t.LMKE('When all products are available')}</div>
                    </div>
                  )}
                </CL.GridCol>
                <CL.GridCol colsXS={4} colsS={1} className="of-device-checkout-thankyou__delivery-details--price">
                  <h4 className="ds-margin-vertical--2">{formatSum(deliveryDetails.deliveryPrice)}</h4>
                </CL.GridCol>
              </CL.GridRow>
            </CL.Grid>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </div>
  );
};
