import * as CL from '@design-system/component-library';
import { AdditionalBillReceiversEdit } from '../../components/AdditionalBillReceiversEdit.js';
import { BillReceiverEdit } from '../../components/BillReceiverEdit.js';
import { DeliveryMethodAndLanguageSelection } from '../../components/DeliveryMethodAndLanguageSelection.js';
import type { Contact } from '../../../../generated/api/contact.js';

interface BillingAccountEmailDeliveryMethodProps {
  deliveryMethodOptions: CL.DropDownItem[];
  disabled?: boolean;
  contacts: Contact[];
  onBillReceiverContactCreated?: (contact: Contact) => void;
}

export const BillingAccountEmailDeliveryMethodEdit = ({
  deliveryMethodOptions,
  disabled = false,
  contacts,
  onBillReceiverContactCreated,
}: BillingAccountEmailDeliveryMethodProps) => (
  <>
    <DeliveryMethodAndLanguageSelection deliveryMethodOptions={deliveryMethodOptions} disabled={disabled} />
    <BillReceiverEdit
      disabled={disabled}
      contacts={contacts}
      onBillReceiverContactCreated={onBillReceiverContactCreated}
    />
    <CL.GridRow>
      <AdditionalBillReceiversEdit disabled={disabled} />
    </CL.GridRow>
  </>
);
