import { createContext, useContext, useState } from 'react';
import { forceInternationalPhoneNumberFormat } from '../../common/utils/phoneNumberUtils.js';
import { getLocale } from '../../common/i18n/index.js';
import { postTurbonappiPaymentInitialization, postTurbonappiValidation } from '../../common/fetch.js';
import type { ReactNode } from 'react';
import type {
  TurbonappiMsisdnActionResponse,
  TurbonappiMsisdnValidationRequest,
  TurbonappiOffer,
  TurbonappiOrderRequest,
} from '../../generated/api/models.js';

export interface TurbonappiData {
  msisdn?: string;
  msisdnInternational?: string;
  email?: string;
  offer?: TurbonappiOffer;
  isMsisdnValidated?: boolean;
  isPaid?: boolean;
  isValidationLoading?: boolean;
  isPaymentLoading?: boolean;
  failureMessage?: string;
}

export interface TurbonappiFormData {
  msisdn: string;
  offer: TurbonappiOffer;
  email: string;
}

interface TurbonappiContextData {
  turbonappiData?: TurbonappiData;
  setTurbonappiData: (data: TurbonappiData) => void;
  validateMsisdnForTurbonappi: (data: TurbonappiFormData) => Promise<boolean>;
  payTurbonappiSubscription: () => void;
}

const TurbonappiContext = createContext<TurbonappiContextData | undefined>(undefined);

const getMsisdnInInternationalFormat = (msisdn: string): string => {
  const msisdnInternational = forceInternationalPhoneNumberFormat(msisdn);
  if (msisdnInternational) {
    return msisdnInternational.substring(1);
  }
  throw new Error('MSISDN should be possible to convert to international format');
};

// We need to send the data what user has chosen/entered to the backend, so that the data can be saved to DB for later usage.
// Only the offer is needed for Nets payment initialization, it's used for price mapping.
const createPaymentInitializationPayload = (turbonappiData: TurbonappiData): TurbonappiOrderRequest => {
  if (!turbonappiData.msisdnInternational || !turbonappiData.offer || !turbonappiData.email) {
    throw new Error('Missing required data in Turbonappi order payload');
  }
  return {
    msisdn: turbonappiData.msisdnInternational,
    offer: turbonappiData.offer,
    email: turbonappiData.email,
    language: getLocale(),
  };
};

export const useTurbonappiContext = (): TurbonappiContextData => {
  const context = useContext(TurbonappiContext);

  if (!context) {
    throw new Error('turbonappiContext must be used within a TurbonappiContextProvider');
  }

  return context;
};

const validateMsisdnForTurbonappi = async (
  turbonappiData: TurbonappiData,
  setTurbonappiData: (data: TurbonappiData) => void,
  { msisdn, offer, email }: TurbonappiFormData
) => {
  const msisdnInternational = getMsisdnInInternationalFormat(msisdn);
  const updatedContextData: TurbonappiData = {
    ...turbonappiData,
    msisdn,
    msisdnInternational,
    offer,
    email,
    isValidationLoading: true,
  };
  setTurbonappiData(updatedContextData);

  const validationRequestPayload: TurbonappiMsisdnValidationRequest = {
    msisdn: msisdnInternational,
    offer,
  };

  try {
    const validationResponse: TurbonappiMsisdnActionResponse = await postTurbonappiValidation(validationRequestPayload);
    setTurbonappiData({
      ...updatedContextData,
      isMsisdnValidated: Boolean(validationResponse.success),
      failureMessage: validationResponse.reasonOfFailure,
      isValidationLoading: false,
    });
    return Boolean(validationResponse.success);
  } catch (error) {
    setTurbonappiData({
      ...updatedContextData,
      isMsisdnValidated: false,
      isValidationLoading: false,
      failureMessage: error,
    });
    return false;
  }
};

const initializeTurbonappiPayment = async (
  turbonappiData: TurbonappiData,
  setTurbonappiData: (data: TurbonappiData) => void
) => {
  const updatedContextData: TurbonappiData = { ...turbonappiData, isPaymentLoading: true };
  setTurbonappiData(updatedContextData);

  const payload: TurbonappiOrderRequest = createPaymentInitializationPayload(updatedContextData);

  try {
    const paymentResponse = await postTurbonappiPaymentInitialization(payload);
    // Forward user to Nets portal
    window.location.href = paymentResponse.forwardUrl;
  } catch (error) {
    setTurbonappiData({
      ...updatedContextData,
      isPaid: false,
      isPaymentLoading: false,
      failureMessage: error,
    });
  }
};

export const TurbonappiContextProvider = ({
  children,
  initialData,
}: {
  children: ReactNode;
  initialData: TurbonappiData;
}) => {
  const [turbonappiData, setTurbonappiData] = useState(initialData);

  const turbonappiContextData: TurbonappiContextData = {
    turbonappiData,
    setTurbonappiData,
    validateMsisdnForTurbonappi: formData => validateMsisdnForTurbonappi(turbonappiData, setTurbonappiData, formData),
    payTurbonappiSubscription: () => initializeTurbonappiPayment(turbonappiData, setTurbonappiData),
  };

  return <TurbonappiContext.Provider value={turbonappiContextData}>{children}</TurbonappiContext.Provider>;
};
