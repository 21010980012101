import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';

import './HeadingWithButtonStyleLink.scss';

export interface HeadingWithButtonStyleLinkProps {
  headingText: string;
  linkText: string;
  linkHref: string;
}

export const HeadingWithButtonStyleLink = ({ headingText, linkText, linkHref }: HeadingWithButtonStyleLinkProps) => {
  return (
    <div className="heading-with-button-style-link">
      <h3>{headingText}</h3>
      <p>
        <Link
          className="ds-link ds-link--style-link-button ds-link--button-color-light ds-link--button-size-s"
          to={linkHref}
        >
          {linkText}{' '}
          <span className="ds-link-last-object">
            <CL.Icon icon="arrow-right-alt" />
          </span>
        </Link>
      </p>
    </div>
  );
};
