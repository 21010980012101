import { Email, PhoneNumber } from '../../../common/react-hook-form/fields/index.js';
import { FormGridFieldset } from '../../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { mainUserContactDetailsMsg, t } from '../../../common/i18n/index.js';
export const CONTACT_EMAIL_FIELD_NAME = 'contactEmail';
export const CONTACT_PHONE_FIELD_NAME = 'contactPhoneNumber';

export const CompanyContactFieldset = () => {
  return (
    <FormGridFieldset legend={t.O1RJ(mainUserContactDetailsMsg)}>
      <Email name={CONTACT_EMAIL_FIELD_NAME} />
      <PhoneNumber name={CONTACT_PHONE_FIELD_NAME} />
    </FormGridFieldset>
  );
};
