import { AuthorizationStatus, useEmployeeAuthenticationStatus } from './employeeSessionResolver.js';
import { DialogType } from '../../common/enums.js';
import { DialogWrapper } from '../../components/DialogWrapper/index.js';
import { EmployeeLoginPath } from './path/Employee/EmployeeLoginPath.js';
import { Header } from '../../components/Header/Header.js';
import { OmaElisaHeader } from '../../components/OmaElisaHeader/OmaElisaHeader.js';
import { Outlet } from 'react-router-dom';
import { PunchoutHeader } from '../../components/Punchout/partials/PunchoutHeader.js';
import { SelfService } from '../../components/SelfService/SelfService.js';
import { SelfServiceLogin } from '../../components/SelfServiceLoginScene/SelfServiceLogin.js';
import { SmallHeader } from '../../components/SmallHeader/SmallHeader.js';
import { Spinner, SpinnerWithSwitchAccountInfoText } from './siteUtils.js';
import {
  backMsg,
  closeMsg,
  externalAuthenticationErrorMsg,
  externalAuthenticationRetryMsg,
  t,
} from '../../common/i18n/index.js';
import { closeDialog } from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getSwitchToAccountName, isMigratedContentHidden } from '../../selfservice/common/localStorageUtils.js';
import { isStrictFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from './AuthProvider.js';
import { useAuthContextMeta, useMeta } from '../../common/hooks/useMeta.js';
import { useDiscountedPrices } from '../../common/hooks/useDiscountedPrices.js';
import { useDispatch, useSelector } from 'react-redux';
import { useElisaIdClient } from '../../common/hooks/useElisaIdClient.js';
import { useNavigateBack } from '../../common/hooks/useNavigateBack.js';
import { useShoppingCart } from '../../common/hooks/useShoppingCart.js';
import type { DialogState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

export const PublicPathLayout = () => {
  const isMaintenanceModeForCmsPages = useSelector((s: State) =>
    isStrictFeatureEnabled(s.config.featureFlags.isMaintenanceModeForCmsPages)
  );
  if (isMaintenanceModeForCmsPages && isMigratedContentHidden()) {
    throw new Response('', { status: 503 });
  }

  return <Outlet />;
};
export const PrivateAuthenticationLayout = () => {
  useAuthContextMeta();
  useDiscountedPrices();
  return <Outlet />;
};
export const PublicAuthenticationLayout = () => {
  useMeta();
  useShoppingCart();
  useDiscountedPrices();
  return <Outlet />;
};

export const OmaElisaNavigationLayout = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const switchToAccountName = getSwitchToAccountName();
  return (
    <>
      {isLoading && !switchToAccountName && <Spinner />}
      {isLoading && switchToAccountName && (
        <SpinnerWithSwitchAccountInfoText switchToAccountName={switchToAccountName} />
      )}
      {!isLoading && (isAuthenticated ? <OmaElisaHeader /> : <Header />)}
      {!isLoading &&
        (isAuthenticated ? (
          <SelfService>
            <Outlet />
          </SelfService>
        ) : (
          <Outlet />
        ))}
    </>
  );
};

export const PunchoutNavigationLayout = () => {
  const { authenticatedUser } = useAuth();
  return (
    <>
      <PunchoutHeader hideCart={!authenticatedUser?.mobile} />
      <Outlet />
    </>
  );
};

export const OmaElisaProtectedRoutesLayout = () => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <SelfServiceLogin />;
  }
  return <Outlet />;
};
export const AuthorizedEmployeeLayout = () => {
  const elisaIdClient = useElisaIdClient();
  const employeeAuthenticationStatus = useEmployeeAuthenticationStatus(elisaIdClient);
  if (employeeAuthenticationStatus === AuthorizationStatus.AUTHORIZED) {
    return <Outlet />;
  } else {
    return <EmployeeLoginPath client={elisaIdClient} />;
  }
};
// This is only for public-facing pages of portals, not for normal public pages
// (different language handling)
export const PublicHeaderLayout = () => (
  <>
    <Header />
    <Outlet />
  </>
);
export const PublicHeaderCmsLayout = () => (
  <>
    <Header cmsPage />
    <Outlet />
  </>
);

const getExtAuthDialog = (onCloseDialog: () => void, dialog?: DialogState | null): JSX.Element | undefined => {
  // TODO: leo 30.4.2022 - Below `dialog?.params?.type` needs the optional chaining because `dialogReducer()` is lying
  //  about returning a `DialogState`, which is supposed to always contain `params`. In practice this function often
  //  gets called with `dialog`:
  //  `{"errors":[{"type":"SYSTEM","message":"Jokin meni pieleen. Ole hyvä ja yritä uudelleen."}],"submitInProgress":false}`
  if (dialog?.params?.type === DialogType.EXTERNAL_AUTHENTICATION_FAIL) {
    return (
      <DialogWrapper
        buttons={[
          {
            id: 'close-external-authentication-error',
            onClick: onCloseDialog,
            text: t.WOYD(closeMsg),
          },
        ]}
        closeable
        header={t.UEHC(externalAuthenticationErrorMsg)}
        onCloseDialog={onCloseDialog}
      >
        <p>{t.Q9YM(externalAuthenticationRetryMsg)}</p>
      </DialogWrapper>
    );
  } else {
    return undefined;
  }
};

export const ExtAuthLayout = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state: State) => state.dialog, deepEqual);

  return (
    <div className="of-selfservice">
      <SmallHeader />
      <Outlet />
      {getExtAuthDialog(() => dispatch(closeDialog()), dialog)}
    </div>
  );
};

export const ShoppingCartWithVoucherLayout = () => {
  const navigateBack = useNavigateBack(paths.WEB_SHOP);
  return (
    <div className="of-selfservice">
      <SmallHeader buttonText={t.D1E9(backMsg)} onClick={navigateBack} />
      <Outlet />
    </div>
  );
};
