import { InputComponent } from '../components/InputComponent.js';
import { cityMsg, fieldCantBeEmptyMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const PostOffice = ({
  name = 'postOffice',
  label = t.J0YE(cityMsg),
  placeholder = undefined,
  required = true,
  readonly = false,
  wrapperClassName,
}: OptionalInputComponentProps) => {
  const validate = (value: string) => {
    if (!value && required) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    return;
  };

  return (
    <InputComponent
      disabled={false}
      label={label}
      name={name}
      placeholder={placeholder}
      readonly={readonly}
      required={required}
      type="text"
      validate={validate}
      wrapperClassName={wrapperClassName}
    />
  );
};
