import { getImageUrl } from './contentfulImageProxy.js';
import { getMimeType } from './mimeType.js';
import { throwError } from '../../common/utils/errorUtils.js';

export interface RenderedImageSizeProps {
  onPhone: string;
  onPhoneLarge?: string;
  onTablet?: string;
  onLaptop?: string;
  onDesktop?: string;
}

export interface ImgProps {
  alt: string;
  quality?: number;
  src: string;
  // Some old cms-contents have width given
  // This will not be given in img tag if undefined
  width?: string;
}

export interface PictureConfig {
  loading?: 'eager' | 'lazy';
  offerWidthAlternatives: [number, ...number[]]; // non-empty array
  renderedImageSize: RenderedImageSizeProps;
  // We know that some images are contained in a square. This information
  // allows us to make portrait image data smaller. E.g. we have a 1000*2500
  // source image that we want to fit in a 250*250 box. If we just provide
  // 250 as width, we'll get 250*750 image data. If we pass 250 as the height
  // as well, we get 100*250, which is exactly what we'd want.
  // (Contentful width & height, at least with default fit value, actually
  // define a bounding box, not the final image size)
  square?: boolean;
}

export interface PictureProps extends ImgProps, PictureConfig {
  handlers?: object;
  pictureClasses?: string;
  imgClasses?: string;
}

export const calculateArrayOfWidthsByMinAndMax = (min: number, max: number, divider = 5) => {
  const oneOfX = (max - min) / (divider - 1);
  const array: number[] = [];

  while (min <= max) {
    array.push(Math.round(min));
    min += oneOfX;
  }
  return array as unknown as [number, ...number[]];
};

export function getFileExtension(imagePath: string) {
  const match = imagePath.match(/[^.]+$/);
  if (!match) {
    return throwError(`Couldn't get extension for '${imagePath}' image path`);
  }
  return match[0];
}

export function createSrcSet(
  imagePath: string,
  widths: number[],
  square?: boolean,
  quality?: number,
  format?: string
): string {
  const imageCandidateStrings: string[] = [];

  for (const width of widths) {
    const imageUrl = getImageUrl(imagePath, { format, height: square ? width : undefined, quality, width });
    const widthDescriptor = `${width}w`;
    imageCandidateStrings.push(`${imageUrl} ${widthDescriptor}`);
  }

  return imageCandidateStrings.join(', ');
}

export function getMidpoint(values: number[]): number {
  const min = Math.min(...values);
  const max = Math.max(...values);
  return Math.floor((min + max) / 2);
}

export function createSizes(renderedSizes: RenderedImageSizeProps): string {
  const { onPhone } = renderedSizes;
  let { onPhoneLarge, onTablet, onLaptop, onDesktop } = renderedSizes;

  if (!onPhone) {
    throw new Error('onPhone must have value');
  }
  if (!onPhoneLarge) {
    onPhoneLarge = onPhone;
  }
  if (!onTablet) {
    onTablet = onPhoneLarge;
  }
  if (!onLaptop) {
    onLaptop = onTablet;
  }
  if (!onDesktop) {
    onDesktop = onLaptop;
  }

  return `(min-width: 1400px) ${onDesktop}, (min-width: 961px) ${onLaptop}, (min-width: 641px) ${onTablet}, (min-width: 481px) ${onPhoneLarge}, ${onPhone}`;
}

function sanitizeImageUrl(imageUrl: string) {
  if (imageUrl.match(/\?/)) {
    imageUrl = imageUrl.split('?')[0];
  }
  if (imageUrl.match(/ /)) {
    imageUrl = encodeURI(imageUrl);
  }
  return imageUrl;
}

/**
 * `renderedImageSize`
 *
 * `renderedImageSize` sets the sizes-attribute on `<source>` element. It describes the width at which the image is going to be rendered on the resulting page. The rendered width varies from mobile to table to desktop etc. and each breakpoint usually needs to have a unique value. Express the rendered widths using `vw`, `px` or `calc` for example `"100vw"`, `"400px"`, or `"calc(min(1260px, 100vw) * 0.33)"`.
 *
 * You can determine the rendered image widths by analyzing the picture's context. For example, if a picture is in a `'ea-fgrid__item--phone-6 ea-fgrid__item--tablet-6 ea-fgrid__item--laptop-4'` grid that's wrapped in a 1260px container, the rendered sizes are: `{ onPhone: "100vw", onTablet: "50vw", onLaptop: "calc(min(1260px, 100vw) * 0.33)"}`
 *
 * The rendered widths don't need to be ultra accurate, and you don't have to include paddings and margins in the calculations. The idea is to give a rough estimate so the browser can start downloading the right image before loading CSS files or rendering the page. For example, downloading a 300px image instead of 600px image uses only 1/4 of the bandwidth.
 * Just make sure the image is never smaller than what we need.
 *
 * `offerWidthAlternatives`
 *
 * `offerWidthAlternatives` lists image widths that are served in the srcset-attribute of the `<source>` element. It provides alternatives for the browser as it chooses the right image based on rendered width information in sizes (created based on the `renderedImageSize` parameter).
 *
 * You can determine right width alternatives by:
 * - determine the widest rendered image width, multiply it by 2x to allow a Retina-friendly version, round to nice number, let this be the highest width
 * - determine the narrowest rendered image width, round (up) to nice number, let this be the lowest width
 * - divide the space between the highest and the lowest width to 200-300px increments, or match the largest size of each breakpoint.
 * - try to provide an exact match for googlebot's mobile view, which is currently masquerading as Moto G4 (360 * 640, DPR: 3)
 *
 * For example, coming up with offered width alternatives for the previous example: the widest rendered sizes on each breakpoint are:
 * - 100vw on phone (covers phone-large) 640px at widest,
 * - 50vw on tablet 480px widest,
 * - 0.33 * 1260px on laptop (covers desktop) 416px at widest.
 *
 * The widest size is 640px, we'll 2x that for Retina, and we get 1280px as our highest width. The narrowest width is 416px. We'll round that to 420px and that's our narrowest width. Theres (1280-420)px = 820px space between the highest and lowest width that we'll divide to two 270px increments. We'll also provide an exact size for googlebot, which is 1080. As that is close to one of our increments (960), well replace that. This gives us the width alternatives of: [420, 690, 1080, 1280].
 *
 * Remember,
 * - stylebook breakpoints are
 *   - phone <480px
 *   - phone-large <640px
 *   - tablet <960px
 *   - laptop <1400px
 *   - desktop >1400px
 * - Grid container is 1260px
 *
 */
export const Picture = (props: PictureProps) => {
  if (!props.src) {
    return <></>;
  }
  const src = sanitizeImageUrl(props.src);
  const {
    alt,
    loading,
    width,
    quality,
    offerWidthAlternatives,
    renderedImageSize,
    square,
    pictureClasses,
    imgClasses,
    handlers = {},
  } = props;
  const fileExtension = getFileExtension(src);
  if (!fileExtension) {
    return null;
  }
  const mimeType = getMimeType(fileExtension);
  if (!mimeType) {
    return null;
  }
  const defaultWidth = getMidpoint(offerWidthAlternatives);
  return (
    <picture {...handlers} className={pictureClasses}>
      <source
        srcSet={createSrcSet(src, offerWidthAlternatives, square, quality, 'avif')}
        sizes={createSizes(renderedImageSize)}
        type="image/avif"
      />
      <source
        srcSet={createSrcSet(src, offerWidthAlternatives, square, quality, 'webp')}
        sizes={createSizes(renderedImageSize)}
        type="image/webp"
      />
      <source
        srcSet={createSrcSet(src, offerWidthAlternatives, square, quality)}
        sizes={createSizes(renderedImageSize)}
        type={mimeType}
      />
      <img
        alt={alt}
        className={imgClasses}
        loading={loading}
        src={getImageUrl(src, { height: square ? defaultWidth : undefined, width: defaultWidth })}
        width={width}
      />
    </picture>
  );
};
