import { CompositeList } from '../CompositeList/index.js';
import { EmptyOrError } from '../EmptyOrError/index.js';
import { Loading } from '../Loading/index.js';
import { PureComponent } from 'react';
import InfiniteScroll from 'react-infinite-scroller'; // eslint-disable-line
import type { CompositeListProps } from '../CompositeList/index.js';
import type { CompositeListSort } from '../CompositeListHeader/index.js';

export interface InfiniteScrollCompositeListOwnProps {
  emptyListText: string | string[];
  emptyListButtonText?: string;
  onEmptyListButtonClick?: () => void;
  enableInfiniteScroll: boolean;
  onInfiniteScrollLoadMore: (page: number, sort?: CompositeListSort) => void;
  showSelectAll?: boolean;
  selectAll?: boolean;
  onClickSelectAll?: (selected: boolean) => void;
}

export type InfiniteScrollCompositeListProps<T extends object> = InfiniteScrollCompositeListOwnProps &
  CompositeListProps<T>;

const wrapRowsWithInfiniteScroller = (
  rows: JSX.Element[],
  enableInfiniteScroll: boolean,
  onInfiniteScrollLoadMore: (page: number, sort?: CompositeListSort) => void,
  sort?: CompositeListSort
): JSX.Element => {
  return (
    <InfiniteScroll
      hasMore={enableInfiniteScroll}
      initialLoad={false}
      loader={<Loading key="loader" />}
      loadMore={(page: number) => onInfiniteScrollLoadMore(page, sort)}
      pageStart={0}
    >
      {rows}
    </InfiniteScroll>
  );
};

export class InfiniteScrollCompositeList<T extends object> extends PureComponent<InfiniteScrollCompositeListProps<T>> {
  render() {
    const {
      emptyListText,
      emptyListButtonText,
      onEmptyListButtonClick,
      enableInfiniteScroll,
      onInfiniteScrollLoadMore,
      classes,
      ...compositeListProps
    }: InfiniteScrollCompositeListProps<T> = this.props;
    const infiniteScrollClasses = ['of-infinite-scroll-composite-list'];
    const compositeListClasses = classes ? classes.concat(infiniteScrollClasses) : infiniteScrollClasses;
    return (
      <CompositeList
        {...compositeListProps}
        classes={compositeListClasses}
        wrapRows={rows => {
          return rows.length > 0 ? (
            wrapRowsWithInfiniteScroller(rows, enableInfiniteScroll, onInfiniteScrollLoadMore, compositeListProps.sort)
          ) : (
            <EmptyOrError
              id="empty-stage"
              text={emptyListText}
              buttonText={emptyListButtonText}
              onButtonClick={onEmptyListButtonClick}
            />
          );
        }}
      />
    );
  }
}
