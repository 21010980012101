import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { Login } from '../Login/index.js';
import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';

import './LoginAndRegistration.scss';

export interface LoginAndRegistrationProps {
  redirectTargetAfterRegisterUrl?: string;
}

export const LoginAndRegistration = ({
  redirectTargetAfterRegisterUrl = paths.DEVICE_CHECKOUT,
}: LoginAndRegistrationProps) => {
  const dialog = useSelector((state: State) => state.dialog, deepEqual);

  const { anonymousUser } = useAuth();
  return (
    <div className="of-login-registration-container">
      <div className="of-login-registration-container__login">
        <Login dialog={dialog} />
      </div>
      {!anonymousUser?.mfaRequired && (
        <div className="of-login-registration-container__registration">
          <h3>{t.ELJA('I am a new customer.')}</h3>
          <Link
            to={`${paths.REGISTER_PAGE}?${REDIRECT_PATH_KEY}=${encodeURIComponent(redirectTargetAfterRegisterUrl)}`}
          >
            <CL.Button size="l">{t.LJTY('Register')}</CL.Button>
          </Link>
        </div>
      )}
    </div>
  );
};
