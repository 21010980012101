import classNames from 'classnames';
import type { ReactNode } from 'react';

import './StickyFooter.scss';

export interface StickyFooterProps {
  active: boolean;
  className?: string;
  children: ReactNode;
}

export const StickyFooter = ({ active, className, children }: StickyFooterProps) => (
  <>
    <div className={`of-sticky-footer--placeholder--${active ? 'visible' : 'hidden'}`}></div>
    <div className={classNames('of-sticky-footer', `of-sticky-footer--${active ? 'visible' : 'hidden'}`, className)}>
      {children}
    </div>
  </>
);
