import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants.js';
import { loginMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';

import './LoginBanner.scss';

export const LoginBanner = () => {
  return (
    <CL.Disclaimer
      className="ds-margin-vertical--3 ds-background-color--secondary-blue-100 ds-border--width-1 ds-border--style-solid"
      icon={<CL.Icon icon="diamond" aria-hidden="true" color="secondary-blue-600" />}
    >
      <h5 className="ds-margin-bottom--1">{t.BMZU('Log in to receive personalized service and offers')}</h5>
      <p className="ds-margin-bottom--2">
        {t.WDIX(`If you don't have an account in OmaElisa for Companies, you can create one below.`)}
      </p>
      <p>
        <Link
          to={`${paths.REGISTER_PAGE}?${REDIRECT_PATH_KEY}=${encodeURIComponent(paths.NEW_SHOPPING_CART)}`}
          className="ds-margin-top--1 ds-margin-bottom--3"
        >
          <span>{t.XE0P('Create an account')}</span>
        </Link>
      </p>
      <p>
        <Link to={paths.NEW_SHOPPING_CART} state={{ loginInProgress: true }}>
          <CL.Button color="light">{t.HYV2(loginMsg)}</CL.Button>
        </Link>
      </p>
    </CL.Disclaimer>
  );
};
