import { ActionBlock } from '../ActionBlock/ActionBlock.js';
import { PageMargins } from '../../components/PageMargins/PageMargins.js';
import type { ActionGroupData } from '../cmsSchema.js';

import './ActionGroup.scss';

export const ActionGroup = ({ actionBlocks = [] }: ActionGroupData) => (
  <PageMargins as="padding" className="ds-background-color--neutral-200--light ds-padding-vertical--2" tag="nav">
    <ul className="of-action-group">
      {actionBlocks.map((contentItem, i) => (
        <ActionBlock key={i} {...contentItem} />
      ))}
    </ul>
  </PageMargins>
);
