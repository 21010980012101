import * as CL from '@design-system/component-library';
import { BreadCrumbsWithTitle } from '../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DeviceSubscriptionExpirationNotification } from './DeviceSubscriptionExpirationNotification.js';
import { DialogType } from '../../common/enums.js';
import { EppRedeem } from '../EppRedeem/EppRedeem.js';
import { Loading } from '../Loading/index.js';
import { SubscriptionAction } from '../../generated/api/models.js';
import {
  damageOrSupportRequestMsg,
  devicesMsg,
  durationOfContractMsg,
  elisaDevicesServiceMsg,
  idNumberMsg,
  omaElisaForEmployeeMsg,
  redeemingTheDeviceMsg,
  serviceMsg,
  t,
  terminateAgreementAndReturnDeviceMsg,
  terminationMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  getCommitmentText,
  getDeviceCommitmentFields,
  isDeviceTerminationAllowedForEmployee,
} from './eppSubscriptionUtils.js';
import { getDeviceTypeText, getEmployeePeriodicPriceAsText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { getSubscriptionStatus, openSubscriptionDialog } from '../../common/utils/subscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { scrollTo } from '../../common/utils/browserUtils.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { AuthenticatedUserState, BillingAccountsState } from '../../common/types/states.js';
import type { Contact, Subscription } from '../../generated/api/models.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { EmployeeSubscription, SubscriptionStatus } from '../../common/types/subscription.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionId } from '../../common/constants/pathInterfaces.js';

enum SubscriptionDetailsButtonType {
  EPP_MAINTENANCE,
  EPP_REDEEM,
  EPP_TERMINATE,
  EPP_DAMAGE_OR_SUPPORT_REQUEST,
}

interface DeviceSubscriptionDetailsContentProps {
  subscription: EmployeeSubscription;
  subscriptionStatus: SubscriptionStatus;
  onShowDialog: (params: DialogParams) => void;
  onClickEppMaintenanceRequest: (subscriptionId: string) => void;
  onClickEppRedeem: () => void;
}

interface DetailsWrapperContentProps {
  subscriptionId: string;
  subscription?: EmployeeSubscription;
  subscriptionStatus: SubscriptionStatus;
  subscriptionActions?: SubscriptionAction[];
  setShowEppRedeem: (showEppRedeem: boolean) => void;
  onShowDialog: (params: DialogParams) => void;
}

interface DetailsWrapperContentEppRedeemProps {
  subscription?: EmployeeSubscription;
  billingAccounts?: BillingAccountsState | null;
  contacts?: Contact[];
  user?: AuthenticatedUserState | null;
  setShowEppRedeem: (showEppRedeem: boolean) => void;
}

const getButtons = (
  buttonTypes: SubscriptionDetailsButtonType[],
  onShowDialog: (params: DialogParams) => void,
  subscription: Subscription,
  subscriptionStatus: SubscriptionStatus,
  onClickEppMaintenanceRequest: (subscriptionId: string) => void,
  onClickEppRedeem: () => void
): JSX.Element => {
  const buttons = {
    EPP_REDEEM: (
      <CL.Button
        color="link"
        key={SubscriptionDetailsButtonType.EPP_REDEEM}
        onClick={
          subscriptionStatus.pendingActions
            ? () => onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING })
            : onClickEppRedeem
        }
      >
        <>
          <span className="ea-icon ea-icon--small ea-icon--invoice" />
          {t.B9EG(redeemingTheDeviceMsg)}
        </>
      </CL.Button>
    ),
    EPP_TERMINATE: (
      <CL.Button
        color="link"
        key={SubscriptionDetailsButtonType.EPP_TERMINATE}
        onClick={() =>
          isDeviceTerminationAllowedForEmployee(subscription)
            ? openSubscriptionDialog(subscriptionStatus, onShowDialog, {
                subscription,
                type: DialogType.EPP_TERMINATE,
              })
            : onShowDialog({
                body: t.ZF77(
                  'The contract cannot be terminated by employee during an active contract period. Please contact your admin user.'
                ),
                header: t.LIUF(terminateAgreementAndReturnDeviceMsg),
                type: DialogType.GENERIC_INFO_DIALOG,
              })
        }
      >
        <>
          <span className="ea-icon ea-icon--small ea-icon--stop" />
          {t.D1IS(terminationMsg)}
        </>
      </CL.Button>
    ),
    EPP_DAMAGE_OR_SUPPORT_REQUEST: (
      <CL.Button
        color="link"
        key={SubscriptionDetailsButtonType.EPP_DAMAGE_OR_SUPPORT_REQUEST}
        onClick={() => onClickEppMaintenanceRequest(subscription.subscriptionId)}
      >
        <>
          <span className="ea-icon ea-icon--small ea-icon--support" />
          {t.N2CC(damageOrSupportRequestMsg)}
        </>
      </CL.Button>
    ),
  };

  // @ts-ignore
  return <>{buttonTypes.map(buttonType => buttons[SubscriptionDetailsButtonType[buttonType].toString()])}</>;
};

const deviceCommitmentFields = (subscription: Subscription): CL.DescriptionItem[] => {
  return subscription.details?.device?.eppSubscription
    ? [...getDeviceCommitmentFields(subscription)]
    : [
        {
          title: t.CAA9(durationOfContractMsg),
          description: getCommitmentText(subscription.details!),
        },
      ];
};

const DeviceSubscriptionDetailsContent = ({
  subscription,
  subscriptionStatus,
  onShowDialog,
  onClickEppMaintenanceRequest,
  onClickEppRedeem,
}: DeviceSubscriptionDetailsContentProps) => (
  <>
    <CL.Description
      className="of-employee-subscription-details__description"
      items={[
        {
          title: t.YTH3(idNumberMsg),
          description: subscription.subscriptionDisplayId,
        },
        {
          title: 'IMEI',
          description: subscription.details?.device?.serialNumber,
        },
        {
          title: t.CDME(serviceMsg),
          description: subscription.details?.device?.eppSubscription ? t.TRE5(elisaDevicesServiceMsg) : undefined,
        },
        ...deviceCommitmentFields(subscription),
        {
          title: t.RUU7('Your monthly charges'),
          description: getEmployeePeriodicPriceAsText(subscription.details?.monthlyRecurringCharge),
        },
      ]}
    />
    {subscription.details?.device?.eppSubscription && (
      <div className="of-employee-subscription-details__actions">
        {getButtons(
          [
            SubscriptionDetailsButtonType.EPP_REDEEM,
            SubscriptionDetailsButtonType.EPP_DAMAGE_OR_SUPPORT_REQUEST,
            SubscriptionDetailsButtonType.EPP_TERMINATE,
          ],
          onShowDialog,
          subscription,
          subscriptionStatus,
          onClickEppMaintenanceRequest,
          onClickEppRedeem
        )}
      </div>
    )}
  </>
);

const DetailsWrapperContentEppRedeem = ({
  subscription,
  billingAccounts,
  contacts,
  user,
  setShowEppRedeem,
}: DetailsWrapperContentEppRedeemProps) => {
  return subscription ? (
    <EppRedeem
      billingAccounts={billingAccounts}
      contacts={contacts}
      email={user?.email}
      firstName={user?.firstName}
      isRedeemedByAdmin={false}
      lastName={user?.lastName}
      loggedInUserContactId={user?.contact?.contactId}
      mobile={user?.mobile}
      onBackClick={() => setShowEppRedeem(false)}
      subscription={subscription}
    />
  ) : (
    <Loading />
  );
};

const DetailsWrapperContent = ({
  subscriptionId,
  subscription,
  subscriptionStatus,
  subscriptionActions,
  setShowEppRedeem,
  onShowDialog,
}: DetailsWrapperContentProps) => {
  const navigate = useNavigate();
  return subscription ? (
    <div>
      <DeviceSubscriptionExpirationNotification subscription={subscription} subscriptionActions={subscriptionActions} />
      <DeviceSubscriptionDetailsContent
        subscription={subscription}
        subscriptionStatus={subscriptionStatus}
        onClickEppMaintenanceRequest={() => {
          navigate(generatePath(paths.EMPLOYEE_DEVICE_SUPPORT_REQUEST, { subscriptionId }));
        }}
        onClickEppRedeem={() => setShowEppRedeem(true)}
        onShowDialog={onShowDialog}
      />
    </div>
  ) : (
    <Loading />
  );
};

export const EmployeeSubscriptionDetailsDevice = ({ subscriptionId }: SubscriptionId) => {
  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const [showEppRedeem, setShowEppRedeem] = useState(false);
  const { authenticatedUser } = useAuth();
  const deviceSubs = useSelector((state: State) => state.user?.authenticated?.subscriptions?.device, deepEqual);
  const subscriptionActions = useSelector(
    (state: State) => state.selfservice?.pendingSubscriptionActions?.items || [],
    deepEqual
  );
  const config = useSelector((state: State) => state.config, deepEqual);
  const imagesBaseUrl = config?.imagesBaseUrl;
  const contacts = useSelector((state: State) => state.selfservice?.contacts, deepEqual);
  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);
  const subscription = deviceSubs?.find(s => s.subscriptionId === subscriptionId);
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions);

  useEffect(() => {
    if (
      showEppRedeem &&
      subscriptionActions?.some(
        subscriptionAction =>
          subscriptionAction.subscriptionId === subscription?.subscriptionId &&
          [
            SubscriptionAction.StatusEnum.PENDING,
            SubscriptionAction.StatusEnum.PENDING_APPROVAL,
            SubscriptionAction.StatusEnum.ERROR,
          ].includes(subscriptionAction.status)
      )
    ) {
      setShowEppRedeem(false);
      scrollTo(0);
    }
  }, [subscriptionActions, subscription?.subscriptionId, showEppRedeem]);

  const getHeadingBottom = () => {
    if (showEppRedeem) {
      return `${subscription?.subscriptionName} | ${subscription?.subscriptionContactName}`;
    }
    return subscription?.subscriptionSubType ? getDeviceTypeText(subscription.subscriptionSubType) : '';
  };

  return (
    <DetailsWrapper
      classes={['of-employee-subscription-details']}
      content={
        showEppRedeem ? (
          <DetailsWrapperContentEppRedeem
            subscription={subscription}
            billingAccounts={billingAccounts}
            contacts={contacts?.items}
            user={authenticatedUser}
            setShowEppRedeem={setShowEppRedeem}
          />
        ) : (
          <DetailsWrapperContent
            subscriptionId={subscriptionId}
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            subscriptionActions={subscriptionActions}
            setShowEppRedeem={setShowEppRedeem}
            onShowDialog={onShowDialog}
          />
        )
      }
      detailsTop={
        <>
          {subscription?.subscriptionName && (
            <BreadCrumbsWithTitle
              breadCrumbPaths={[
                { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
                { name: t.JJTM(devicesMsg), path: paths.EMPLOYEE_DEVICES },
                { name: subscription.subscriptionName },
              ]}
            />
          )}
        </>
      }
      id="employee-subscription"
      heading={showEppRedeem ? t.KK9D('Redeeming your device') : (subscription?.subscriptionName ?? '')}
      headingBottom={getHeadingBottom()}
      heroImage={!showEppRedeem ? (subscription?.details?.device?.image ?? '') : undefined}
      heroPicto={!showEppRedeem ? 'phone-filled' : ''}
      heroIcon={showEppRedeem ? 'invoice' : ''}
      imagesBaseUrl={imagesBaseUrl}
    />
  );
};
