import * as CL from '@design-system/component-library';
import { HeadingWithButtonStyleLink } from './HeadingWithButtonStyleLink.js';
import { Link, generatePath } from 'react-router-dom';
import { StatusColumn, Table } from '../Table/index.js';
import { companyMsg, dueDateMsg, paidMsg, statusMsg, t } from '../../common/i18n/index.js';
import { formatBalance, formatSum } from '../../common/utils/priceUtils.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getEmptyListText } from '../InvoiceList/InvoiceList.js';
import { getStatusColumnInvoiceState } from '../InvoiceDetails/InvoiceDetails.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useMultiAccountRowClick } from '../../common/hooks/useMultiAccountRowClick.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { InvoiceHeader } from '../../generated/api/invoiceHeader.js';

export interface SelfServiceHomeInvoicesProps {
  invoices?: InvoiceHeader[];
  authenticatedUser?: AuthenticatedUserState;
  className?: string;
}
export const SelfServiceHomeInvoices = ({ invoices, authenticatedUser, className }: SelfServiceHomeInvoicesProps) => {
  const onRowClick = useMultiAccountRowClick();

  const columns: CL.Column[] = [
    {
      key: 'invoiceDisplayId',
      label: t.BRFX('Invoice number'),
      sortable: true,
    },
    { key: 'due', label: t.LA93(dueDateMsg), sortable: true },
    {
      key: 'sum',
      label: t.P4RQ('Amount'),
      sortable: true,
    },
    { key: 'state', label: t.ASQT(statusMsg), sortable: false },
    { key: 'company', label: t.KJTS(companyMsg) },
  ];

  const rows = invoices?.map(invoice => ({
    invoiceDisplayId: (
      <Link
        to={`${generatePath(paths.INVOICE, { invoiceId: invoice.invoiceDisplayId })}?companyId=${
          invoice.accountMasterId
        }`}
        onClick={e => {
          e.preventDefault();
          onRowClick(
            `${generatePath(paths.INVOICE, { invoiceId: invoice.invoiceDisplayId })}?companyId=${
              invoice.accountMasterId
            }`,
            getCompanyName(authenticatedUser, invoice.accountMasterId),
            invoice.accountMasterId
          );
        }}
      >
        {invoice.invoiceDisplayId}
      </Link>
    ),
    due: formatTimestampToDDMMYYYY(invoice.due),
    state: (
      <StatusColumn
        status={getStatusColumnInvoiceState(invoice.balance || 0, invoice.due).text}
        color={getStatusColumnInvoiceState(invoice.balance || 0, invoice.due).color}
      />
    ),
    sum: (
      <CL.Description
        items={[
          {
            title: formatSum(invoice.sum),
            description: `${t.RJ27(paidMsg)} ${formatBalance(invoice.sum, invoice.balance || 0)}`,
          },
        ]}
      />
    ),
    company: getCompanyName(authenticatedUser, invoice.accountMasterId),
  }));

  return (
    <div className={className}>
      <HeadingWithButtonStyleLink
        headingText={t.INV3('Open invoices')}
        linkText={t.MEHC('All invoices')}
        linkHref={paths.INVOICES}
      />
      <div className="ds-margin-top--5">
        <Table tableType="bordered" columns={columns} noItemsText={getEmptyListText('open')} rows={rows || []} />
      </div>
    </div>
  );
};
