// Typescript types associated with PathVariables inside path with colon (:) prefix
// E.g. /omaelisa/laskutussopimukset/:billingAccountId

export const addonId = 'addonId';
export type AddonId = Record<typeof addonId, string>;

export const adminId = 'adminId';
export type AdminId = Record<typeof adminId, string>;

export const billingAccountId = 'billingAccountId';
export type BillingAccountId = Record<typeof billingAccountId, string>;

export const catalogCode = 'catalogCode';
export type CatalogCode = Record<typeof catalogCode, string>;

export const category = 'category';
export type Category = Record<typeof category, string>;

export const checkoutCardPaymentStatus = 'checkoutCardPaymentStatus';
export type CheckoutCardPaymentStatus = Record<typeof checkoutCardPaymentStatus, string>;

export const deliveryId = 'deliveryId';
export type DeliveryId = Record<typeof deliveryId, string>;

export const deviceName = 'deviceName';
export type DeviceName = Record<typeof deviceName, string>;

export const historyId = 'historyId';
export type HistoryId = Record<typeof historyId, string>;

export const invoiceId = 'invoiceId';
export type InvoiceId = Record<typeof invoiceId, string>;

export const orderId = 'orderId';
export type OrderId = Record<typeof orderId, string>;

export const requestId = 'requestId';
export type RequestId = Record<typeof requestId, string>;

export const subscriptionId = 'subscriptionId';
export type SubscriptionId = Record<typeof subscriptionId, string>;

export const caseDisplayId = 'supportCaseDisplayId';
export type SupportCaseDisplayId = Record<typeof caseDisplayId, string>;

export const token = 'token';
export type Token = Record<typeof token, string>;

export const trainingName = 'trainingName';
export type TrainingName = Record<typeof trainingName, string>;

export const userId = 'userId';
export type UserId = Record<typeof userId, string>;

export const userName = 'userName';
export type UserName = Record<typeof userName, string>;

export const contractDisplayId = 'contractDisplayId';
export type ContractDisplayId = Record<typeof contractDisplayId, string>;

export const customerOrderDisplayId = 'customerOrderDisplayId';
export type CustomerOrderDisplayId = Record<typeof customerOrderDisplayId, string>;

export const contactMasterId = 'contactMasterId';
export type ContactMasterId = Record<typeof contactMasterId, string>;
