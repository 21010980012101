import * as CL from '@design-system/component-library';
import * as React from 'react';
import { AuthenticatedUserRole } from '../../generated/api/models.js';
import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { GlobalSearch } from '../GlobalSearch/GlobalSearch.js';
import { HeaderNotification } from '../HeaderNotification/HeaderNotification.js';
import { LoggedInAsEmployeeNotification, getUserAccounts, siteSelect } from '../Header/dynamic/headerFunctions.js';
import { SelectCompanyDialog } from '../../components/Dialogs/SelectCompanyDialog.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import {
  chosenLanguageMsg,
  closeMsg,
  englishMsg,
  forCorporationsMsg,
  logInMsg,
  logOutMsg,
  myInformationMsg,
  searchMsg,
  suomiMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  getUserHasSeenSearchInfoPopover,
  setUserHasSeenSearchInfoPopover,
} from '../../selfservice/common/localStorageUtils.js';
import { headerAccordions } from '../Header/dynamic/headerAccordions.js';
import { isFeatureEnabled, isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { isMultiBiz } from '../../common/utils/accountUtils.js';
import { omaElisaNavigationLinksWithCurrentProperty } from './dynamic/omaElisaNavigationLinks.js';
import { paths } from '../../common/constants/pathVariables.js';
import { personalUserMenuLinks } from '../Header/dynamic/personalUserMenuLinks.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ChangeEvent, KeyboardEvent } from 'react';
import type { HeaderUserAccount } from '@design-system/component-library';
import type { State } from '../../selfservice/common/store.js';

import './OmaElisaHeader.scss';

interface SearchButtonProps {
  searchFieldVisible: boolean;
  infoPopoverVisible: boolean;
  setSearchFieldVisible: (visible: boolean) => void;
  setInfoPopoverVisible: (visible: boolean) => void;
}
const SearchButton = ({
  searchFieldVisible,
  infoPopoverVisible,
  setSearchFieldVisible,
  setInfoPopoverVisible,
}: SearchButtonProps) => {
  const { toggleActiveItem } = useContext(CL.HeaderContext);
  const searchButton = (
    <button
      className="ds-navigationbutton ds-headersearchbutton"
      onClick={() => {
        if (!searchFieldVisible) {
          // This hides whatever item might have been active/open in the header
          toggleActiveItem(null, null);
        }
        setSearchFieldVisible(!searchFieldVisible);
        setInfoPopoverVisible(false);
        setUserHasSeenSearchInfoPopover();
      }}
      type="button"
    >
      <div className="ds-navigationbutton__icon">
        <CL.Icon icon="search" />
      </div>
      <span className="search-button__text">{t.V4GK(searchMsg)}</span>
    </button>
  );
  return (
    <CL.Popover
      triggerElement={searchButton}
      className="search-info-popover"
      placement="bottom"
      defaultOpen={infoPopoverVisible}
      i18n_popover_closeButtonTitle={t.WOYD(closeMsg)}
    >
      <h5>{t.CJQJ('Test the new search')}</h5>
      <p>
        {t.C7RR(
          'We have released a new search function. You can now quickly search for, e.g., devices, phone subscriptions, orders, users, or billing accounts.'
        )}
      </p>
    </CL.Popover>
  );
};

export const OmaElisaHeader = () => {
  const { logout, ssoSessionValid } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { siteBaseUrl } = useContext(SiteContext);
  const dispatch = useDispatch();
  const { siteLanguage, saveUserPreferredLanguage } = React.useContext(SiteContext);
  const [userAccounts, setUserAccounts] = useState<HeaderUserAccount[]>([]);
  const menuId = 'oma-elisa-navigation';
  const hamburgerRef = useRef<HTMLDialogElement>(null);
  const userMenuRef = useRef<HTMLDialogElement>(null);
  const userHasSeenSearchInfoPopover = getUserHasSeenSearchInfoPopover();
  const [selectCompanyDialogUrl, setSelectCompanyDialogUrl] = useState<string>();
  const [searchFieldVisible, setSearchFieldVisible] = useState(false);
  const [searchInfoPopoverVisible, setSearchInfoPopoverVisible] = useState(!userHasSeenSearchInfoPopover);
  const { authenticatedUser } = useAuth();
  const { classicSiteUrl, ringBaseUrl, featureFlags } = useSelector(
    (state: State) => ({
      classicSiteUrl: state.config.classicSiteUrl,
      ringBaseUrl: state.config.ringBaseUrl,
      featureFlags: state.config.featureFlags,
    }),
    deepEqual
  );
  const isConsolidatedViewsEnabled = isFeatureEnabled(featureFlags.consolidatedViews);
  const licenseManagementEnabled =
    isFeatureEnabled(featureFlags.licenseManagement) ||
    authenticatedUser?.enabledFeatureFlags?.includes('licenseManagement') ||
    false;
  const dnsManagementEnabled = isFeatureEnabledForUser(
    'dnsManagement',
    featureFlags,
    authenticatedUser?.enabledFeatureFlags
  );
  const isEmployeeUser = authenticatedUser?.userRole === AuthenticatedUserRole.EMPLOYEE;
  const { firstName = '', lastName = '', email = '', companyName = '' } = authenticatedUser || {};
  const mobileId = isFeatureEnabled(featureFlags.mobileId);
  const navigationItemList = omaElisaNavigationLinksWithCurrentProperty(
    mobileId,
    pathname,
    licenseManagementEnabled,
    dnsManagementEnabled
  );
  const accordions = headerAccordions(navigate, ssoSessionValid, ringBaseUrl);
  const notifications = personalUserMenuLinks(navigate, classicSiteUrl);

  const logo = {
    label: 'Elisa',
    onClick: (e: React.SyntheticEvent) => {
      e.preventDefault();
      navigate('/');
    },
    url: '/',
  };

  const service = companyName
    ? {
        identifiers: ['OmaElisa', t.KBLX(forCorporationsMsg)],
        label: '',
        onClick: (e: React.SyntheticEvent) => {
          e.preventDefault();
          navigate(paths.SELF_SERVICE_HOME);
        },
        url: paths.SELF_SERVICE_HOME,
      }
    : undefined;

  const languageSelect = {
    languages: [
      { name: suomiMsg, abbr: 'fi' },
      { name: englishMsg, abbr: 'en' },
    ],
    selectedLanguage: siteLanguage.substring(0, 2),
    onLanguageChange: ({ lang }: { lang: string }) => {
      saveUserPreferredLanguage(lang);
    },
  };

  const personalLinks =
    authenticatedUser && userAccounts.length > 0
      ? [
          <CompanySelector
            id="userMenuCompanySelector"
            key="headerCompanySelector"
            onInputChange={(_e: ChangeEvent | KeyboardEvent | React.MouseEvent, option: Partial<HeaderUserAccount>) => {
              if (option?.accountMasterId) {
                dispatch(setActiveAccount(option.accountMasterId, true, undefined, option?.name));
              }
            }}
            userAccounts={userAccounts}
          />,
        ]
      : [];

  const headerUser = authenticatedUser
    ? {
        email,
        fullName: `${firstName} ${lastName}`,
        name: companyName,
      }
    : undefined;

  useEffect(() => {
    setUserAccounts(getUserAccounts(authenticatedUser));
  }, [authenticatedUser]);

  return (
    <>
      {!!selectCompanyDialogUrl && (
        <SelectCompanyDialog
          onCloseDialog={() => setSelectCompanyDialogUrl(undefined)}
          onContinue={selectedCompanyId => navigate(`${selectCompanyDialogUrl}?companyId=${selectedCompanyId}`)}
        />
      )}
      <CL.Header
        desktopBreakpointOffset={35}
        className={`of-self-service-header ${searchFieldVisible ? 'of-self-service-header__search-active' : ''}`}
        isLoggedIn={Boolean(companyName)}
        languageSelect={languageSelect}
        siteSelect={siteSelect(siteBaseUrl)}
      >
        <div className={searchFieldVisible ? 'of-self-service-header__hide-on-mobile' : ''}>
          <CL.HeaderNavigationSection>
            <CL.HeaderLogoAndService logo={logo} service={service} />
          </CL.HeaderNavigationSection>
        </div>
        {searchFieldVisible ? (
          <GlobalSearch setSearchFieldVisible={setSearchFieldVisible} isMultiBiz={isMultiBiz(authenticatedUser)} />
        ) : (
          <CL.HeaderNavigationSection>
            <CL.HeaderNavigation
              id={menuId}
              languageSelect={languageSelect}
              navigationItemList={navigationItemList}
              onButtonClick={() => {}}
              onLinkClick={(e, { url }) => {
                if (url) {
                  e.preventDefault();
                  if (
                    (url === paths.BUSINESS_SECURITY || url === paths.WEB_SHOP) &&
                    isMultiBiz(authenticatedUser) &&
                    isConsolidatedViewsEnabled
                  ) {
                    setSelectCompanyDialogUrl(url);
                  } else {
                    navigate(url);
                  }
                }
              }}
              requireLogin={false}
              returnFocusToRef={hamburgerRef}
              siteSelect={siteSelect(siteBaseUrl)}
            />
          </CL.HeaderNavigationSection>
        )}
        <CL.HeaderNavigationSection className={searchFieldVisible ? 'of-self-service-header__hide-on-mobile' : ''}>
          <SearchButton
            searchFieldVisible={searchFieldVisible}
            infoPopoverVisible={searchInfoPopoverVisible}
            setSearchFieldVisible={setSearchFieldVisible}
            setInfoPopoverVisible={setSearchInfoPopoverVisible}
          />
          <CL.HeaderUserMenu
            i18nButtonLabel={t.MIFJ(myInformationMsg)}
            i18nLanguageSwitchSelectedLanguageLabel={t.FT4V(chosenLanguageMsg)}
            i18nLogin={t.Z16I(logInMsg)}
            logout={{ onClick: () => logout(paths.SELF_SERVICE_LOGOUT), title: t.LQ3X(logOutMsg) }}
            notifications={notifications}
            onToggle={(isVisible: boolean) => {
              if (isVisible) {
                setSearchFieldVisible(false);
              }
            }}
            personalLinks={personalLinks}
            ref={userMenuRef}
            user={headerUser}
            userPronounText={firstName}
            userMenuLinkAccordions={accordions}
          />
          <CL.HeaderHamburger
            i18nCloseAlt={t.WOYD(closeMsg)}
            i18nOpenAlt="menu"
            ref={hamburgerRef}
            onToggle={(isVisible: boolean) => {
              if (isVisible) {
                setSearchFieldVisible(false);
              }
            }}
            toggledModalChild={menuId}
          />
        </CL.HeaderNavigationSection>
      </CL.Header>
      <HeaderNotification />
      {isEmployeeUser && LoggedInAsEmployeeNotification}
      {searchFieldVisible && (
        <div role="presentation" onClick={() => setSearchFieldVisible(false)} className="search-backdrop" />
      )}
    </>
  );
};
