import * as React from 'react';
import { ProductDetailsQuantity } from './ProductDetailsQuantity.js';
import { ToggleCheckbox } from '../../ToggleCheckbox/ToggleCheckbox.js';
import { deductibleMsg, squareTradeDeductibleMsg, t } from '../../../common/i18n/index.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { isSquareTradeAddOn } from '../../../common/utils/addOnUtils.js';
import { valueOrZero } from '../utils/productDetailsUtils.js';
import type { AddOn, AddOnPurpose } from '../../../generated/api/models.js';

import './ProductDetailsAddOn.scss';

export interface ProductDetailsAddOnStateProperties {
  addOn: AddOn;
  display: boolean;
  addOnPurpose: AddOnPurpose;
  addOnAssociationCode: string;
  addOnAssociationId: string;
  isChecked: boolean;
  isDisabled: boolean;
  quantity: number;
}

export interface ProductDetailsAddOnProperties {
  addOns: ProductDetailsAddOnStateProperties[];
  setStatus: React.Dispatch<React.SetStateAction<ProductDetailsAddOnStateProperties[]>>;
  productQuantity: number;
}

export interface ProductDetailsAddOnItemProperties {
  addOnState: ProductDetailsAddOnStateProperties;
  setStatus: React.Dispatch<React.SetStateAction<ProductDetailsAddOnStateProperties[]>>;
  productQuantity: number;
}

const onAddonStateChangeHandler = (
  { addOnState, setStatus }: ProductDetailsAddOnItemProperties,
  checked: boolean,
  quantity: number
) => {
  setStatus(addOns =>
    addOns.map(item => {
      if (item.addOn.addOnProductName === addOnState.addOn.addOnProductName) {
        item.quantity = quantity;
        item.isChecked = checked;
      }
      return item;
    })
  );
};

interface AddonDescriptionProps {
  addOn: AddOn;
}

const AddOnDescription = ({ addOn }: AddonDescriptionProps) =>
  isSquareTradeAddOn(addOn) ? (
    <div className="of-product-details-addon-description">
      <p>
        {t.WW2M(
          'Secure your device for accident - on accidental incident you will get new equivalent replacement device with one phone call.'
        )}
        <a
          target="_blank"
          href="https://elisa.fi/asiakaspalvelu/aihe/matkapuhelinliittymat/ohje/square-trade/"
          rel="noreferrer"
        >
          {` ${t.BQ9Y('More details')}.`}
        </a>
      </p>
    </div>
  ) : null;

const getPriceInfo = (addOn: AddOn, productQuantity: number) => {
  return {
    value: (
      <span>
        {formatSum(valueOrZero(addOn.addOnMonthlyRecurringCharge) * productQuantity, true)}
        {`€/${t.XXVX('month')}`}
      </span>
    ),
    additionalText: isSquareTradeAddOn(addOn) ? (
      <div className="ds-text--xs">
        {`${t.VWSV(deductibleMsg)} ${squareTradeDeductibleMsg}. ${t.XJMC('No fixed term.')}`}
      </div>
    ) : undefined,
  };
};

const ProductDetailsAddOnItem = (addOnItem: ProductDetailsAddOnItemProperties) => {
  const {
    addOnState: { isChecked, isDisabled, addOn },
    productQuantity,
  } = addOnItem;

  const quantityChangeHandler = (quantity: number) => {
    onAddonStateChangeHandler(addOnItem, true, quantity);
  };

  return (
    <ToggleCheckbox
      disabled={isDisabled}
      initialValue={isChecked}
      onChange={() => {
        onAddonStateChangeHandler(addOnItem, !isChecked, productQuantity);
      }}
      title={addOn.addOnProductName}
      priceInfo={getPriceInfo(addOn, productQuantity)}
    >
      <>
        <AddOnDescription addOn={addOn} />
        {!isDisabled && (
          <ProductDetailsQuantity quantity={productQuantity} setQuantity={quantityChangeHandler} disabled={true} />
        )}
      </>
    </ToggleCheckbox>
  );
};

export const ProductDetailsAddOn = ({ addOns, ...rest }: ProductDetailsAddOnProperties) => {
  return (
    <>
      {addOns.map(item => (
        <ProductDetailsAddOnItem key={item.addOn.addOnCode} addOnState={item} {...rest} />
      ))}
    </>
  );
};
