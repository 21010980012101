import { CreateInterworksAccountRequest } from '../../../generated/api/createInterworksAccountRequest.js';
import { DeliveryMethod } from '../../../common/react-hook-form/fields/DeliveryMethod.js';
import { ElectronicAddress } from '../../../common/react-hook-form/fields/ElectronicAddress.js';
import { ElectronicOperator } from '../../../common/react-hook-form/fields/ElectronicOperator.js';
import {
  FORM_GRID_FULL_WIDTH,
  FormGridFieldset,
} from '../../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { TextInput } from '../../../common/react-hook-form/fields/index.js';
import { billingAddressAlias, eInvoiceMsg, emailInvoiceMsg, paymentDetailsMsg, t } from '../../../common/i18n/index.js';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { BillChannel } from '../../../generated/api/billChannel.js';

interface BillingDetailsProps {
  billChannels: BillChannel[];
}
export const BILLING_DELIVERY_METHOD = 'billingDeliveryMethod';
export const BILLING_BILL_E_OPERATOR = 'billingElectronicOperator';
export const BILLING_BILL_E_ADDRESS = 'billingElectronicAddress';
export const BILLING_ADDRESS_ALIAS = 'billingAddressAlias';

export const BillingFieldset = (props: BillingDetailsProps) => {
  const { watch, resetField } = useFormContext();
  const billingDeliveryMethod = watch(BILLING_DELIVERY_METHOD);

  useEffect(() => {
    if (billingDeliveryMethod === CreateInterworksAccountRequest.BillingDeliveryMethodEnum.EMAIL) {
      resetField(BILLING_BILL_E_OPERATOR);
      resetField(BILLING_BILL_E_ADDRESS);
    }
  }, [billingDeliveryMethod, resetField]);

  const deliveryMethodOptions = [
    {
      value: CreateInterworksAccountRequest.BillingDeliveryMethodEnum.EMAIL,
      label: t.NNV9(emailInvoiceMsg),
    },
    {
      value: CreateInterworksAccountRequest.BillingDeliveryMethodEnum.ELECTRONIC,
      label: t.IK1D(eInvoiceMsg),
    },
  ];

  return (
    <FormGridFieldset legend={t.EE4N(paymentDetailsMsg)}>
      <div className={FORM_GRID_FULL_WIDTH}>
        <DeliveryMethod
          name={BILLING_DELIVERY_METHOD}
          billChannels={props.billChannels}
          customDeliveryMethodOptions={deliveryMethodOptions}
        />
      </div>
      {billingDeliveryMethod === CreateInterworksAccountRequest.BillingDeliveryMethodEnum.ELECTRONIC && (
        <>
          <ElectronicOperator name={BILLING_BILL_E_OPERATOR} billChannels={props.billChannels} />
          <div className="ds-margin-bottom--4">
            <ElectronicAddress name={BILLING_BILL_E_ADDRESS} />
          </div>
        </>
      )}
      <TextInput label={t.LFU7(billingAddressAlias)} name={BILLING_ADDRESS_ALIAS} />
    </FormGridFieldset>
  );
};
