import * as CL from '@design-system/component-library';
import { BusinessId } from '../../common/react-hook-form/fields/BusinessId.js';
import { Email, Name, PhoneNumber, TextInput } from '../../common/react-hook-form/fields/index.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Lead } from '../../generated/api/lead.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { Spinner } from '../../public/site/siteUtils.js';
import { addAnonymousLead, addLead } from '../../selfservice/actions/index.js';
import {
  businessIdMsg,
  companyNameMsg,
  dataProtectionMsg,
  firstNameMsg,
  informantBISMsg,
  lastNameMsg,
  loadingMsg,
  t,
} from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type {
  ClickableInputField,
  LeadContactDetails,
  TrainingResponse,
  WritableInputFields,
} from '../../generated/api/models.js';
import type { LeadsState } from '../../common/types/states.js';
import type { PropsWithChildren } from 'react';

import './TrainingEnrollment.scss';

export interface TrainingEnrollmentProps {
  contactId: string | undefined;
  defaultValues: { [s: string]: string | object } | undefined;
  additionalInformationSchema: {
    id: string;
    label: string;
    placeholder: string;
    type: string;
    required: boolean;
    disabled: boolean;
  }[];
  trainingResponse: TrainingResponse;
  trainingSchema: Array<ClickableInputField>;
  leadsState?: LeadsState | null;
}

type TrainingOnSubmitValues = {
  businessId: string;
  businessName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  additionalInformation: string;
  trainingId: string;
};

export const setAdditionalInformationSchema = (obj: WritableInputFields) => {
  const base = {
    id: 'additionalInformation',
    label: t.GZUK('Additional information'),
    placeholder: t.GZUK('Additional information'),
    type: 'text',
    required: false,
    disabled: false,
  };
  return [{ ...base, ...obj }];
};

const DataProtection = () => (
  <CL.Grid className="ds-grid">
    <CL.GridRow justifyCenter>
      <CL.GridCol className="ds-grid__col" colWidth={12}>
        <h4>{t.TBCM(dataProtectionMsg)}</h4>
        <p>
          {t.HPH9(
            'Personal data is processed in connection with registration, organizing and carrying out the training in accordance with Elisa’s data protection principles. Elisa is the data controller pursuant to the applicable data protection legislation. Elisa may also use subcontractors for providing services and processing personal data.'
          )}
        </p>
        <p>
          {`${t.ATD5('Further information:')} `}
          <a
            href={t.E0FK('https://elisa.com/attachment/content/Data_protection_principles_eng.pdf')}
            target="_BLANK"
            rel="noreferrer"
          >
            {t.EO6B('Elisa’s data protection principles')}
          </a>
          {` ${t.F43F('and')} `}
          <a
            href={t.GOPL('https://yrityksille.elisa.fi/ohje/elisan-sopimusehdot-yritysasiakkaille')}
            target="_BLANK"
            rel="noreferrer"
          >
            {t.G6IO('Elisa’s general terms and conditions')}
          </a>
          .
        </p>
      </CL.GridCol>
    </CL.GridRow>
  </CL.Grid>
);

const HALF_WIDTH_ON_DESKTOP_FULL_WIDTH_ON_OTHERS = {
  colWidthXS: 4,
  colWidthS: 6,
  colWidthM: 6,
  colWidthL: 6,
  colWidthXL: 6,
} as const;

const TrainingFormPage = (props: PropsWithChildren<TrainingEnrollmentProps>) => {
  const { siteBaseUrl } = useContext(SiteContext);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { trainingResponse, additionalInformationSchema, defaultValues = {} } = props;
  const methods = useForm({ mode: 'all', defaultValues });
  const { trainingId } = methods.watch();

  const { businessId, businessName, firstName, lastName } = {
    businessId: t.MPA5(businessIdMsg),
    businessName: t.AJ93(companyNameMsg),
    firstName: t.AIK7(firstNameMsg),
    lastName: t.Y41S(lastNameMsg),
  };

  const onSubmit = (data: TrainingOnSubmitValues) => {
    const training = trainingResponse.trainingContent.find(i => i.id === data.trainingId);
    const contactDetails: LeadContactDetails = {
      businessId: data.businessId,
      businessName: data.businessName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
    };
    const lead: Lead = {
      leadType: Lead.LeadTypeEnum.ONLINE_TRAINING,
      description: data.additionalInformation || '',
      sourceUrl: siteBaseUrl + pathname,
      formParameters: {
        id: training?.id || '',
        title: trainingResponse.title || '',
        date: training?.label || '',
        price: training?.value || '',
      },
    };
    dispatch(
      props.contactId ? addLead({ contactId: props.contactId, lead }) : addAnonymousLead({ lead, contactDetails })
    );
  };

  return (
    <div className="of-training-enrollment-main">
      <style>
        {trainingResponse.trainingContent.map(
          ({ value }, i) => `.ds-radio:nth-child(${i + 1}):after { content: '${value}' }`
        )}
      </style>
      <CL.Grid className="ds-grid">
        <CL.GridRow justifyCenter>
          <CL.GridCol className="ds-grid__col" colWidth={12}>
            <h1>{trainingResponse.title}</h1>
            {trainingResponse.content?.map((item, i) => <p key={`content-${i}`}>{item}</p>)}
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow justifyCenter>
          <CL.GridCol className="ds-grid__col" colWidth={12}>
            <h2>{trainingResponse.additionalTitle}</h2>
            {trainingResponse.additionalContent?.map((item, i) => <p key={`additional-content-${i}`}>{item}</p>)}
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <CL.Grid className="of-training-enrollment__content">
            <CL.GridRow justifyCenter>
              <CL.GridCol className="ds-grid__col" {...HALF_WIDTH_ON_DESKTOP_FULL_WIDTH_ON_OTHERS}>
                <h3>{trainingResponse.trainingTitle}</h3>
                <SelectRadio
                  name="trainingId"
                  defaultValue={trainingResponse.trainingContent[0].value}
                  items={trainingResponse.trainingContent.map(({ label, id }) => ({
                    label,
                    value: id,
                  }))}
                />

                <div className="of-training-enrollment__total-price adjust-content-based-on-checkbox">
                  <h3>{t.CEQ2('In total')}</h3>
                  <p className="of-training-enrollment__price-row">
                    <span>{t.GOBY('Single fare')}</span>
                    <span className="ds-float--right">
                      {trainingResponse.trainingContent.find(i => i.id === trainingId)?.value}
                    </span>
                  </p>
                  <p className="ds-text--xs ds-float--right">{t.TEA3('All prices VAT 0%')}</p>
                </div>
              </CL.GridCol>
              <CL.GridCol className="ds-grid__col" {...HALF_WIDTH_ON_DESKTOP_FULL_WIDTH_ON_OTHERS}>
                <h3>{t.IRQS('Company billing information')}</h3>
                <p className="ds-text--xs">{t.V9OQ(informantBISMsg)}</p>

                <BusinessId
                  name="businessId"
                  label={businessId}
                  placeholder={businessId}
                  disabled={Boolean(defaultValues.businessId)}
                />
                <TextInput
                  name="businessName"
                  label={businessName}
                  placeholder={businessName}
                  disabled={Boolean(defaultValues.businessName)}
                />
                <Name
                  name="firstName"
                  label={firstName}
                  placeholder={firstName}
                  disabled={Boolean(defaultValues.firstName)}
                />
                <Name
                  name="lastName"
                  label={lastName}
                  placeholder={lastName}
                  disabled={Boolean(defaultValues.lastName)}
                />
                <PhoneNumber disabled={Boolean(defaultValues.phoneNumber)} />
                <Email disabled={Boolean(defaultValues.email)} />

                <h3>{trainingResponse.additionalInformationTitle || t.GZUK('Additional information')}</h3>

                {additionalInformationSchema.map(field => {
                  return (
                    <TextInput
                      key={field.id}
                      name={field.id}
                      label={field.label}
                      placeholder={field.label}
                      required={field.required}
                      disabled={field.disabled}
                    />
                  );
                })}

                {props.leadsState?.leadFailed && (
                  <CL.Notification
                    hasCloseButton={false}
                    text={t.JDTO('Something went wrong. Please try again.')}
                    type="error"
                  />
                )}
              </CL.GridCol>
            </CL.GridRow>
            <CL.GridRow justifyCenter>
              <CL.GridCol className="ds-grid__col ds-text-align--end" colWidth={12}>
                <CL.Button
                  i18n_button_loading_ariaText={t.KW12(loadingMsg)}
                  id="of-training-enrollment-submit"
                  loading={!!props.leadsState?.leadInProgress}
                  size="l"
                  type="submit"
                >
                  {trainingResponse.submitButtonText || t.EAJM('Sign up')}
                </CL.Button>
              </CL.GridCol>
            </CL.GridRow>
          </CL.Grid>
        </form>
      </FormProvider>
      <DataProtection />
    </div>
  );
};

// TODO Sebastian 1.6.2022 Consider adding thank you page: e.g. /ilmo/kiitos or /ilmo/:trainingName/kiitos
const ThankYouPage = ({ trainingResponse }: PropsWithChildren<TrainingEnrollmentProps>) => (
  <CL.Grid className="ds-grid">
    <CL.GridRow justifyCenter>
      <CL.GridCol className="ds-grid__col of-training-enrollment-thank-you" colWidth={12}>
        <h2>{trainingResponse.thankYouPageTitle}</h2>
        {trainingResponse.thankYouPageContent?.map((item, i) => <p key={`thank-you-content-${i}`}>{item}</p>)}
        {trainingResponse.thankYouPageLinkText && trainingResponse.thankYouPageLinkUrl && (
          <p>
            <a href={trainingResponse.thankYouPageLinkUrl}>{trainingResponse.thankYouPageLinkText}</a>
          </p>
        )}
      </CL.GridCol>
    </CL.GridRow>
  </CL.Grid>
);

export const TrainingEnrollment = (props: PropsWithChildren<TrainingEnrollmentProps>) => {
  const { anonymousUser, authenticatedUser } = useAuth();

  if ((!anonymousUser && !authenticatedUser) || authenticatedUser?.isFetching === true) {
    return <Spinner />;
  }
  return (
    <div className="of-training-enrollment">
      {!props.leadsState?.leadSent ? <TrainingFormPage {...props} /> : <ThankYouPage {...props} />}
    </div>
  );
};
