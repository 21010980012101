import * as CL from '@design-system/component-library';
import { Ribbon } from '../Ribbon/Ribbon.js';
import { SfToggles } from '../../common/enums.js';
import { Sticker } from '../Sticker/Sticker.js';
import { TurbonappiOrderForm } from '../Turbonappi/TurbonappiOrderForm.js';
import { activationFeeMsg, alvZeroMsg, noFixedTermMsg, saveMsg, sumPerMonthMsg, t } from '../../common/i18n/index.js';
import { formatSumToString } from '../../common/utils/priceUtils.js';
import { getTurbonappiSelectItemEvent } from '../Turbonappi/turbonappiAnalyticsUtils.js';
import { isFiveGPlusVoiceOffer } from '../../common/utils/subscriptionUtils.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { shouldShowSeparator } from './subscriptionCardUtils.js';
import { useState } from 'react';
import classNames from 'classnames';
import type { MouseEvent } from 'react';
import type { SubscriptionCardType } from '../../common/enums.js';
import type { TurbonappiOffer } from '../../generated/api/turbonappiOffer.js';

import './SubscriptionCard.scss';

type SubscriptionCardHeadingProps = {
  description?: string | JSX.Element;
  subDescription?: string | JSX.Element;
  // 'Family name', e.g. 'Yritysliittymä' or 'Laitenetti'
  // or something like the 5G pill.
  name: string | JSX.Element;
  // Speed moniker, e.g. '100M' or 'L'
  mainHeader: string;
  voucherCode?: string;
  groupHeader?: string;
};

type SubscriptionCardServices = {
  name: string | JSX.Element;
  guid: string;
  icon?: string;
  isIncluded: boolean;
  popoverText?: string;
  popoverContent?: string | JSX.Element;
  hidden?: boolean;
};

export type SubscriptionCardContentProps = {
  bullets?: string[];
  features?: {
    icon: string;
    text: string;
    title: string;
  }[];
  inPublicStore?: boolean;
  text?: string[];
  selectedOffer?: string;
  // This is used with Laitenetti. With Laitenetti we only have one offer and cards are addons for that offer.
  selectedAddon?: string;
  onChangeOffer?: (offerGuid: string, groupName?: string) => void;
  contentHeader?: string;
  offers?: SubscriptionCardOffer[];
  specifications?: SubscriptionCardOfferSpecification[];
  services?: SubscriptionCardServices[];
  abTestingOption?: string;
  showContentSeparators?: boolean;
};

type OfferButtonProps = {
  offers?: SubscriptionCardOffer[];
  selectedOffer?: string;
  onChangeOffer?: (offerGuid?: string) => void;
};

export type SubscriptionCardOffer = {
  guid: string;
  icon?: string;
  // This is for the 'public' store, i.e. https://yrityksille.elisa.fi/yritysliittymat and such
  salesProduct: {
    oldNumberOfferCode?: string;
    onlineModelCode: string;
    newNumberOfferCode: string;
  };
  text: string;
};

export type SubscriptionCardOfferSpecification = {
  name: string;
  value?: { [guid: string]: string };
  icon?: string;
  popoverText?: string;
  popoverContent?: string | JSX.Element;
  disabled?: boolean;
};

export type MbbSalesProductProps = {
  salesProduct: {
    offerCode: string;
    onlineModelCode: string;
  };
};

export type TurbonappiProps = {
  turbonappiPrice: string;
  priceUnit: string;
  turbonappiPriceAdditionalInfo: string;
  turbonappiOffer: TurbonappiOffer;
  turbonappiFullName: string;
};

type SubscriptionCardPriceProps = Partial<TurbonappiProps> & {
  fixedTermMonths?: number;
  monthlyPrice?: number;
  oneTimePrice?: number;
  serviceFee?: number;
  originalMonthlyPrice?: number;
  originalOneTimePrice?: number;
  pricePreamble?: string;
  showSticker?: boolean;
  selectButton?: JSX.Element;
  turbonappiOrderForm?: JSX.Element;
};

type SubscriptionCardFooterProps = {
  // ID of element that the button controls (if any)
  buttonControls?: string;
  buttonDisabled?: boolean;
  buttonText?: string;
  separator?: boolean;
  // We might want to disable the button until we've loaded the product data
  isLoading?: boolean;
  onButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  showSticker?: boolean;
  selectedOffer?: string;
  footerDisclaimer?: { [guid: string]: string };
} & SubscriptionCardPriceProps;

export type SubscriptionCardProps = {
  color?: 'orange' | 'turquoise';
  recommended?: boolean;
  // We pass along ribbonWidth so that if we have a bunch of cards,
  // we can match the ribbon width between them.
  ribbonWidth?: number;
  selected?: boolean;
  showSticker?: boolean;
  cardType?: SubscriptionCardType;
  cardName?: string;
} & SubscriptionCardHeadingProps &
  SubscriptionCardContentProps &
  Omit<SubscriptionCardFooterProps, 'separator'>;

export type SubCardProps = Exclude<SubscriptionCardProps, 'color' | 'ribbonWidth'>;
export type MobileBroadbandSubCardProps = SubCardProps & MbbSalesProductProps;

const SubscriptionCardHeading = ({
  description,
  name,
  mainHeader,
  voucherCode,
  subDescription,
}: SubscriptionCardHeadingProps) => (
  <>
    <div className={`of-subscription-card--${voucherCode ? 'voucher' : 'no-voucher'}`}>{voucherCode}</div>
    <h2 className={subDescription ? 'ds-margin--0' : ''}>
      <div className="ds-h4">{name}</div>
      <div className={`ds-text--display ${subDescription ? 'ds-margin--0' : ''}`}>{mainHeader}</div>
    </h2>
    {description ? (
      <>
        <div className="ds-h4">{description}</div>
        <div className="ds-h5">{subDescription}</div>
      </>
    ) : (
      // Adjusts the top margin to compensate for the absence of a description element and prevent excessive empty space.
      <div className="of-subscription-card__remove-top-margin" />
    )}
  </>
);

const isNotEmpty = (inputArray?: object[] | string[]) => Boolean(inputArray && inputArray.length > 0);

const OfferButtons = ({ offers, selectedOffer, onChangeOffer }: OfferButtonProps) => {
  return (
    <div className="of-subscription-card--button">
      {offers?.map((offer, i) => (
        <CL.Button
          size="s"
          key={`offer-selection-${i}`}
          className={`${selectedOffer && offer.guid === selectedOffer ? 'selected' : ''}`}
          onClick={() => onChangeOffer?.(offer.guid)}
        >
          <>
            <div className={`of-subscription-card--button--icon--${offer.icon} ds-margin-right--1`} />
            <div className="of-subscription-card--button--text">{offer.text}</div>
          </>
        </CL.Button>
      ))}
    </div>
  );
};

interface CardServicesProps {
  inPublicStore: boolean;
  services?: SubscriptionCardServices[];
}

const CardServices = ({ inPublicStore, services }: CardServicesProps) => {
  const getServicesRows = (filteredServices: SubscriptionCardServices[]) => (
    <div className="of-subscription-card--service--items">
      {filteredServices.map((service, i) => (
        <div key={`service-included-item-${i}`} className="of-subscription-card--service--items--item">
          <div className={`icon icon--${service.icon}`} />
          <div className="ds-text-align--left">{service.name}</div>
          <div className="popover">
            <CL.Popover
              triggerElement={<CL.Icon icon="information" size="s" type="light" color="neutral-700" />}
              placement="top"
              i18n_popover_contentText={service.popoverText}
            >
              {service.popoverContent}
            </CL.Popover>
          </div>
        </div>
      ))}
    </div>
  );

  return services?.some(s => !s.hidden) ? (
    <div className="ds-margin-top--3 of-subscription-card--service">
      <div className="of-subscription-card--service--column">
        {services.some(s => s.isIncluded) && (
          <>
            <div className="ds-h5 ds-text-align--left ds-margin-top--1 ds-margin-bottom--1">
              {t.QQ80('Subscription includes')}
            </div>
            {getServicesRows(services.filter(s => s.isIncluded && !s.hidden))}
          </>
        )}
        {services.some(s => !s.isIncluded) && (
          <>
            <div className="ds-h5 ds-text-align--left ds-margin-top--3 ds-margin-bottom--1">
              {inPublicStore ? t.RMXE('Available in OmaElisa') : t.RMXD('Buy additionally')}
            </div>
            {getServicesRows(services.filter(s => !s.isIncluded && !s.hidden))}
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

const OfferSpecifications = ({
  specifications,
  selectedOffer,
  hasContentHeader,
}: {
  specifications?: SubscriptionCardOfferSpecification[];
  selectedOffer: string;
  hasContentHeader: boolean;
}) => {
  const iconColor = (disabled?: boolean) => (disabled ? 'neutral-300' : 'neutral-700');

  return (
    // Reduce top margin if a content header is present to avoid excessive spacing, since the header includes its own margin
    <div className={hasContentHeader ? 'ds-margin-top--2' : 'ds-margin-top--3'}>
      {specifications?.map((specification, i) => (
        <div
          key={`offer-inclusion-row-${i}`}
          className={`of-subscription-card--specification${
            specification.disabled ? '--disabled' : ''
          } ds-display--flex ds-justify-content--space-between ds-margin-top--1`}
          aria-disabled="true"
        >
          <div className="ds-text--xs of-subscription-card--specification--start">
            {specification.popoverText || specification.popoverContent ? (
              <div className="ds-display--flex">
                <div className="ds-margin-right--2">{specification.name}</div>
                <CL.Popover
                  triggerElement={
                    <CL.Icon icon="information" size="s" type="light" color={iconColor(specification.disabled)} />
                  }
                  placement="right"
                  i18n_popover_contentText={specification.popoverText}
                >
                  {specification.popoverContent}
                </CL.Popover>
              </div>
            ) : (
              specification.name
            )}
          </div>
          <div className="of-subscription-card--specification--word-separator"></div>
          <div className="ds-text--xs of-subscription-card--specification--end">
            {specification.icon ? (
              <CL.Icon icon={specification.icon} size="s" type="light" color={iconColor(specification.disabled)} />
            ) : (
              specification.value?.[selectedOffer]
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const Features = ({
  features,
  text = [],
  showContentSeparators = true,
}: Pick<SubscriptionCardContentProps, 'features' | 'text' | 'showContentSeparators'>) => (
  <>
    {showContentSeparators && <hr aria-hidden="true" />}
    <div className="of-subscription-card__specs">
      <ul className="of-subscription-card__icongrid ds-text--xs">
        {features &&
          features.map(feature => (
            <li key={feature.title}>
              <CL.Icon icon={feature.icon} size="s" type="light" />
              <span className="ds-sr-only">{feature.title}</span> <span>{feature.text}</span>
            </li>
          ))}
      </ul>
      {text.map((line, idx) => (
        <p className="ds-text--xs" key={idx}>
          {line}
        </p>
      ))}
    </div>
  </>
);

const Bullets = ({ bullets = [] }: Pick<SubscriptionCardContentProps, 'bullets'>) => (
  <>
    <hr aria-hidden="true" />
    <ul className="of-subscription-card__bullets ds-list ds-list--icon">
      {bullets.map(bulletText => (
        <li className="ds-text--s" key={bulletText}>
          <CL.Icon icon="check" size="s" type="filled" />
          {bulletText}
        </li>
      ))}
    </ul>
  </>
);

const SubscriptionCardContent = ({
  bullets,
  features,
  text = [],
  contentHeader,
  inPublicStore,
  offers,
  specifications,
  selectedOffer,
  onChangeOffer,
  services,
  abTestingOption,
  showContentSeparators = true,
}: SubscriptionCardContentProps) => {
  const getAbTestingOptionSpecificContent = () => {
    switch (abTestingOption) {
      case SfToggles.SHOW_OPTION_B:
        return (
          <div className="of-subscription-card--content--option-a">
            {isNotEmpty(services) && <CardServices inPublicStore={Boolean(inPublicStore)} services={services} />}
            <div>
              {isNotEmpty(services) && (
                <div className="ds-margin-bottom--3">
                  <hr aria-hidden="true" />
                </div>
              )}
              {isNotEmpty(specifications) && (
                <OfferSpecifications
                  specifications={specifications}
                  selectedOffer={selectedOffer || ''}
                  hasContentHeader={Boolean(contentHeader)}
                />
              )}
            </div>
          </div>
        );
      case SfToggles.SHOW_OPTION_C:
        return (
          <div className="of-subscription-card--content--option-c">
            {isNotEmpty(services) && (
              <div className="ds-margin-bottom--3">
                <CardServices inPublicStore={Boolean(inPublicStore)} services={services} />
              </div>
            )}
            {isNotEmpty(specifications) && (
              <OfferSpecifications
                specifications={specifications}
                selectedOffer={selectedOffer || ''}
                hasContentHeader={Boolean(contentHeader)}
              />
            )}
          </div>
        );
      case SfToggles.SHOW_OPTION_A:
      default:
        return (
          <div className="of-subscription-card--content--option-b">
            {isNotEmpty(specifications) && (
              <OfferSpecifications
                specifications={specifications}
                selectedOffer={selectedOffer || ''}
                hasContentHeader={Boolean(contentHeader)}
              />
            )}
            {isNotEmpty(services) && (
              <div className="ds-margin-top--3">
                <CardServices inPublicStore={Boolean(inPublicStore)} services={services} />
              </div>
            )}
          </div>
        );
    }
  };
  return (
    <div className={`${isNotEmpty(offers) ? 'of-subscription-card--content' : ''}`}>
      {(contentHeader || isNotEmpty(offers) || isNotEmpty(specifications)) && showContentSeparators && (
        <hr aria-hidden="true" />
      )}
      {contentHeader && <div className="ds-h5 ds-margin-top--3">{contentHeader}</div>}
      {isNotEmpty(offers) && (
        <OfferButtons offers={offers} selectedOffer={selectedOffer} onChangeOffer={onChangeOffer} />
      )}
      {getAbTestingOptionSpecificContent()}
      {(isNotEmpty(features) || (text && text.length > 0)) && (
        <Features features={features} showContentSeparators={showContentSeparators} text={text} />
      )}
      {bullets && bullets.length > 0 && <Bullets bullets={bullets} />}
    </div>
  );
};

const getMonthlySaving = (monthlyPrice?: number, originalMonthlyPrice?: number) =>
  monthlyPrice != null ? (originalMonthlyPrice ?? monthlyPrice) - monthlyPrice : 0;

const getOneTimeSaving = (oneTimePrice?: number, originalOneTimePrice?: number) =>
  (originalOneTimePrice ?? (oneTimePrice || 0)) - (oneTimePrice || 0);

const hasOffer = (
  monthlyPrice?: number,
  oneTimePrice?: number,
  originalMonthlyPrice?: number,
  originalOneTimePrice?: number
) =>
  getMonthlySaving(monthlyPrice, originalMonthlyPrice) > 0 || getOneTimeSaving(oneTimePrice, originalOneTimePrice) > 0;

const getPriceString = (monthlyPrice?: number, priceString?: string): string => {
  if (priceString) {
    return priceString;
  }
  const monthlyPriceStr = formatSumToString(monthlyPrice, true);
  return monthlyPrice != null ? monthlyPriceStr : monthlyPriceStr.replace(/0/g, '_');
};

const SubscriptionCardPriceAdditionalInfo = ({
  monthlySaving,
  fixedTermMonths,
  oneTimePrice,
  originalOneTimePrice,
  originalMonthlyPrice,
  serviceFee,
  showSticker,
}: SubscriptionCardPriceProps & { monthlySaving: number }) => {
  return (
    <div className="ds-price-additional">
      {showSticker && monthlySaving > 0 && (
        <div>
          {t.SYWS('Normal price')} {t.YO7F(sumPerMonthMsg, formatSumToString(originalMonthlyPrice))}
        </div>
      )}
      <div>
        <span>
          {fixedTermMonths ? t.IWHR('{} month fixed term contract', `${fixedTermMonths}`) : t.XJMB(noFixedTermMsg)}
          {', '}
        </span>
        {serviceFee && t.IKVP('Change subscription type fee {}', formatSumToString(serviceFee))}
        {!serviceFee && oneTimePrice != null && `${t.HWDR(activationFeeMsg)} ${formatSumToString(oneTimePrice)}`}
        {!serviceFee && originalOneTimePrice != null && oneTimePrice != null && originalOneTimePrice > oneTimePrice && (
          <>
            {' '}
            <del>{formatSumToString(originalOneTimePrice)}</del>
          </>
        )}
        <span>, {t.S8TX(alvZeroMsg)}</span>
      </div>
    </div>
  );
};

const SubscriptionCardPriceAndUnit = ({
  monthlyPrice,
  pricePreamble,
  turbonappiPrice,
  priceUnit,
  monthlySaving,
  showSticker = false,
}: SubscriptionCardPriceProps & { monthlySaving: number }) => {
  return (
    <div className="ds-price-content">
      {showSticker && monthlySaving > 0 && (
        <Sticker saving={t.YO7F(sumPerMonthMsg, formatSumToString(monthlySaving))} text={t.HWDT(saveMsg)} />
      )}
      {pricePreamble && <div className="ds-text--s of-subscription-card__price-preamble">{pricePreamble}</div>}
      <div className="ds-price-content-number">
        {getPriceString(monthlyPrice, turbonappiPrice)}
        <span className="of-subscription-card__price-unit">
          {priceUnit ? ` ${priceUnit}` : ` ${t.YO7F('{}/month', '€')}`}
        </span>
      </div>
    </div>
  );
};

// We have to recreate the CL.Price component here, as we can't insert the preamble and sticker elements in the real one.
const SubscriptionCardPrice = ({
  fixedTermMonths,
  monthlyPrice,
  oneTimePrice,
  originalMonthlyPrice,
  originalOneTimePrice,
  pricePreamble,
  turbonappiPrice,
  priceUnit,
  turbonappiPriceAdditionalInfo,
  showSticker,
  serviceFee,
  selectButton,
  turbonappiOrderForm,
}: SubscriptionCardPriceProps) => {
  const monthlySaving = getMonthlySaving(monthlyPrice, originalMonthlyPrice);
  return (
    <div className={`ds-price of-subscription-card__price ${turbonappiPriceAdditionalInfo ? 'turbonappi-form' : ''}`}>
      <div className="of-subscription-card__price__text-and-button">
        <SubscriptionCardPriceAndUnit
          monthlyPrice={monthlyPrice}
          pricePreamble={pricePreamble}
          turbonappiPrice={turbonappiPrice}
          priceUnit={priceUnit}
          monthlySaving={monthlySaving}
          showSticker={showSticker}
        />
        {selectButton}
      </div>
      <div className="of-subscription-card__price__original-price">
        <del>{monthlySaving > 0 && `${formatSumToString(originalMonthlyPrice, true)} ${t.YO7F('{}/month', '€')}`}</del>
      </div>
      {turbonappiPriceAdditionalInfo ? (
        <div className="ds-price-additional">
          <div>{turbonappiPriceAdditionalInfo}</div>
          <div className="of-subscription-card__price__turbonappi-form">{turbonappiOrderForm}</div>
        </div>
      ) : (
        <SubscriptionCardPriceAdditionalInfo
          monthlySaving={monthlySaving}
          originalMonthlyPrice={originalMonthlyPrice}
          oneTimePrice={oneTimePrice}
          originalOneTimePrice={originalOneTimePrice}
          fixedTermMonths={fixedTermMonths}
          serviceFee={serviceFee}
          turbonappiPriceAdditionalInfo={turbonappiPriceAdditionalInfo}
          showSticker={showSticker}
        />
      )}
    </div>
  );
};

const SubscriptionCardFooter = ({
  buttonControls,
  buttonDisabled,
  buttonText,
  fixedTermMonths,
  isLoading,
  monthlyPrice,
  onButtonClick,
  oneTimePrice,
  originalMonthlyPrice,
  originalOneTimePrice,
  pricePreamble,
  turbonappiPrice,
  priceUnit,
  turbonappiPriceAdditionalInfo,
  turbonappiOffer,
  showSticker,
  serviceFee,
  selectedOffer,
  footerDisclaimer,
}: SubscriptionCardFooterProps) => {
  const [showTurboNappiContent, setShowTurboNappiContent] = useState(false);
  if (turbonappiPrice) {
    onButtonClick = () => {
      setShowTurboNappiContent(!showTurboNappiContent);
      pushToDataLayer(getTurbonappiSelectItemEvent(turbonappiOffer));
    };
  }
  const turbonappiOrderForm = showTurboNappiContent ? <TurbonappiOrderForm turbonappiOffer={turbonappiOffer} /> : <></>;
  const selectButton = showTurboNappiContent ? (
    <></>
  ) : (
    <div className="of-subscription-card__button">
      <CL.Button
        aria-controls={buttonControls}
        color={turbonappiPrice ? 'light' : 'linkblue'}
        disabled={buttonDisabled}
        loading={isLoading}
        onClick={onButtonClick}
        size="l"
      >
        {buttonText}
      </CL.Button>
    </div>
  );

  return (
    <>
      {footerDisclaimer && (
        <div className="of-subscription-card--footer-disclaimer">{footerDisclaimer[selectedOffer || '']}</div>
      )}
      <hr aria-hidden="true" />
      <SubscriptionCardPrice
        fixedTermMonths={fixedTermMonths}
        monthlyPrice={monthlyPrice}
        oneTimePrice={oneTimePrice}
        originalMonthlyPrice={originalMonthlyPrice}
        originalOneTimePrice={originalOneTimePrice}
        pricePreamble={pricePreamble}
        turbonappiPrice={turbonappiPrice}
        priceUnit={priceUnit}
        turbonappiPriceAdditionalInfo={turbonappiPriceAdditionalInfo}
        showSticker={showSticker}
        serviceFee={serviceFee}
        selectButton={selectButton}
        turbonappiOrderForm={turbonappiOrderForm}
      />
    </>
  );
};

const getRibbonText = (
  monthlyPrice?: number,
  oneTimePrice?: number,
  originalMonthlyPrice?: number,
  originalOneTimePrice?: number,
  recommended?: boolean
): string[] | undefined => {
  if (recommended) {
    return [t.TJPP('Recommended')];
  }
  if (hasOffer(monthlyPrice, oneTimePrice, originalMonthlyPrice, originalOneTimePrice)) {
    return [t.TJPQ('Offer')];
  }
  return undefined;
};

export const SubscriptionCard = ({
  bullets,
  buttonControls,
  buttonText,
  description,
  features,
  fixedTermMonths,
  isLoading,
  monthlyPrice,
  name,
  onButtonClick,
  oneTimePrice,
  originalMonthlyPrice,
  originalOneTimePrice,
  pricePreamble,
  recommended,
  selected,
  mainHeader,
  text,
  turbonappiPrice,
  priceUnit,
  turbonappiPriceAdditionalInfo,
  turbonappiOffer,
  showSticker,
  contentHeader,
  inPublicStore,
  offers,
  selectedOffer,
  onChangeOffer,
  specifications,
  services,
  serviceFee,
  voucherCode,
  footerDisclaimer,
  cardName,
  abTestingOption,
  showContentSeparators,
  subDescription,
}: SubscriptionCardProps) => {
  const color = recommended ? 'turquoise' : undefined;
  const ribbonText = getRibbonText(monthlyPrice, oneTimePrice, originalMonthlyPrice, originalOneTimePrice, recommended);
  return (
    <div className="of-subscription-card--card-wrapper">
      <CL.Card
        badge={ribbonText ? <Ribbon color={color} text={ribbonText} /> : ''}
        className={classNames([
          'of-subscription-card',
          (recommended || selected) && `of-subscription-card--highlight-${selected ? 'blue' : color}`,
        ])}
        content={
          <SubscriptionCardContent
            bullets={bullets}
            features={features}
            inPublicStore={inPublicStore}
            text={text}
            contentHeader={contentHeader}
            offers={offers}
            selectedOffer={selectedOffer}
            onChangeOffer={(guid: string) => {
              onChangeOffer?.(guid, cardName);
            }}
            specifications={specifications}
            services={services}
            abTestingOption={abTestingOption}
            showContentSeparators={showContentSeparators}
          />
        }
        footer={
          <SubscriptionCardFooter
            buttonControls={buttonControls}
            buttonDisabled={selected}
            buttonText={buttonText}
            fixedTermMonths={fixedTermMonths}
            isLoading={isLoading}
            monthlyPrice={monthlyPrice}
            onButtonClick={onButtonClick}
            oneTimePrice={oneTimePrice}
            originalMonthlyPrice={originalMonthlyPrice}
            originalOneTimePrice={originalOneTimePrice}
            pricePreamble={pricePreamble}
            separator={shouldShowSeparator({
              bullets,
              features,
              inPublicStore,
              text,
              contentHeader,
              offers,
              specifications,
              services,
            })}
            turbonappiPrice={turbonappiPrice}
            priceUnit={priceUnit}
            turbonappiPriceAdditionalInfo={turbonappiPriceAdditionalInfo}
            turbonappiOffer={turbonappiOffer}
            showSticker={showSticker}
            serviceFee={serviceFee}
            selectedOffer={selectedOffer}
            footerDisclaimer={footerDisclaimer}
          />
        }
        heading={
          <SubscriptionCardHeading
            description={description}
            name={typeof name === 'string' && isFiveGPlusVoiceOffer(selectedOffer) ? name.concat('+') : name}
            mainHeader={mainHeader}
            voucherCode={voucherCode}
            subDescription={subDescription}
          />
        }
        type="outlined"
      />
      {selected && (
        <div className="selection-footer-ribbon">
          <span>
            <CL.Icon icon="check" type="filled" size="s" color="white" />
            <span className="spacer" />
            {t.QRYW('Selected')}
          </span>
        </div>
      )}
    </div>
  );
};
