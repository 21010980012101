import { CURRENCY_CODE_EUR, EcommerceEventTypeV4 } from '../../common/analytics.js';
import { OnlineModelCategory } from '../../generated/api/models.js';
import { TypeKeys } from './actionsUtils.js';
import { centsToEuros, getPriceForType, selectPricesByDeviceType } from '../../common/utils/priceUtils.js';
import type { Action } from 'redux';
import type {
  EcommerceAddPaymentInfoEventV4,
  EcommerceAddShippingInfoEventV4,
  EcommerceAddToCartEventV4,
  EcommerceBeginCheckoutEventV4,
  EcommerceItem,
  EcommercePurchaseEventV4,
  EcommerceRemoveFromCartEventV4,
  EcommerceSelectItemEventV4,
  EcommerceViewItemEventV4,
  EcommerceViewItemListEventV4,
} from '../../common/analytics.js';
import type { Offer, OnlineModel, OnlineModelHeader } from '../../generated/api/models.js';
import type { PaymentTypeEnum } from '../common/googleEcommerceEvent.js';

interface GoogleEcommerceAddToCartAction extends Action {
  event: EcommerceAddToCartEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_ADD_TO_CART;
}

export const googleEcommerceAddToCart = (productData: EcommerceItem[]): GoogleEcommerceAddToCartAction => ({
  event: {
    event: EcommerceEventTypeV4.ADD_TO_CART,
    ecommerce: {
      currency: CURRENCY_CODE_EUR,
      items: productData,
    },
  },
  type: TypeKeys.GOOGLE_ECOMMERCE_ADD_TO_CART,
});

interface GoogleEcommerceCheckoutAction extends Action {
  event: EcommerceBeginCheckoutEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_CHECKOUT;
}

export const googleEcommerceBeginCheckout = (productData: EcommerceItem[]): GoogleEcommerceCheckoutAction => ({
  event: {
    event: EcommerceEventTypeV4.BEGIN_CHECKOUT,
    ecommerce: {
      items: productData,
    },
  },
  type: TypeKeys.GOOGLE_ECOMMERCE_CHECKOUT,
});

interface GoogleEcommerceAddPaymentInfoAction extends Action {
  event: EcommerceAddPaymentInfoEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_ADD_PAYMENT_INFO;
}

export const googleEcommerceAddPaymentInfo = (productData: EcommerceItem[]): GoogleEcommerceAddPaymentInfoAction => ({
  event: {
    event: EcommerceEventTypeV4.ADD_PAYMENT_INFO,
    ecommerce: {
      items: productData,
    },
  },
  type: TypeKeys.GOOGLE_ECOMMERCE_ADD_PAYMENT_INFO,
});

interface GoogleEcommerceAddShippingInfoAction extends Action {
  event: EcommerceAddShippingInfoEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_ADD_SHIPPING_INFO;
}

export const googleEcommerceAddShippingInfo = (productData: EcommerceItem[]): GoogleEcommerceAddShippingInfoAction => ({
  event: {
    event: EcommerceEventTypeV4.ADD_SHIPPING_INFO,
    ecommerce: {
      items: productData,
    },
  },
  type: TypeKeys.GOOGLE_ECOMMERCE_ADD_SHIPPING_INFO,
});

interface GoogleEcommerceEventAction extends Action {
  event: EcommerceSelectItemEventV4 | EcommerceViewItemListEventV4 | EcommerceViewItemEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_EVENT;
}

interface GoogleEcommerceImpressionsPushedAction extends Action {
  event: EcommerceViewItemListEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_IMPRESSIONS_PUSHED;
}

const resolveECommerceEventListName = (type: string): string => {
  switch (type) {
    case 'CategoryList':
      return 'Category list (tuotelistaus)';
    case 'PromotionBlock':
      return 'Promotion block (tuotenosto)';
    default:
      throw new Error(`Unidentified ecommerce event key: ${type}`);
  }
};

export const eCommerceClickEvent = (
  type: string,
  position: number[],
  onlineModelHeaders: OnlineModelHeader[]
): GoogleEcommerceEventAction => {
  return {
    event: {
      ecommerce: {
        items: onlineModelHeaders.map((omh, index): EcommerceItem => {
          const oneTimeCharge = selectPricesByDeviceType(omh).oneTimeCharge;
          return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_brand: omh.manufacturer,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_category: omh.category,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_id: omh.onlineModelCode,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_list_name: resolveECommerceEventListName(type),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_name: omh.onlineModelName,
            index: position[index],
            price: oneTimeCharge ? centsToEuros(oneTimeCharge) : 0,
          };
        }),
      },
      event: EcommerceEventTypeV4.SELECT_ITEM,
    },
    type: TypeKeys.GOOGLE_ECOMMERCE_EVENT,
  };
};

export const eCommerceImpressionEvent = (
  type: string,
  position: number[],
  onlineModelHeaders: OnlineModelHeader[]
): GoogleEcommerceEventAction => {
  const listName = resolveECommerceEventListName(type);
  return {
    event: {
      ecommerce: {
        items: onlineModelHeaders.map((omh, index): EcommerceItem => {
          const oneTimeCharge = selectPricesByDeviceType(omh).oneTimeCharge;
          return {
            index: position[index],
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_brand: omh.manufacturer,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_category: omh.category,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_id: omh.onlineModelCode,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_list_name: listName,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_name: omh.onlineModelName,
            price: oneTimeCharge ? centsToEuros(oneTimeCharge) : 0,
          };
        }),
      },
      event: EcommerceEventTypeV4.VIEW_ITEM_LIST,
    },
    type: TypeKeys.GOOGLE_ECOMMERCE_EVENT,
  };
};

export const eCommerceDetailEvent = (
  onlineModels: OnlineModel[],
  offers: Offer[][],
  id?: string,
  name?: string
): GoogleEcommerceEventAction => {
  return {
    event: {
      ecommerce: {
        items: onlineModels.map((om, index): EcommerceItem => {
          return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_brand: om.category !== OnlineModelCategory.SALES_PRODUCT ? om.manufacturer : '',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_category: om.category || '',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_id: id || om.onlineModelCode,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            item_name: name || om.onlineModelName,
            price: centsToEuros(getPriceForType(offers[index], om.category)),
          };
        }),
      },
      event: EcommerceEventTypeV4.VIEW_ITEM,
    },
    type: TypeKeys.GOOGLE_ECOMMERCE_EVENT,
  };
};

export const googleEcommerceImpressionsPushed = (
  productData: EcommerceItem[]
): GoogleEcommerceImpressionsPushedAction => ({
  event: {
    ecommerce: {
      items: productData,
    },
    event: EcommerceEventTypeV4.VIEW_ITEM_LIST,
  },
  type: TypeKeys.GOOGLE_ECOMMERCE_IMPRESSIONS_PUSHED,
});

interface GoogleEcommerceProductClickAction extends Action {
  event: EcommerceSelectItemEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_PRODUCT_CLICK;
}

export const googleEcommerceProductClick = (items: EcommerceItem[]): GoogleEcommerceProductClickAction => {
  return {
    event: {
      ecommerce: {
        items,
      },
      event: EcommerceEventTypeV4.SELECT_ITEM,
    },
    type: TypeKeys.GOOGLE_ECOMMERCE_PRODUCT_CLICK,
  };
};

interface GoogleEcommerceProductDetailAction extends Action {
  event: EcommerceViewItemEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_PRODUCT_DETAIL;
}

export const googleEcommerceProductDetail = (productData: EcommerceItem[]): GoogleEcommerceProductDetailAction => {
  return {
    event: {
      ecommerce: {
        items: productData,
      },
      event: EcommerceEventTypeV4.VIEW_ITEM,
    },
    type: TypeKeys.GOOGLE_ECOMMERCE_PRODUCT_DETAIL,
  };
};

interface GoogleEcommercePurchaseAction extends Action {
  event: EcommercePurchaseEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_PURCHASE;
}

export const googleEcommercePurchase = (
  orderId: string,
  revenueInCents: number,
  taxInCents: number,
  shippingInCents: number,
  productData: EcommerceItem[],
  paymentType: PaymentTypeEnum
): GoogleEcommercePurchaseAction => ({
  event: {
    ecommerce: {
      currency: CURRENCY_CODE_EUR,
      items: productData,
      shipping: shippingInCents ? centsToEuros(shippingInCents) : 0,
      tax: parseFloat((taxInCents ? centsToEuros(taxInCents) : 0).toFixed(2)),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      transaction_id: orderId,
      value: revenueInCents ? centsToEuros(revenueInCents) : 0,
    },
    event: EcommerceEventTypeV4.PURCHASE,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    payment_type: paymentType,
  },
  type: TypeKeys.GOOGLE_ECOMMERCE_PURCHASE,
});

interface GoogleEcommerceRemoveFromCartAction extends Action {
  event: EcommerceRemoveFromCartEventV4;
  type: TypeKeys.GOOGLE_ECOMMERCE_REMOVE_FROM_CART;
}

export const googleEcommerceRemoveFromCart = (productData: EcommerceItem[]): GoogleEcommerceRemoveFromCartAction => ({
  event: {
    ecommerce: {
      currency: CURRENCY_CODE_EUR,
      items: productData,
    },
    event: EcommerceEventTypeV4.REMOVE_FROM_CART,
  },
  type: TypeKeys.GOOGLE_ECOMMERCE_REMOVE_FROM_CART,
});

export type GoogleEcommerceActionTypes =
  | GoogleEcommerceAddPaymentInfoAction
  | GoogleEcommerceAddShippingInfoAction
  | GoogleEcommerceAddToCartAction
  | GoogleEcommerceCheckoutAction
  | GoogleEcommerceEventAction
  | GoogleEcommerceImpressionsPushedAction
  | GoogleEcommerceProductClickAction
  | GoogleEcommerceProductDetailAction
  | GoogleEcommercePurchaseAction
  | GoogleEcommerceRemoveFromCartAction;
