import { isStrictFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { useSelector } from 'react-redux';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

export interface FeatureFlagWrapperProps {
  children: ReactNode;
}

// #TODO: shoppingCartWithVoucher / feature flag - remove after taken into use
export const FeatureFlagWrapper = ({ children }: FeatureFlagWrapperProps) => {
  const useShoppingCartWithVoucher = useSelector((s: State) =>
    isStrictFeatureEnabled(s.config.featureFlags?.shoppingCartWithVoucher)
  );

  return useShoppingCartWithVoucher ? <>{children}</> : <></>;
};
