import { BillingAccountList } from '../../../../../components/BillingAccountList/BillingAccountList.js';
import { InvoiceSystemError, Invoices, InvoicesSceneTab } from '../../../../../components/Invoices/Invoices.js';
import { useLoaderData } from 'react-router-dom';
import type { BillingAccountListLoaderData } from '../../../../../common/loaders.js';

export const BillingAccountsPath = () => {
  const { billingAccounts } = useLoaderData() as BillingAccountListLoaderData;

  return (
    <InvoiceSystemError>
      <Invoices tab={InvoicesSceneTab.BILLING_ACCOUNTS}>
        <BillingAccountList billingAccounts={billingAccounts} />
      </Invoices>
    </InvoiceSystemError>
  );
};
