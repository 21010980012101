import { Locale, elisaForCompaniesMsg, t } from '../i18n/index.js';
import { isInBrowser } from '../utils/ssrUtils.js';
import { paths } from '../constants/pathVariables.js';
import { useEffect } from 'react';
import { useLocation, useRouteLoaderData } from 'react-router-dom';
import type { PageResponse } from '../../generated/api/models.js';

const querySelector = <T extends Element = Element>(selector: string): T | null => {
  return isInBrowser() ? document.querySelector(selector) : null;
};

const getMetaElementBySelector = (selector: string): HTMLMetaElement | null => querySelector(selector);
export const setElementBySelectorAndContent = (selector: string, content: string) => {
  if (content) {
    const tag = getMetaElementBySelector(selector);
    if (tag) {
      tag.content = content;
    }
  }
};

const getLinkElementBySelector = (selector: string): HTMLLinkElement | null => querySelector(selector);
const setElementBySelectorAndHref = (selector: string, href: string) => {
  if (href) {
    const tag = getLinkElementBySelector(selector);
    if (tag) {
      tag.href = href;
    }
  }
};

const changePageTitle = (title: string) => {
  if (isInBrowser() && title) {
    document.title = title;
  }
};

/**
 * Create canonical url
 * Root pathname will always produce slash
 */
const createCanonicalUrl = (pathname: string) => `${location.origin}${pathname}`;
export const ROBOTS_META_SELECTOR = '[name="robots"]';
export const INDEX_FOLLOW = 'index, follow';
export const NOINDEX_FOLLOW = 'noindex, follow';
export const NOINDEX_NOFOLLOW = 'noindex, nofollow';

// This should match robots.txt (except for 404 which is handled in another useEffect).
// robots.txt disallow paths are effectively matched with startsWith, so that's what we do as well.
const NOINDEX_PATHS: string[] = [
  paths.DEVICE_CHECKOUT,
  `${paths.SELF_SERVICE_HOME}/`, // We only want to block NOE sub pages, not the frontpage
  paths.AUTHENTICATION_EXT,
  paths.PUNCHOUT_HOME,
  paths.EMPLOYEE_HOME,
  paths.SHOPPING_CART,
  paths.NEW_SHOPPING_CART,
];

const getRobotsMetaValue = (path?: string): string =>
  path && NOINDEX_PATHS.some(nip => decodeURI(path).startsWith(nip)) ? NOINDEX_NOFOLLOW : INDEX_FOLLOW;

const setAlternateHrefMeta = (hrefLang: string, url: string) => {
  if (hrefLang && url) {
    const head = document.querySelector('head');
    const existingHref = getLinkElementBySelector('[rel="alternate"][hrefLang="' + hrefLang + '"]');
    if (existingHref) {
      existingHref.href = url;
    } else {
      const tag = document.createElement('link');
      tag.setAttribute('rel', 'alternate');
      tag.setAttribute('hrefLang', hrefLang);
      tag.setAttribute('href', url);
      head?.appendChild(tag);
    }
  }
};

const removeAlternateHrefMeta = (hrefLang: string) => {
  const existingHref = getLinkElementBySelector('[rel="alternate"][hrefLang="' + hrefLang + '"]');
  existingHref?.remove();
};

/**
 This is to avoid polluting search engines with the urls that contain filters,
 but still allowing google to index links (e.g. search results) from those non-indexed pages.
 Basically, as an example:
 1. /puhelimet -> will be indexed, all the links on the page will be indexed.
 2. /puhelimet?search=apple -> won't be indexed, but the links on the page will be.
 */
const checkQueryParameters = (search: string) => (search ? NOINDEX_FOLLOW : '');

/**
 * The data in CMS/Contentful sometimes provides the elisaForCompaniesMsg in the title and sometimes not
 */
const getFullTitle = (title: string | undefined): string => {
  const msg = t.V0XR(elisaForCompaniesMsg);
  return title ? (title.includes(msg) ? title : `${title} | ${msg}`) : msg;
};

/**
 * Hook to collect all public page <meta> tag changes in one place
 * This implementation relies upon prerender-proxy capabilities
 */
export const useMeta = () => {
  const page = useRouteLoaderData('public-path-root') as PageResponse | undefined;
  const meta = page?.meta;
  const { pathname, search } = useLocation();

  useEffect(() => {
    setElementBySelectorAndHref('[rel="canonical"]', createCanonicalUrl(pathname));
    setElementBySelectorAndContent('[property="og:url"]', createCanonicalUrl(pathname));
    if (meta) {
      const { description, ingress, language, robots, title, image, fi, sv, en } = meta;
      const fullTitle = getFullTitle(title);
      const lang = (language.toUpperCase() || 'FI') as 'FI' | 'EN' | 'SV';
      changePageTitle(fullTitle);
      setElementBySelectorAndContent('[name="description"]', description);
      setElementBySelectorAndContent('[property="og:title"]', fullTitle);
      setElementBySelectorAndContent('[property="og:description"]', ingress);
      setElementBySelectorAndContent('[property="og:locale"]', Locale[lang]);
      setElementBySelectorAndContent(
        ROBOTS_META_SELECTOR,
        checkQueryParameters(search) || robots || getRobotsMetaValue(pathname)
      );
      // New sites meta
      setElementBySelectorAndContent(
        '[property="og:image"]',
        image || 'https://static.elisa.com/v2/image/2tqybbhjs47b/2RqtGrcDNUigzWNlgROqSJ/Elisa_logo_blue_RGB.png'
      );
      fi ? setAlternateHrefMeta('fi', fi) : removeAlternateHrefMeta('fi');
      en ? setAlternateHrefMeta('en', en) : removeAlternateHrefMeta('en');
      sv ? setAlternateHrefMeta('sv', sv) : removeAlternateHrefMeta('sv');
      // Add also current lang if not given, SEO recommendation
      if (fi || en || sv) {
        !fi && lang === 'FI' && setAlternateHrefMeta('fi', createCanonicalUrl(pathname));
        !en && lang === 'EN' && setAlternateHrefMeta('en', createCanonicalUrl(pathname));
        !sv && lang === 'SV' && setAlternateHrefMeta('sv', createCanonicalUrl(pathname));
      }
    }
  }, [meta, pathname, search]);
};

export const useAuthContextMeta = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    setElementBySelectorAndHref('[rel="canonical"]', createCanonicalUrl(pathname));
    setElementBySelectorAndContent('[property="og:url"]', createCanonicalUrl(pathname));
    setElementBySelectorAndContent(ROBOTS_META_SELECTOR, getRobotsMetaValue(pathname));
  }, [pathname]);
};
