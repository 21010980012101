import * as CL from '@design-system/component-library';
import { DialogType } from '../../common/enums.js';
import { SubscriptionStatusType } from '../../generated/api/models.js';
import {
  activateSubMsg,
  changeOfDeviceMsg,
  damageOrSupportRequestMsg,
  getLocale,
  redemptionMsg,
  ringAdministratorsMsg,
  supportRequestMsg,
  t,
  terminateSubscriptionMsg,
  terminationMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  doActionIfNotPendingSubscription,
  isCommitmentPeriodActive,
  isHyllyLiittyma,
  openSubscriptionDialog,
} from '../../common/utils/subscriptionUtils.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { showSuspendSubscriptionOptions } from '../../selfservice/actions/subscriptionActionsActions.js';
import { startBroadbandFixer } from '../../selfservice/actions/broadbandFixerActions.js';
import { startMobileFixer } from '../../selfservice/actions/mobileFixerActions.js';
import { useDispatch, useSelector } from 'react-redux';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { Subscription, SubscriptionType } from '../../generated/api/models.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

export enum SubscriptionDetailsButtonType {
  UPDATE_SUBSCRIPTION,
  SUPPORT_REQUEST,
  SUBMIT_SUPPORT_CASE,
  TEMPORARILY_CLOSE,
  TERMINATE_VIA_DIALOG,
  TERMINATE,
  RESUME_SUBSCRIPTION,
  EPP_TERMINATE,
  EPP_REDEEM,
  EPP_DAMAGE_OR_SUPPORT_REQUEST,
  RING_ADMIN_USERS,
  RING_SERVICE,
  EPP_DEVICE_CHANGE,
  CHANGE_OFFER,
  CHANGE_OFFER_NOT_AVAILABLE,
  PENDING_ACTIONS,
  BROADBAND_FIXER,
  MOBILE_FIXER,
  CHANGE_NETTI_LITE_SUBSCRIPTION,
  YTT_SUPPORT,
  UPDATE_LIIKKUVA_WIFI_SUBSCRIPTION,
}

interface ButtonData {
  type: SubscriptionDetailsButtonType;
  onClickCallback: () => void;
  icon: string;
  label: string;
  disabled?: boolean;
}

interface ButtonGroupProps {
  buttonTypes: SubscriptionDetailsButtonType[];
  portal?: 'NOE' | 'EOE';
  subscription: Subscription;
  subscriptionStatus?: SubscriptionStatus;
  onShowDialog?: (params: DialogParams) => void;
  onShowSubscriptionTerminationForm?: () => void;
  onResumeSubscription?: (subscriptionId: string) => void;
  onClickCompanyInfoUser?: () => void;
  onClickEppMaintenanceRequest?: (subscriptionId: string) => void;
  onClickEppRedeem?: () => void;
  onClickSubscriptionChangeOfferSelection?: (subscriptionDisplayId: string, subscriptionType: SubscriptionType) => void;
}

export const ButtonGroup = ({
  buttonTypes,
  portal = 'NOE',
  subscription,
  subscriptionStatus,
  onShowDialog,
  onShowSubscriptionTerminationForm,
  onResumeSubscription,
  onClickCompanyInfoUser,
  onClickEppMaintenanceRequest,
  onClickEppRedeem,
  onClickSubscriptionChangeOfferSelection,
}: ButtonGroupProps) => {
  const subscriptionId = subscription.subscriptionId;
  const subscriptionDisplayId = subscription.subscriptionDisplayId;
  const ringBaseUrl = useSelector((state: State) => state.config.ringBaseUrl, deepEqual);
  const dispatch = useDispatch();
  const EPP_SUPPORT_MAINTENANCE_URL = 'https://safeavenue.f-secure.com/iframe/-sso/elisaya/';

  const buttonData: ButtonData[] = [
    {
      type: SubscriptionDetailsButtonType.EPP_DAMAGE_OR_SUPPORT_REQUEST,
      onClickCallback: () => {
        onClickEppMaintenanceRequest!(subscription.subscriptionDisplayId);
      },
      label: t.N2CC(damageOrSupportRequestMsg),
      icon: 'maintenance',
    },
    {
      type: SubscriptionDetailsButtonType.EPP_REDEEM,
      onClickCallback:
        subscriptionStatus?.pendingActions && onShowDialog
          ? () => onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING })
          : onClickEppRedeem!,
      label: t.B9EG(redemptionMsg),
      icon: 'invoice',
    },
    {
      type: SubscriptionDetailsButtonType.EPP_TERMINATE,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            subscription,
            type: DialogType.EPP_TERMINATE,
          });
        }
      },
      label: t.D1IS(terminationMsg),
      icon: 'block',
    },
    {
      type: SubscriptionDetailsButtonType.RESUME_SUBSCRIPTION,
      onClickCallback: () => {
        if (onResumeSubscription && onShowDialog && subscriptionStatus) {
          doActionIfNotPendingSubscription(subscriptionStatus, onShowDialog, () =>
            onResumeSubscription(subscriptionId)
          );
        }
      },
      label: t.QV5K(activateSubMsg),
      icon: 'lock',
    },
    {
      type: SubscriptionDetailsButtonType.SUPPORT_REQUEST,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            type: DialogType.SUBSCRIPTION_SUPPORT_REQUEST,
          });
        }
      },
      label: t.ROBH(supportRequestMsg),
      icon: 'maintenance',
    },
    {
      type: SubscriptionDetailsButtonType.SUBMIT_SUPPORT_CASE,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            subscriptionId,
            type: DialogType.SUBSCRIPTION_SUBMIT_SUPPORT_CASE,
          });
        }
      },
      label: t.ROBH(supportRequestMsg),
      icon: 'maintenance',
    },
    {
      type: SubscriptionDetailsButtonType.TEMPORARILY_CLOSE,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          doActionIfNotPendingSubscription(subscriptionStatus, onShowDialog, () =>
            dispatch(showSuspendSubscriptionOptions())
          );
        }
      },
      label: t.IATD('Close temporarily'),
      disabled: isHyllyLiittyma(subscription),
      icon: 'lock',
    },
    {
      type: SubscriptionDetailsButtonType.TERMINATE,
      onClickCallback: () => {
        if (onShowDialog) {
          if (isCommitmentPeriodActive(subscription.details?.commitmentEndDate)) {
            onShowDialog({
              type: DialogType.SUBSCRIPTION_TERMINATION_FORBIDDEN,
              commitmentEndDate: formatTimestampToUTCDDMMYYYY(subscription.details?.commitmentEndDate)!,
            });
          } else if (onShowSubscriptionTerminationForm && subscriptionStatus) {
            doActionIfNotPendingSubscription(subscriptionStatus, onShowDialog, onShowSubscriptionTerminationForm);
          }
        }
      },
      label: t.M1TW(terminateSubscriptionMsg),
      icon: 'power',
    },
    {
      type: SubscriptionDetailsButtonType.TERMINATE_VIA_DIALOG,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            subscriptionId,
            type: DialogType.SUBSCRIPTION_TERMINATE_REQUEST,
          });
        }
      },
      label: t.M1TW(terminateSubscriptionMsg),
      icon: 'power',
    },
    {
      type: SubscriptionDetailsButtonType.UPDATE_SUBSCRIPTION,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            subscriptionId: subscription.subscriptionId,
            type: DialogType.SUBSCRIPTION_CHANGE_SUBSCRIPTION,
          });
        }
      },
      label: t.VAS5('Update subscription type'),
      icon: 'star',
    },
    {
      type: SubscriptionDetailsButtonType.CHANGE_OFFER,
      onClickCallback:
        subscriptionStatus?.pendingActions && onShowDialog
          ? () => onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING })
          : () => onClickSubscriptionChangeOfferSelection!(subscriptionDisplayId, subscription.subscriptionType),
      label: t.RWLD('Update subscription'),
      icon: 'star',
    },
    {
      type: SubscriptionDetailsButtonType.CHANGE_NETTI_LITE_SUBSCRIPTION,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            type: DialogType.CHANGE_NETTI_LITE_SUBSCRIPTION,
          });
        }
      },
      label: t.RWLD('Update subscription'),
      icon: 'star',
    },
    {
      type: SubscriptionDetailsButtonType.CHANGE_OFFER_NOT_AVAILABLE,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            subscriptionId: subscription.subscriptionId,
            type: DialogType.CHANGE_OFFER_NOT_AVAILABLE,
          });
        }
      },
      label: t.RWLD('Update subscription'),
      icon: 'star',
    },
    {
      type: SubscriptionDetailsButtonType.PENDING_ACTIONS,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            type: DialogType.SUBSCRIPTION_ACTION_PENDING,
          });
        }
      },
      label: t.RWLD('Update subscription'),
      icon: 'star',
    },
    {
      type: SubscriptionDetailsButtonType.RING_SERVICE,
      onClickCallback: () => {
        window.open(`${ringBaseUrl}/autologin`, '_blank');
      },
      label: t.V07Q('Oma Ring'),
      icon: 'settings',
    },
    {
      type: SubscriptionDetailsButtonType.RING_ADMIN_USERS,
      onClickCallback: () => {
        if (onClickCompanyInfoUser) {
          onClickCompanyInfoUser();
        }
      },
      label: t.PFI5(ringAdministratorsMsg),
      icon: 'user',
    },
    {
      type: SubscriptionDetailsButtonType.EPP_DEVICE_CHANGE,
      onClickCallback: () => {
        if (subscriptionStatus && onShowDialog) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            type: DialogType.EPP_DEVICE_CHANGE,
            subscription: subscription,
          });
        }
      },
      label: t.ZHBT(changeOfDeviceMsg),
      icon: 'update',
    },
    {
      type: SubscriptionDetailsButtonType.BROADBAND_FIXER,
      onClickCallback: () => dispatch(startBroadbandFixer(subscriptionId, getLocale(), portal)),
      label: t.OTF5('Connection problems?'),
      disabled: subscription.subscriptionStatus !== SubscriptionStatusType.ACTIVE,
      icon: 'wifi',
    },
    {
      type: SubscriptionDetailsButtonType.MOBILE_FIXER,
      onClickCallback: () => dispatch(startMobileFixer(subscriptionId, getLocale(), portal)),
      label: t.OTF5('Connection problems?'),
      disabled: subscription.subscriptionStatus !== SubscriptionStatusType.ACTIVE,
      icon: 'wifi',
    },
    {
      type: SubscriptionDetailsButtonType.YTT_SUPPORT,
      onClickCallback: () => {
        window.open(EPP_SUPPORT_MAINTENANCE_URL, '_blank');
      },
      label: t.QJL0('Move to the installation and management service'),
      icon: 'maintenance',
    },
    {
      type: SubscriptionDetailsButtonType.UPDATE_LIIKKUVA_WIFI_SUBSCRIPTION,
      onClickCallback: () => {
        if (onShowDialog && subscriptionStatus) {
          openSubscriptionDialog(subscriptionStatus, onShowDialog, {
            type: DialogType.UPDATE_LIIKKUVA_WIFI_SUBSCRIPTION,
          });
        }
      },
      label: t.RWLD('Update subscription'),
      icon: 'star',
    },
  ];

  const renderButton = (buttonType: SubscriptionDetailsButtonType) => {
    const buttonDetails: ButtonData = buttonData.find(element => element.type === buttonType) || ({} as ButtonData);
    const iconColor = buttonDetails.disabled ? 'neutral-400--light' : 'brand-blue';
    return (
      <CL.Button
        onClick={buttonDetails.onClickCallback}
        disabled={buttonDetails.disabled}
        size="l"
        color="link"
        key={buttonDetails.type}
      >
        <CL.Icon icon={buttonDetails.icon} color={iconColor} />
        <>{buttonDetails.label}</>
      </CL.Button>
    );
  };

  return (
    <div className="of-subscription-details__actions">{buttonTypes.map(buttonType => renderButton(buttonType))}</div>
  );
};
