import * as CL from '@design-system/component-library';
import { AddOrSelectBillingAccounts } from '../AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { CommercialProductType, SimType } from '../../generated/api/models.js';
import { DEFAULT_COUNTRY_CODE, isPOBoxAddress } from '../../common/utils/validationUtils.js';
import { FormWrapper } from './FormWrapper.js';
import { Loading } from '../Loading/index.js';
import { Name, PhoneNumber, PostalCode, TextInput } from '../../common/react-hook-form/fields/index.js';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { POBoxWarningModal } from '../Modal/POBoxWarningModal.js';
import { PhaseIndicator } from '../PhaseIndicator/index.js';
import { PhoneNumberType, SelectedPurposeOfUseOrContact, SimCardSelection } from '../../common/enums.js';
import { ScrollToTopWhenSearchParamsChange, scrollTo } from '../../common/utils/browserUtils.js';
import { WizardActions } from '../WizardActions/index.js';
import {
  addEmptyFieldValidationError,
  addErrorsFromUpsertAddress,
  convertStringMapToCommonErrors,
  getElementsWithErrors,
} from '../../common/utils/errorUtils.js';
import {
  companyMsg,
  confirmOrderMsg,
  deliveryDetailsMsg,
  deliveryMethodMsg,
  editMsg,
  nameMsg,
  phoneNumberMsg,
  postalCodeMsg,
  productInformationsMsg,
  shippingAddressMsg,
  streetAddressMsg,
  t,
} from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { generateNumbers, validateNumbers } from '../../common/fetch.js';
import {
  getEmptyBillingAccount,
  getErrorsFromUpsertBillingAccount,
  getReceiverType,
  prepareBillingAccountSave,
} from '../../common/utils/billingAccountUtils.js';
import { getTopMostElement } from '../../common/utils/domUtils.js';
import { isDefined } from '../../common/utils/objectUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useEffect, useRef, useState } from 'react';
import type {
  AddOn,
  Address,
  BillingAccount,
  CampaignAssociation,
  Contact,
  DeliveryAddress,
  GenericErrorDuplicateContact,
} from '../../generated/api/models.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { DeliveryAddressFormValues } from './FormWrapper.js';
import type { OrderSubscriptionStateParams } from '../OrderSubscription/OrderSubscriptionLayout.js';
import type { SelectedBAData } from '../AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';

import './OrderDeliveryOptions.scss';

export interface OrderDeliveryOptionsProps {
  contacts?: Contact[];
  companyInfo?: CompanyInfoState;
  onlineOrdersErrors?: CommonError[];
  saving: boolean;
  user?: Contact;
  useDuplicateContact?: GenericErrorDuplicateContact;
  orderStateParams: OrderSubscriptionStateParams;
  onSubmitOrder: (
    orderItems: ConfiguredOffer[],
    deliveryAddress: DeliveryAddress,
    isCompanyNameRequired?: boolean,
    deliveryAddressValidationErrors?: CommonError[],
    personAccountValidationErrors?: CommonError[],
    billingAccountId?: string,
    newBillingAccount?: BillingAccount,
    newBillingAccountValidationErrors?: CommonError[],
    addOns?: AddOn[],
    setShowPOBoxWarningModal?: (isShown: boolean) => void
  ) => void;
}

interface UnavailableNumbersModalProps {
  onSubmit: () => void;
  onModalClose: () => void;
  notAvailableNumbers: Array<string>;
}

export const getErrorsFromUpsertDeliveryAddress = (
  upsertedDeliveryAddress: Readonly<DeliveryAddress>,
  isCompanyNameRequired?: boolean,
  validationErrors?: Readonly<CommonError>[]
): CommonError[] | undefined => {
  const errors: CommonError[] = [];

  addErrorsFromUpsertAddress(errors, upsertedDeliveryAddress.address, 'address');
  const { companyName, phoneNumber, recipient } = upsertedDeliveryAddress;

  if (isCompanyNameRequired !== false && !companyName?.length) {
    addEmptyFieldValidationError(errors, 'companyName');
  }
  if (!phoneNumber?.length) {
    addEmptyFieldValidationError(errors, 'phoneNumber');
  }
  if (!recipient?.length) {
    addEmptyFieldValidationError(errors, 'recipient');
  }

  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
};

const UnavailableNumbersModal = ({ onSubmit, onModalClose, notAvailableNumbers }: UnavailableNumbersModalProps) => (
  <CL.Modal autoOpen={true} onModalClose={onModalClose}>
    <h3 className="ds-margin-bottom--0">{t.XP04('Phone number is no longer available')}</h3>
    <p>
      {t.IEC8('Unfortunately, the following phone number or numbers are no longer available')}:<br />
      {notAvailableNumbers.map(number => formatPhoneNumber(number, true)).join(', ')}
    </p>
    <p>
      {t.N894(
        'You can still confirm the order, in which case the next available phone number will be assigned to your subscription. If you wish to choose the phone number yourself, please start the order process again.'
      )}
    </p>
    <ButtonGroupForSubmitAndBack
      className="ds-padding-top--4 ds-text-align--right"
      submitButtonText={t.RZU4(confirmOrderMsg)}
      onSubmit={onSubmit}
      cancelButtonText={t.VQ6O('Start order process again')}
      onCancel={() => {
        window.location.href = paths.PS_MOBILE_SUBSCRIPTION_NEW;
      }}
    />
  </CL.Modal>
);

export const OrderDeliveryOptions = ({
  contacts,
  companyInfo,
  onlineOrdersErrors,
  saving,
  user,
  useDuplicateContact,
  orderStateParams,
  onSubmitOrder,
}: OrderDeliveryOptionsProps) => {
  const [editDeliveryAddress, setEditDeliveryAddress] = useState(false);
  const [shouldScrollToError, setShouldScrollToError] = useState(false);
  const [billingAccountSaveValues, setBillingAccountSaveValues] = useState<SelectedBAData>();
  const [billingAccountErrors, setBillingAccountErrors] = useState<CommonError[]>([]);
  const [displayUnavailableNumbersModal, setDisplayUnavailableNumbersModal] = useState(false);
  const [notAvailableNumbers, setNotAvailableNumbers] = useState<Array<string>>([]);
  const [deliveryAddressFormValuesState, setDeliveryAddressFormValuesState] = useState<DeliveryAddressFormValues>();
  const [submitting, setSubmitting] = useState(false);
  const [showPOBoxWarningModal, setShowPOBoxWarningModal] = useState(false);

  const domRefs = useRef<{ [elementKey: string]: HTMLElement }>({});
  const deliveryAddress: DeliveryAddress = {
    address: companyInfo?.address
      ? {
          ...companyInfo.address,
          line2: undefined,
          countryCode: companyInfo.address.countryCode || DEFAULT_COUNTRY_CODE,
        }
      : ({} as Address),
    companyName: companyInfo?.companyName || '',
    phoneNumber: user?.person?.phoneNumber || '',
    recipient: user?.person ? `${user.person.firstName} ${user.person.lastName}` : '',
  };
  const selectedOffer = orderStateParams.selectedOffer!;
  const commercialProducts: ConfiguredCommercialProduct[] = selectedOffer.selectedCommercialProducts;
  const selectedCampaignAssociation: CampaignAssociation | undefined = selectedOffer.selectedCampaignAssociation;
  const ringProducts: ReadonlyArray<ConfiguredCommercialProduct> = commercialProducts
    .filter(product => product.configuredRingOffer)
    .map(product => product.configuredRingOffer!.selectedCommercialProducts[0]);
  const selectedCommercialProducts: Readonly<ConfiguredCommercialProduct>[] = [...commercialProducts, ...ringProducts];
  const hasDeliverables = selectedOffer.selectedCommercialProducts.some(
    selectedCp =>
      selectedCp.simCardConfiguration?.simSelection === SimCardSelection.ORDER_NEW &&
      selectedCp.simCardConfiguration?.simType !== SimType.ESIM
  );

  useEffect(() => {
    const allErrors: CommonError[] = [
      ...(billingAccountErrors ? billingAccountErrors : []),
      ...(onlineOrdersErrors ? onlineOrdersErrors : []),
    ];
    if (shouldScrollToError && window && allErrors.length > 0) {
      scrollTo(getTopMostElement(getElementsWithErrors(domRefs.current, allErrors)));
      setShouldScrollToError(false);
    }
  }, [billingAccountErrors, onlineOrdersErrors, shouldScrollToError]);

  const prepareNewBillingAccount = (prepareNewBillingAccountSaveValues?: BillingAccountOrErrorSupplier) => {
    if (!prepareNewBillingAccountSaveValues) {
      return {};
    }

    const { obj, validationErrors } = prepareNewBillingAccountSaveValues();
    const receiverType = getReceiverType(obj);
    const updatedBillingAccount = prepareBillingAccountSave(
      getEmptyBillingAccount(companyInfo?.companyName ?? ''),
      obj,
      receiverType
    );

    return {
      newBillingAccount: updatedBillingAccount,
      newBillingAccountValidationErrors: convertStringMapToCommonErrors(validationErrors),
    };
  };

  const updateOfferWithDuplicateContactIfAny = (offer: ConfiguredOffer) => {
    if (!useDuplicateContact) {
      return offer;
    }

    const duplicateContactId = useDuplicateContact.contactId;
    const duplicateContact = contacts?.find(c => c.contactId === duplicateContactId);
    const purposeOfUseOrContact = {
      selected: SelectedPurposeOfUseOrContact.CONTACT,
      contactId: duplicateContact?.contactId ?? duplicateContactId,
      costCenter: duplicateContact?.person?.costCenter ?? '',
      employeeNumber: duplicateContact?.person?.employeeNumber ?? '',
    };

    return {
      ...offer,
      selectedCommercialProducts: offer.selectedCommercialProducts.map((product, index) =>
        index === 0 ? { ...product, purposeOfUseOrContact } : product
      ),
    };
  };

  const updateOfferWithAvailableNumbers = async () => {
    const newNumbersCount = notAvailableNumbers.length;
    const generatedNumbers = await generateNumbers({ count: newNumbersCount });
    const updatedCommercialProducts = selectedOffer.selectedCommercialProducts.map(product => {
      const currentNumber = product.selectedPhoneNumber?.newPhoneNumber;
      const newPhoneNumber =
        currentNumber && notAvailableNumbers.includes(currentNumber) ? generatedNumbers.numbers.shift() : currentNumber;
      return {
        ...product,
        selectedPhoneNumber: product.selectedPhoneNumber
          ? {
              ...product.selectedPhoneNumber,
              newPhoneNumber: newPhoneNumber,
            }
          : product.selectedPhoneNumber,
      };
    });
    return {
      ...selectedOffer,
      selectedCommercialProducts: updatedCommercialProducts,
    };
  };

  const validateNewMobileNumbersIfAny = async () => {
    const newMobileNumbers = selectedOffer.selectedCommercialProducts
      .filter(
        product =>
          product.commercialProduct.productType === CommercialProductType.MOBILE &&
          product.selectedPhoneNumber?.type === PhoneNumberType.NEW
      )
      .map(product => product.selectedPhoneNumber?.newPhoneNumber)
      .filter(isDefined);

    if (newMobileNumbers.length > 0) {
      const validateNumbersResponse = await validateNumbers({ numbers: newMobileNumbers });
      setNotAvailableNumbers(validateNumbersResponse.notAvailableNumbers || []);
      setDisplayUnavailableNumbersModal(!validateNumbersResponse.allNumbersAvailable);
      return validateNumbersResponse.allNumbersAvailable;
    }
    return true;
  };

  const onSubmit = async (
    deliveryAddressFormValues: DeliveryAddressFormValues,
    shouldValidateNumbers = true,
    shouldReplaceUnavailableNumbers = false
  ) => {
    if (isPOBoxAddress(deliveryAddressFormValues?.deliveryAddress?.address?.postalCode) && hasDeliverables) {
      setShowPOBoxWarningModal(true);
      return;
    }

    setSubmitting(true);

    const { selectedBaId, prepareNewBillingAccountSaveValues } = billingAccountSaveValues!;
    const { newBillingAccount, newBillingAccountValidationErrors } = prepareNewBillingAccount(
      prepareNewBillingAccountSaveValues
    );

    if (newBillingAccount) {
      const errors = getErrorsFromUpsertBillingAccount(newBillingAccount, newBillingAccountValidationErrors);
      if (errors) {
        setSubmitting(false);
        setShouldScrollToError(true);
        setBillingAccountErrors(errors);
        return;
      }
    }

    const allNumbersAvailable = shouldValidateNumbers ? await validateNewMobileNumbersIfAny() : true;
    if (!allNumbersAvailable) {
      setSubmitting(false);
      return;
    }

    const offer = shouldReplaceUnavailableNumbers ? await updateOfferWithAvailableNumbers() : selectedOffer;

    onSubmitOrder(
      [updateOfferWithDuplicateContactIfAny(offer)],
      deliveryAddressFormValues.deliveryAddress,
      true,
      undefined,
      undefined,
      selectedBaId,
      newBillingAccount,
      newBillingAccountValidationErrors,
      orderStateParams.addOns
    );

    setSubmitting(false);
  };

  const setRefCallback = (key: string, ref: HTMLElement | null) => {
    if (ref) {
      domRefs.current[key] = ref;
    } else {
      delete domRefs.current[key];
    }
  };

  if (!deliveryAddress.companyName || !deliveryAddress.recipient) {
    return <Loading />;
  }
  return (
    <>
      {showPOBoxWarningModal && <POBoxWarningModal setShowModal={setShowPOBoxWarningModal} />}
      <FormWrapper
        defaultAddress={deliveryAddress}
        onSubmit={async deliveryAddressFormValues => {
          setDeliveryAddressFormValuesState(deliveryAddressFormValues);
          await onSubmit(deliveryAddressFormValues);
        }}
      >
        <div className="of-order-delivery-options">
          <ScrollToTopWhenSearchParamsChange />
          <div className="of-order-delivery-options__phaseindicator-container">
            <PhaseIndicator
              phases={[
                {
                  displayName: t.R4EV(productInformationsMsg),
                },
                {
                  displayName: t.T78Y(deliveryDetailsMsg),
                },
              ]}
              currentPhaseIndex={1}
            />
          </div>
          <div className="of-order-delivery-options__container">
            <CL.Grid className="of-order-delivery-options__grid">
              <h3 className="ds-margin-bottom--1 ds-margin-top--0">{t.IHO6(shippingAddressMsg)}</h3>
              <div className="of-order-delivery-options__section">
                {editDeliveryAddress ? (
                  <>
                    <CL.GridRow>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <TextInput name="deliveryAddress.companyName" maxLength={256} label={t.KJTS(companyMsg)} />
                      </CL.GridCol>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <Name name="deliveryAddress.recipient" maxLength={100} label={t.VGFI(nameMsg)} placeholder="" />
                      </CL.GridCol>
                    </CL.GridRow>
                    <CL.GridRow>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <PhoneNumber name="deliveryAddress.phoneNumber" label={t.W1Q4(phoneNumberMsg)} placeholder="" />
                      </CL.GridCol>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <TextInput
                          name="deliveryAddress.address.line1"
                          maxLength={100}
                          label={t.DD38(streetAddressMsg)}
                        />
                      </CL.GridCol>
                    </CL.GridRow>
                    <CL.GridRow>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <PostalCode
                          name="deliveryAddress.address.postalCode"
                          label={t.RUAW(postalCodeMsg)}
                          placeholder=""
                        />
                      </CL.GridCol>
                      <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                        <TextInput name="deliveryAddress.address.postOffice" maxLength={50} label={t.J0YE('City')} />
                      </CL.GridCol>
                    </CL.GridRow>
                  </>
                ) : (
                  <div>
                    <div>
                      <span>
                        {deliveryAddress.companyName}
                        <br />
                      </span>
                      <span>
                        {deliveryAddress.recipient}
                        <br />
                      </span>
                      <span>
                        {deliveryAddress.phoneNumber}
                        <br />
                      </span>
                      <span>
                        {deliveryAddress.address.line1}
                        <br />
                      </span>
                      <span>{deliveryAddress.address.postalCode + ' ' + deliveryAddress.address.postOffice}</span>
                    </div>
                    <div className="of-order-delivery-options__section-edit-button">
                      <CL.Button
                        className="ds-margin-top--2"
                        color="light"
                        onClick={() => {
                          setEditDeliveryAddress(true);
                        }}
                      >
                        {t.NVPK(editMsg)}
                      </CL.Button>
                    </div>
                  </div>
                )}
              </div>
              <CL.GridRow>
                <CL.GridCol colWidthXL={12}>
                  <h3 className="ds-margin-bottom--1 ds-margin-top--0">{t.STU7(deliveryMethodMsg)}</h3>
                  <div className="of-order-delivery-options__section">
                    {hasDeliverables
                      ? t.FTA1('SIM cards are delivered by post to the address of your choice.')
                      : t.R2DK('Nothing to deliver.')}
                  </div>
                </CL.GridCol>
              </CL.GridRow>
              <CL.GridRow>
                <CL.GridCol colWidthXL={12}>
                  <AddOrSelectBillingAccounts
                    addNewBA={true}
                    getBillingAccountSaveValuesFn={selectedBaData => {
                      setBillingAccountSaveValues(selectedBaData);
                    }}
                    setRefCallBack={setRefCallback}
                    isSaving={saving}
                    createBillingAccountErrors={billingAccountErrors}
                  />
                </CL.GridCol>
              </CL.GridRow>
              <CL.GridRow>
                <CL.GridCol colWidthXL={12}>
                  <OrderSummary
                    commercialProducts={selectedCommercialProducts}
                    campaignAssociation={selectedCampaignAssociation}
                    summaryType={OrderSummaryType.DETAILS_OPEN}
                  />
                  <div className="of-wizard-actions-container">
                    <WizardActions
                      submitting={submitting}
                      forwardButtonText={t.RZU4(confirmOrderMsg)}
                      forwardButtonWideOnPhone={true}
                      onBackClick={() => history.back()}
                      onForwardClick={() => {}}
                    />
                  </div>
                </CL.GridCol>
              </CL.GridRow>
            </CL.Grid>
            {displayUnavailableNumbersModal && (
              <UnavailableNumbersModal
                onSubmit={async () => {
                  setDisplayUnavailableNumbersModal(false);
                  await onSubmit(deliveryAddressFormValuesState!, false, true);
                }}
                onModalClose={() => setDisplayUnavailableNumbersModal(false)}
                notAvailableNumbers={notAvailableNumbers}
              />
            )}
          </div>
        </div>
      </FormWrapper>
    </>
  );
};
