import { CatalogProductSelectionForm } from './CatalogProductSelectionForm.js';
import { CatalogProductSelectionFormWrapper } from './CatalogProductSelectionFormWrapper.js';
import { SupportedModelCategories } from '../../common/utils/catalogUtils.js';
import { getSelectedCatalogProducts } from './CatalogProductSelectionUtils.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { DeviceSelection } from './CatalogProductSelectionFormWrapper.js';

import './CatalogProductsSelection.scss';

export interface CatalogProductsSelectionProps {
  preSelectedProducts?: Record<string, CatalogProduct[]>;
  onSetProductsForCatalog: (products: Record<string, CatalogProduct[]>) => void;
  contractPeriod?: number;
  imagesBaseUrl: string;
  catalogProducts: Record<string, CatalogProduct[]>;
}

export const CatalogProductsSelection = ({
  catalogProducts,
  preSelectedProducts,
  imagesBaseUrl,
  contractPeriod,
  onSetProductsForCatalog,
}: CatalogProductsSelectionProps) => {
  const handleSubmit = (data: Readonly<DeviceSelection>) => {
    const selectedProductCodes = Object.keys(data?.devices || {}).filter(item => data.devices[item].selected);
    const selectedCatalogProducts = getSelectedCatalogProducts(catalogProducts, selectedProductCodes);
    onSetProductsForCatalog(selectedCatalogProducts);
  };

  const preSelectedProductCodes = SupportedModelCategories.flatMap(cat => {
    return preSelectedProducts ? preSelectedProducts[cat]?.map(prod => prod.code) : [];
  }).filter(prod => prod);

  return (
    <CatalogProductSelectionFormWrapper
      preSelected={preSelectedProductCodes}
      onSubmit={data => handleSubmit(data)}
      products={catalogProducts}
    >
      <div className="of-catalog-products-selection">
        <CatalogProductSelectionForm
          catalogProducts={catalogProducts}
          imagesBaseUrl={imagesBaseUrl}
          contractPeriod={contractPeriod}
        />
      </div>
    </CatalogProductSelectionFormWrapper>
  );
};
