export const capitalize = (string: string) => string.replace(/^\w/, c => c.toUpperCase());

export const regExpChars = /[.*+?^${}()|[\]\\]/g;
export const searchQueryMinLength = 2;

export const escapeRegExpChars = (text: string) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions?redirectlocale=en-US&redirectslug=JavaScript%2FGuide%2FRegular_Expressions#escaping
  return text.replace(regExpChars, '\\$&');
};

export const multiwordSearchRegexStr = (words: string[]) => {
  return words
    .map(e => e.replace(regExpChars, '|').trim())
    .flatMap(e => e.split('|'))
    .filter(e => e) // Remove empty strings, they would match everything.
    .sort((a, b) => b.length - a.length) // Sort longest first, so that we match the longest word available.
    .join('|');
};

export const isValidSearchQuery = (searchQuery?: string) =>
  searchQuery !== undefined && searchQuery.trim().length >= searchQueryMinLength;

export const trimAndJoinDistinctValues = (input: string | string[] | undefined) =>
  Array.isArray(input) ? [...new Set(input.map(item => item.trim()))].join(', ') : input?.trim();

export const truncateText = (input: string | string[] | undefined, maxChars: number) => {
  const text = trimAndJoinDistinctValues(input);
  return text && text.length > maxChars ? text.substring(0, maxChars) + '...' : (text ?? '');
};

export const getSearchSnippetText = (input: string | string[] | undefined, searchWords: string[]): string => {
  const searchTerms = multiwordSearchRegexStr(searchWords).split('|');
  const text = trimAndJoinDistinctValues(input) || '';
  let result = '';

  for (let i = 0; i < searchTerms.length; i++) {
    const term = searchTerms[i];
    // The regex will match the search term and surrounding three words of the term on each side. E.g. if searching
    // for 'sopimus', the regex would match the text "hinta. Meillä on sopimushinnat, joissa Yritysliittymä 5G".
    const matchReg = new RegExp('(\\S+\\s){0,3}\\S*(' + term + ')\\S*(\\s\\S+){0,3}', 'gmi');
    const match = matchReg.exec(text);
    if (match) {
      const matchText = match[0].trim();
      // Add '...' at the beginning if the snipped isn't from the beginning of the input text
      const prefix = matchText && match.index > 0 ? '...' : '';
      result = prefix + matchText;
      break;
    }
  }

  // Add '...' at the end if the snippet doesn't reach the end of the input text
  if (result && !result.endsWith(text.slice(-1))) {
    result += '...';
  }

  return result;
};
