import { FormGridFieldset } from '../../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { POSTAL_CODE_MAX_LENGTH } from '../../../common/utils/validationUtils.js';
import { PostOffice } from '../../../common/react-hook-form/fields/PostOffice.js';
import { PostalCode } from '../../../common/react-hook-form/fields/index.js';
import { StreetAddress } from '../../../common/react-hook-form/fields/StreetAddress.js';
import {
  businessIdMsg,
  companyDetailsMsg,
  companyNameMsg,
  postOfficeMsg,
  postalAddressMsg,
  postalCodeMsg,
  t,
} from '../../../common/i18n/index.js';

export interface CompanyDetailsProps {
  companyName: string;
  businessId: string;
}
export const COMPANY_POSTAL_ADDRESS_FIELD_NAME = 'line1';
export const COMPANY_POSTAL_CODE_FIELD_NAME = 'postalCode';
export const COMPANY_POST_OFFICE_FIELD_NAME = 'postOffice';

export const CompanyDetailsFieldset = ({ companyName, businessId }: CompanyDetailsProps) => {
  return (
    <FormGridFieldset legend={t.EHOL(companyDetailsMsg)}>
      <div className="ds-margin-bottom--4">
        <h5 className="ds-margin--0">{t.AJ93(companyNameMsg)}</h5>
        <span>{companyName}</span>
      </div>
      <div className="ds-margin-bottom--4">
        <h5 className="ds-margin--0">{t.MPA5(businessIdMsg)}</h5>
        <span>{businessId}</span>
      </div>
      <StreetAddress label={t.NDWK(postalAddressMsg)} name={COMPANY_POSTAL_ADDRESS_FIELD_NAME} />
      <PostalCode
        allowPoBox={true}
        label={t.RUAW(postalCodeMsg)}
        maxLength={POSTAL_CODE_MAX_LENGTH}
        name={COMPANY_POSTAL_CODE_FIELD_NAME}
        placeholder=""
      />
      <PostOffice label={t.Z7S5(postOfficeMsg)} name={COMPANY_POST_OFFICE_FIELD_NAME} />
    </FormGridFieldset>
  );
};
