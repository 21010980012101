import * as CL from '@design-system/component-library';
import { Input } from '../Input/Input.js';
import { activateMsg, discountVoucherMsg, t } from '../../common/i18n/index.js';

import './Voucher.scss';

export interface VoucherProps {
  activeVoucher?: string;
  alignEnd?: boolean;
  onDisableClick: () => void;
}

interface VoucherTextProps {
  text: string;
  onDisableClick: () => void;
}

const VoucherText = ({ text, onDisableClick }: VoucherTextProps) => (
  <div className="of-voucher-text ds-background-color--green-100 ds-padding--2">
    <span className="ds-margin-right--1">{text}</span>
    <CL.Button size="s" color="link" onClick={onDisableClick}>
      <CL.Icon icon="close" color="blue-800" />
    </CL.Button>
  </div>
);

export const Voucher = ({ activeVoucher, alignEnd, onDisableClick }: VoucherProps) => (
  <div
    className={`of-voucher-alignment-wrapper ds-margin-left--5 ds-margin-right--5 ds-justify-content--flex-${
      alignEnd ? 'end' : 'start'
    }`}
  >
    <div className="of-voucher-wrapper">
      <div className="input-section">
        <Input label={t.GOH2(discountVoucherMsg)} type="text" />
        <CL.Button
          className="ds-margin-left--3 ds-margin-top--1 ds-padding-horizontal--5"
          size="l"
          color="light"
          type="submit"
          onClick={() => alert('Activate clicked')}
        >
          {t.EITS(activateMsg)}
        </CL.Button>
      </div>
      <div className="voucher-display-section ds-display--inline-block">
        {activeVoucher && <VoucherText text={t.QK7P('', activeVoucher)} onDisableClick={onDisableClick} />}
      </div>
    </div>
  </div>
);
