export interface InfoTextProps {
  info: string | number | JSX.Element;
  styleClass?: string;
}

export const InfoText = ({ info, styleClass }: InfoTextProps) => (
  <div className="info-text">
    <span className={styleClass}>{info}</span>
  </div>
);

export const HeaderText = ({ info }: InfoTextProps) => <InfoText info={info} styleClass="header" />;

export const Separator = () => <span className="bar">|</span>;
