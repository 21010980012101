import { JsonLd } from 'react-schemaorg';
import { SiteContext } from '../../public/site/SiteContext.js';
import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard.js';
import { atContext, atType } from '../../common/constants/namingConventionVariables.js';
import { createProductStructuredDataFromOnlineModel } from '../../common/structured-data/product.js';
import {
  getViewItemListAnalyticsEvent,
  isSubCardWithImpression,
  sendSelectionAnalyticsEvent,
} from './subscriptionCardsAnalyticsUtils.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { ItemList, Product, WithContext } from 'schema-dts';
import type { MouseEvent } from 'react';
import type { SubCardProps } from '../SubscriptionCard/SubscriptionCard.js';
import type { SubCardWithAnalyticsV4Props, SubCardWithImpressionProps } from './subscriptionCardsAnalyticsUtils.js';

import './SubscriptionCards.scss';

export type SubscriptionCardsProps = {
  buttonControls?: string;
  buttonText?: string;
  flex?: boolean;
  listName?: string;
  onButtonClick?: (subscription: SubCardProps | SubCardWithImpressionProps, e: MouseEvent<HTMLButtonElement>) => void;
  subscriptions: (SubCardProps | SubCardWithImpressionProps | SubCardWithAnalyticsV4Props)[];
};

const getItemList = (
  subscriptions: (SubCardProps | SubCardWithImpressionProps)[],
  siteBaseUrl: string,
  pathname: string
): WithContext<ItemList> => {
  // We need the onlineModel for the structured data.
  const items = subscriptions.filter(isSubCardWithImpression);
  return {
    [atContext]: 'https://schema.org',
    [atType]: 'ItemList',
    itemListElement: items.map((subData, idx): WithContext<Product> & { position: number } => {
      return {
        ...createProductStructuredDataFromOnlineModel(
          subData.onlineModel,
          siteBaseUrl,
          subData.offer.offerCode,
          subData.productName
        ),
        position: idx,
      };
    }),
    numberOfItems: items.length,
    url: `${siteBaseUrl}${pathname}`,
  };
};

export const SubscriptionCards = ({
  buttonControls,
  buttonText,
  flex,
  listName,
  onButtonClick,
  subscriptions,
}: SubscriptionCardsProps): JSX.Element => {
  const { pathname } = useLocation();
  const { siteBaseUrl } = useContext(SiteContext);
  const hasRunOnceRef = useRef(false);

  useEffect(() => {
    // Ref has to be used here, otherwise there might be several events sent of one subscription card page render
    if (!hasRunOnceRef.current && (subscriptions as SubCardWithAnalyticsV4Props[]).every(sub => sub.offer)) {
      pushToDataLayer(getViewItemListAnalyticsEvent(subscriptions as SubCardWithAnalyticsV4Props[], listName));
      hasRunOnceRef.current = true;
    }
  }, [listName, subscriptions]);

  return (
    <div
      className={[
        'of-subscription-cards',
        subscriptions.length === 1 ? 'of-subscription-cards-single' : '',
        flex ? ' of-subscription-cards--flex' : '',
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {subscriptions.map((subscription, idx) => {
        const buttonClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
          if (isSubCardWithImpression(subscription)) {
            sendSelectionAnalyticsEvent(subscription, idx, listName);
          }
          onButtonClick?.(subscription, e);
        };
        return (
          <div key={idx}>
            <SubscriptionCard
              {...subscription}
              buttonControls={buttonControls}
              buttonText={buttonText}
              onButtonClick={buttonClickHandler}
            />
          </div>
        );
      })}
      <JsonLd<ItemList> item={getItemList(subscriptions, siteBaseUrl, pathname)} />
    </div>
  );
};
