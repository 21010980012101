import { Catalog, CustomerCatalogItem } from '../../generated/api/models.js';
import { EMPTY, forkJoin, of } from 'rxjs';
import {
  TypeKeys,
  catalogToValidationRules,
  clearCartItems,
  copyVirtualCatalogFailed,
  copyVirtualCatalogFulfilled,
  deleteVirtualCatalogDraftFailed,
  deleteVirtualCatalogDraftFulfilled,
  deleteVirtualCatalogFailed,
  deleteVirtualCatalogFulfilled,
  loadOnlineModels,
  loadVirtualCatalogsFailed,
  loadVirtualCatalogsFulfilled,
  processCartItems,
  publishVirtualCatalogDraft,
  publishVirtualCatalogDraftFailed,
  publishVirtualCatalogDraftFulfilled,
  setSelectedVirtualCatalog,
  startNotification,
  updateSelectedBaId,
  upsertVirtualCatalog,
  upsertVirtualCatalogFailed,
  upsertVirtualCatalogFulfilled,
} from '../actions/index.js';
import { actionToActionState, isEmployeePortal, isPunchout } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import {
  collectProductCategoriesOfVirtualCatalog,
  findCatalogById,
  getPublishedCatalogs,
  toCatalogProductsPerCategory,
} from '../../common/utils/catalogUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { createBa } from './billingAccountEpic.js';
import {
  createDraftCatalogPrivateMethod,
  createVirtualCatalogPrivateMethod,
  deleteDraftCatalogPrivateMethod,
  deprecateVirtualCatalogPrivateMethod,
  getVirtualCatalogPrivateMethod,
  getVirtualCatalogsPrivateMethod,
  publishDraftCatalogPrivateMethod,
  updateDraftCatalogPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { generatePath } from 'react-router-dom';
import {
  getCatalogCode,
  getShoppingCart,
  removeCatalogCode,
  setCatalogCode,
  setNewCatalogCode,
} from '../common/localStorageUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { push } from 'redux-first-history';
import { t } from '../../common/i18n/index.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  CopyVirtualCatalogAction,
  DeleteVirtualCatalogAction,
  DeleteVirtualCatalogDraftAction,
  DeleteVirtualCatalogFulfilledAction,
  ErrorAction,
  LoadVirtualCatalogsAction,
  LoadVirtualCatalogsFulfilledAction,
  PublishVirtualCatalogDraftAction,
  PublishVirtualCatalogDraftFailedAction,
  PublishVirtualCatalogDraftFulfilledAction,
  SelfServiceActionTypes,
  SetSelectedVirtualCatalogAction,
  UpsertBillingAccountFailedAction,
  UpsertBillingAccountFulfilledAction,
  UpsertVirtualCatalogAction,
  UpsertVirtualCatalogFailedAction,
  UpsertVirtualCatalogFulfilledAction,
} from '../actions/index.js';
import type { Observable } from 'rxjs';
import type { PutVirtualCatalogResponse, VirtualCatalogResponse } from '../../generated/api/models.js';
import type { State } from '../common/store.js';

const isEmpty = (array?: object[] | string[]) => array === undefined || array.length === 0;

const validateAndFilterIncompatibleProducts = (
  updatedCatalog: Partial<Catalog>,
  existingCatalog: Catalog,
  state$: StateObservable<State>
): Partial<Catalog> => {
  // If updated catalog has different productType or contractPeriod than the existing catalog then the
  // products in the catalog needs to be re-validated such that if product does not have relevant price
  // option for new productType or contractPeriod, it is filtered out
  if (
    updatedCatalog.productType !== existingCatalog.productType ||
    updatedCatalog.contractPeriod !== existingCatalog.contractPeriod
  ) {
    const headers = state$.value.selfservice?.onlineModelHeaders?.items;
    // Headers for all needed categories should be present at this point as those get loaded in catalog details page
    if (!headers || !isEmpty(updatedCatalog.productCategories?.filter(category => isEmpty(headers[category])))) {
      throw new Error('Online model headers not present for all needed categories');
    }
    // Empty array used for discounts as only filtered products are needed from output (prices are ignored)
    const filteredProductsByCategory = toCatalogProductsPerCategory(
      updatedCatalog,
      true,
      [],
      state$.value.selfservice?.onlineModelHeaders
    );
    const filteredProducts = Object.values(filteredProductsByCategory)
      .flat()
      .map(product => product.code);
    return {
      ...updatedCatalog,
      products: filteredProducts,
    };
  }
  return updatedCatalog;
};

const getDraftCatalogLastModified = (catalogItems: CustomerCatalogItem[]): number | undefined =>
  catalogItems?.find(catalogItem => catalogItem.status === CustomerCatalogItem.StatusEnum.DRAFT)?.lastModified;

function updateDraftCatalog<T>(
  upsertCatalogAction: UpsertVirtualCatalogAction,
  epicDependencies: EpicDependencies,
  state$: StateObservable<State>
): Observable<T | ErrorAction<TypeKeys>> {
  let { ...payload } = upsertCatalogAction.catalog;
  const { catalogCode } = upsertCatalogAction.catalog;
  const virtualCatalog = upsertCatalogAction.virtualCatalog;

  if (!catalogCode || !virtualCatalog) {
    throw new Error('Error updating draft catalog. CatalogCode or virtualCatalogCode missing from request');
  }

  payload = validateAndFilterIncompatibleProducts(payload, virtualCatalog.draft!, state$);

  return callUiApi({
    epicDependencies,
    failureAction: upsertVirtualCatalogFailed,
    method: updateDraftCatalogPrivateMethod(virtualCatalog.virtualCatalogCode, catalogCode),
    payload,
    state$,
    successAction: (response: AjaxResponse) =>
      upsertVirtualCatalogFulfilled(
        false,
        {
          name: payload.name!,
          productType: payload.productType!,
          damageInsurance: payload.damageInsurance,
          enrollmentProgramConsent: payload.enrollmentProgramConsent!,
          enrollmentProgramAlias: payload.enrollmentProgramAlias!,
          contractPeriod: payload.contractPeriod!,
          corporateShare: payload.corporateShare,
          corporateMessage: payload.corporateMessage,
          products: payload.products!,
          billingAccountId: payload.billingAccountId!,
          productCategories: payload.productCategories!,
          publishedStatus: payload.publishedStatus!,
          lastModified: getDraftCatalogLastModified(response.response.catalogs),
        },
        virtualCatalog,
        upsertCatalogAction.publishCatalog
      ),
  });
}

function createDraftCatalog<T>(
  upsertCatalogAction: UpsertVirtualCatalogAction,
  epicDependencies: EpicDependencies,
  state$: StateObservable<State>
): Observable<T | ErrorAction<TypeKeys>> {
  let payload = upsertCatalogAction.catalog;
  const virtualCatalog = upsertCatalogAction.virtualCatalog;

  if (!virtualCatalog) {
    throw new Error('Error creating draft catalogue. VirtualCatalogCode missing from request');
  }

  if (virtualCatalog.published) {
    payload = validateAndFilterIncompatibleProducts(payload, virtualCatalog.published, state$);
  }

  return callUiApi({
    epicDependencies,
    failureAction: upsertVirtualCatalogFailed,
    method: createDraftCatalogPrivateMethod(virtualCatalog.virtualCatalogCode),
    state$,
    payload,
    successAction: (response: AjaxResponse) =>
      upsertVirtualCatalogFulfilled(
        false,
        {
          name: payload.name!,
          productType: payload.productType!,
          damageInsurance: payload.damageInsurance,
          enrollmentProgramConsent: payload.enrollmentProgramConsent!,
          enrollmentProgramAlias: payload.enrollmentProgramAlias!,
          contractPeriod: payload.contractPeriod!,
          corporateShare: payload.corporateShare,
          products: payload.products!,
          billingAccountId: payload.billingAccountId!,
          productCategories: payload.productCategories!,
          corporateMessage: payload.corporateMessage,
          created: response.response.created!,
          lastModified: getDraftCatalogLastModified(response.response.catalogs),
          publishedStatus: Catalog.PublishedStatusEnum.DRAFT,
          catalogCode: (response.response as PutVirtualCatalogResponse).draftCatalogCode,
        },
        virtualCatalog,
        upsertCatalogAction.publishCatalog
      ),
  });
}

function createVirtualCatalog<T>(
  payload: Partial<Catalog>,
  publishCatalog: boolean,
  epicDependencies: EpicDependencies,
  state$: StateObservable<State>,
  headers?: Record<string, string>
): Observable<T | ErrorAction<TypeKeys>> {
  return callUiApi({
    epicDependencies,
    headers,
    failureAction: upsertVirtualCatalogFailed,
    method: createVirtualCatalogPrivateMethod(),
    payload,
    state$,
    successAction: (response: AjaxResponse) => {
      return upsertVirtualCatalogFulfilled(
        true,
        {
          name: payload.name!,
          productType: payload.productType!,
          damageInsurance: payload.damageInsurance,
          enrollmentProgramConsent: payload.enrollmentProgramConsent!,
          enrollmentProgramAlias: payload.enrollmentProgramAlias!,
          contractPeriod: payload.contractPeriod!,
          corporateShare: payload.corporateShare,
          products: payload.products!,
          billingAccountId: payload.billingAccountId!,
          productCategories: payload.productCategories!,
          corporateMessage: payload.corporateMessage,
          catalogCode: response.response.draftCatalogCode!,
          created: response.response.created!,
          lastModified: response.response.lastModified!,
          publishedStatus: Catalog.PublishedStatusEnum.DRAFT,
        },
        {
          virtualCatalogCode: response.response.virtualCatalogCode!,
        },
        publishCatalog
      );
    },
  });
}

function publishDraftCatalog<T>(
  publishVirtualCatalogDraftAction: PublishVirtualCatalogDraftAction,
  epicDependencies: EpicDependencies,
  state$: StateObservable<State>
): Observable<T | ErrorAction<TypeKeys>> {
  return callUiApi({
    epicDependencies,
    failureAction: publishVirtualCatalogDraftFailed,
    method: publishDraftCatalogPrivateMethod(publishVirtualCatalogDraftAction.virtualCatalogCode),
    state$,
    successAction: () => publishVirtualCatalogDraftFulfilled(publishVirtualCatalogDraftAction.virtualCatalogCode),
  });
}

function handleVirtualCatalogUpsert<T>(
  action$: UpsertVirtualCatalogAction,
  epicDependencies: EpicDependencies,
  state$: StateObservable<State>
): Observable<T | ErrorAction<TypeKeys>> {
  // 1. If virtual catalog does not exist - create one, catalog is set as draft
  // 2. If draft exists - update it
  // 3. if draft does not exist - create it
  if (!action$.virtualCatalog) {
    return createVirtualCatalog(action$.catalog, action$.publishCatalog, epicDependencies, state$);
  }
  if (action$.virtualCatalog!.draft) {
    return updateDraftCatalog(action$, epicDependencies, state$);
  }
  return createDraftCatalog(action$, epicDependencies, state$);
}

export const setSelectedVirtualCatalogEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.SET_SELECTED_VIRTUAL_CATALOG).pipe(
    mergeMap((action: SetSelectedVirtualCatalogAction) => {
      action.selectedCatalogCode ? setCatalogCode(action.selectedCatalogCode) : removeCatalogCode();
      const selectedCatalog: Catalog | undefined = findCatalogById(
        state$.value.selfservice?.virtualCatalogs?.items,
        action.selectedCatalogCode
      );
      if (selectedCatalog?.billingAccountId != null) {
        return of(updateSelectedBaId(selectedCatalog?.billingAccountId));
      }

      return EMPTY;
    })
  );

//
// Virtual Catalogs
//

const loadVirtualCatalogsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_VIRTUAL_CATALOGS)), (action: LoadVirtualCatalogsAction) =>
    actionToActionState(action, state$, 'virtualCatalogs')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading virtual catalogs: query missing');
      }
      const loadCatalogsAction = actionAndState.action as LoadVirtualCatalogsAction;
      return callUiApi({
        epicDependencies,
        failureAction: loadVirtualCatalogsFailed,
        method: loadCatalogsAction.catalogId
          ? getVirtualCatalogPrivateMethod(loadCatalogsAction.catalogId)
          : getVirtualCatalogsPrivateMethod(),
        state$,
        successAction: (response: VirtualCatalogResponse) =>
          loadVirtualCatalogsFulfilled(response, loadCatalogsAction.catalogId),
      });
    })
  );

export const loadVirtualCatalogsFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.LOAD_VIRTUAL_CATALOGS_FULFILLED).pipe(
    mergeMap((action: LoadVirtualCatalogsFulfilledAction) => {
      const otherActions: Action[] = [];
      if (action.catalogId !== undefined) {
        const onlineModelHeaders = state$.value.selfservice?.onlineModelHeaders?.items;
        // load online model headers for all the categories in the catalog
        const catalog = state$.value.selfservice?.virtualCatalogs?.items?.find(
          item => item.virtualCatalogCode === action.catalogId
        );
        if (catalog) {
          const allNeededProductCategories = collectProductCategoriesOfVirtualCatalog(catalog);
          allNeededProductCategories.forEach((productCategory: string) => {
            if (!(onlineModelHeaders && onlineModelHeaders[productCategory])) {
              otherActions.push(loadOnlineModels(productCategory, true));
            }
          });
        }
      }
      const enabledFeatureFlags = state$.value.user?.authenticated?.enabledFeatureFlags;
      const publishedCatalogs = getPublishedCatalogs(state$.value.selfservice?.virtualCatalogs?.items);
      let selectedCatalogCode;
      if (
        enabledFeatureFlags?.includes('catalogPreSelected') &&
        publishedCatalogs &&
        publishedCatalogs.length === 1 &&
        publishedCatalogs[0].catalogCode
      ) {
        selectedCatalogCode = publishedCatalogs[0].catalogCode;
      }
      if (!selectedCatalogCode) {
        selectedCatalogCode = getCatalogCode();
      }
      if (!selectedCatalogCode) {
        return of(...otherActions, clearCartItems(), setSelectedVirtualCatalog(null));
      }
      const selectedCatalog = findCatalogById(action.catalogs, selectedCatalogCode);
      if (!selectedCatalog) {
        return of(...otherActions, clearCartItems(), setSelectedVirtualCatalog(null));
      }
      if (isEmployeePortal(window.location.pathname) || isPunchout(window.location.pathname)) {
        return of(
          processCartItems(false, true, getShoppingCart(), catalogToValidationRules(selectedCatalog)),
          setSelectedVirtualCatalog(selectedCatalogCode)
        );
      }
      return of(...otherActions, setSelectedVirtualCatalog(selectedCatalogCode));
    })
  );

export const deleteVirtualCatalogDraftEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT)),
    (action: DeleteVirtualCatalogDraftAction) => actionToActionState(action, state$, 'virtualCatalogs')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const deleteVirtualCatalogDraftAction = actionAndState.action as DeleteVirtualCatalogDraftAction;

      return callUiApi({
        epicDependencies,
        failureAction: deleteVirtualCatalogDraftFailed,
        method: deleteDraftCatalogPrivateMethod(
          deleteVirtualCatalogDraftAction.virtualCatalogCode,
          deleteVirtualCatalogDraftAction.catalogCode
        ),
        state$,
        successAction: () =>
          deleteVirtualCatalogDraftFulfilled(
            deleteVirtualCatalogDraftAction.virtualCatalogCode,
            deleteVirtualCatalogDraftAction.catalogCode
          ),
      }).pipe(
        concatMap((finalAction: DeleteVirtualCatalogFulfilledAction) => {
          return [finalAction, push(`${paths.COMPANY_INFO_CATALOGS}/${finalAction.virtualCatalogCode}`)];
        })
      );
    })
  );

export const publishVirtualCatalogDraftEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT)),
    (action: PublishVirtualCatalogDraftAction) => actionToActionState(action, state$, 'virtualCatalogs')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const publishVirtualCatalogDraftAction = actionAndState.action as PublishVirtualCatalogDraftAction;
      return publishDraftCatalog(publishVirtualCatalogDraftAction, epicDependencies, state$).pipe(
        concatMap((action: PublishVirtualCatalogDraftFulfilledAction | PublishVirtualCatalogDraftFailedAction) => {
          if (action.type === TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT_FAILED) {
            return [action];
          }
          return [action, startNotification(t.BTQA('Catalogue published'))];
        })
      );
    })
  );

export const deleteVirtualCatalogEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.DELETE_VIRTUAL_CATALOG)), (action: DeleteVirtualCatalogAction) =>
    actionToActionState(action, state$, 'virtualCatalogs')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const deleteCatalogAction = actionAndState.action as DeleteVirtualCatalogAction;

      return callUiApi({
        epicDependencies,
        failureAction: deleteVirtualCatalogFailed,
        method: deprecateVirtualCatalogPrivateMethod(deleteCatalogAction.virtualCatalogCode),
        state$,
        successAction: () => deleteVirtualCatalogFulfilled(deleteCatalogAction.virtualCatalogCode),
      }).pipe(
        concatMap((finalAction: DeleteVirtualCatalogFulfilledAction) => {
          return [finalAction, push(`${paths.COMPANY_INFO_CATALOGS}/`)];
        })
      );
    })
  );

export const upsertVirtualCatalogEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.UPSERT_VIRTUAL_CATALOG)), (action: UpsertVirtualCatalogAction) =>
    actionToActionState(action, state$, 'virtualCatalogs')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for upserting virtual catalog');
      }
      const upsertVirtualCatalogAction = actionAndState.action as UpsertVirtualCatalogAction;

      // If a new billingAccount is created - create it first before upserting the catalog
      if (upsertVirtualCatalogAction.newBillingAccount) {
        return createBa(upsertVirtualCatalogAction, state$, epicDependencies).pipe(
          concatMap((action: UpsertBillingAccountFulfilledAction | UpsertBillingAccountFailedAction): Action[] => {
            if (action.type === TypeKeys.UPSERT_BILLING_ACCOUNT_FAILED) {
              return [upsertVirtualCatalogFailed('Failed to create BA', 500)];
            } else {
              const actionArrays: Action[] = [
                action,
                upsertVirtualCatalog(
                  { ...upsertVirtualCatalogAction.catalog, billingAccountId: action.billingAccount.billingAccountId },
                  upsertVirtualCatalogAction.publishCatalog,
                  upsertVirtualCatalogAction.virtualCatalog,
                  undefined,
                  upsertVirtualCatalogAction.newBillingAccountValidationErrors,
                  true
                ),
              ];
              return actionArrays;
            }
          })
        );
      }

      return handleVirtualCatalogUpsert(upsertVirtualCatalogAction, epicDependencies, state$).pipe(
        concatMap((action: UpsertVirtualCatalogFulfilledAction | UpsertVirtualCatalogFailedAction) => {
          if (action.type === TypeKeys.UPSERT_VIRTUAL_CATALOG_FAILED) {
            return [action];
          }

          if (action.publishCatalog) {
            setNewCatalogCode(action.virtualCatalog.virtualCatalogCode);
            return [
              action,
              publishVirtualCatalogDraft(action.virtualCatalog.virtualCatalogCode),
              push(paths.COMPANY_INFO_CATALOGS),
            ];
          } else if (action.isCreate) {
            setNewCatalogCode(action.virtualCatalog.virtualCatalogCode);
            return [
              action,
              push(`${paths.COMPANY_INFO_CATALOGS}/`),
              startNotification(
                t.P7JV('Device list is saved as a draft. Remember to publish the device list when ready.')
              ),
            ];
          } else {
            return [
              action,
              push(generatePath(paths.COMPANY_INFO_CATALOG, { catalogCode: action.virtualCatalog.virtualCatalogCode })),
              startNotification(
                t.P7JV('Device list is saved as a draft. Remember to publish the device list when ready.')
              ),
            ];
          }
        })
      );
    })
  );

const handleCopyCatalog = (
  account: string,
  copyVirtualCatalogAction: CopyVirtualCatalogAction,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return of(account).pipe(
    concatMap(val => {
      return createVirtualCatalog(copyVirtualCatalogAction.catalog, false, epicDependencies, state$, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-API-Account-Master-ID': val,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Elisa-Company-MDM-ID': val,
      });
    })
  );
};

export const copyVirtualCatalogEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.COPY_VIRTUAL_CATALOG)), (action: UpsertVirtualCatalogAction) =>
    actionToActionState(action, state$, 'virtualCatalogs')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for copying virtual catalog');
      }
      const activeAccountMasterId =
        state$.value.user?.authenticated?.mdmId || state$.value.user?.authenticated?.activeAccountMasterId;
      const copyVirtualCatalogAction = actionAndState.action as CopyVirtualCatalogAction;

      return of(copyVirtualCatalogAction.accounts).pipe(
        concatMap(accounts =>
          forkJoin(
            accounts.map((account: string) => {
              return handleCopyCatalog(account, copyVirtualCatalogAction, state$, epicDependencies).pipe(
                map((action: UpsertVirtualCatalogFulfilledAction | UpsertVirtualCatalogFailedAction) => {
                  return {
                    accountMasterId: account,
                    action,
                  };
                })
              );
            })
          )
        ),
        concatMap(actions => {
          if (actions.every(action => action.action.type === TypeKeys.UPSERT_VIRTUAL_CATALOG_FULFILLED)) {
            const activeAccountAction = actions.find(x => x.accountMasterId === activeAccountMasterId)
              ?.action as UpsertVirtualCatalogFulfilledAction;
            return [
              copyVirtualCatalogFulfilled(activeAccountAction?.catalog, activeAccountAction?.virtualCatalog),
              push(paths.COMPANY_INFO_CATALOGS),
            ];
          } else if (actions.every(action => action.action.type === TypeKeys.UPSERT_VIRTUAL_CATALOG_FAILED)) {
            return [copyVirtualCatalogFailed()];
          } else {
            const activeAccountAction = actions.find(x => x.accountMasterId === activeAccountMasterId)
              ?.action as UpsertVirtualCatalogFulfilledAction;
            const accountsFailedToCopyTo = actions
              .filter(action => action.action.type === TypeKeys.UPSERT_VIRTUAL_CATALOG_FAILED)
              .map(action => action.accountMasterId);
            return [
              copyVirtualCatalogFulfilled(
                activeAccountAction?.catalog,
                activeAccountAction?.virtualCatalog,
                accountsFailedToCopyTo
              ),
            ];
          }
        })
      );
    })
  );

export const catalogsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  loadVirtualCatalogsEpic,
  loadVirtualCatalogsFulfilledEpic,
  setSelectedVirtualCatalogEpic,
  deleteVirtualCatalogDraftEpic,
  publishVirtualCatalogDraftEpic,
  deleteVirtualCatalogEpic,
  upsertVirtualCatalogEpic,
  copyVirtualCatalogEpic
);
