import { Catalog } from '../../generated/api/models.js';
import { CompositeList } from '../CompositeList/CompositeList.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Grid } from '../Grid/Grid.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import {
  cancelMsg,
  continueMsg,
  contractPeriodMsg,
  deviceShopMsg,
  elisaDevicesServiceMsg,
  monthMsg,
  monthlyChargeMsg,
  nameMsg,
  oneTimePaymentMsg,
  productTypeMsg,
  t,
} from '../../common/i18n/index.js';
import { getVatAsString } from '../../common/utils/taxUtils.js';
import { resolveCategoryUrl } from '../../common/utils/catalogUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useEffect, useState } from 'react';
import type { CompositeListColumn } from '../CompositeListHeader/CompositeListHeader.js';

interface EmployeeDeviceStoreHomeSceneProps {
  catalogs: Catalog[];
  onSelectCatalog: (selectedCatalogCode: string) => void;
  clearCartItems: () => void;
  selectedCatalogCode?: string | null;
  hasCartItems: boolean;
  onClickCatalog: (category?: string) => void;
}

const catalogProductsColumns: CompositeListColumn<Catalog>[] = [
  {
    heading: t.VGFI(nameMsg),
    columnId: 'catalogName',
    ref: 'name',
  },
  {
    heading: t.R6HP(productTypeMsg),
    columnId: 'productType',
    value: item => {
      switch (item.productType) {
        case Catalog.ProductTypeEnum.EPP_RECURRING:
          return t.TRE5(elisaDevicesServiceMsg);
        case Catalog.ProductTypeEnum.ONETIME:
          return t.ASEI(oneTimePaymentMsg);
        case Catalog.ProductTypeEnum.RECURRING:
          return t.P0PS(monthlyChargeMsg);
        default:
          return '-';
      }
    },
  },
  {
    heading: t.KIEG(contractPeriodMsg),
    columnId: 'contractPeriod',
    ref: 'contractPeriod',
    refFormatNumber: value => (value ? `${value} ${t.XXVX(monthMsg)}` : '—'),
  },
];

const ConfirmationModal = ({ onSubmit, onCancel }: { onSubmit: () => void; onCancel: () => void }) => (
  <DialogWrapper
    buttons={[
      {
        color: 'link',
        onClick: onCancel,
        text: t.B2V1(cancelMsg),
      },
      {
        onClick: onSubmit,
        text: t.I62A(continueMsg),
      },
    ]}
    closeable
    header={t.THL9('The order can only have products from one catalog.')}
    onCloseDialog={onCancel}
  >
    <p>
      {t.NIY2(
        'Complete the current order and then order the products from a different catalog. If you continue to another device list, your shopping cart will be automatically emptied.'
      )}
    </p>
  </DialogWrapper>
);

export const EmployeeDeviceStoreHomeScene = (props: EmployeeDeviceStoreHomeSceneProps) => {
  const [selectedCatalogId, setSelectedCatalogId] = useState<string | undefined>();
  const { authenticatedUser } = useAuth();
  const { catalogs, clearCartItems, onClickCatalog, onSelectCatalog, selectedCatalogCode, hasCartItems } = props;
  useEffect(() => {
    if (
      authenticatedUser?.enabledFeatureFlags?.includes('catalogPreSelected') &&
      catalogs.length === 1 &&
      catalogs[0].catalogCode
    ) {
      onClickCatalog(resolveCategoryUrl(catalogs, catalogs[0].catalogCode));
    }
  }, [catalogs, authenticatedUser, onClickCatalog]);

  const content = (
    <Grid colWidthL={8}>
      <div className="ds-margin-horizontal--7 ds-padding-bottom--4">
        <h3 className="ds-h3">{t.OTQ4('Select the device list')}</h3>
        <p>
          {t.JNQG(
            `Device lists define available devices according to your employer's policy, for example, to specific manufacturers, models or price categories.`
          )}
        </p>
        <p>
          {t.VBQK(
            'The device contract period is predefined. Quoted prices include VAT {}%. The devices shown with 0,00 €/month prices are devices that your employer fully pays for. The products exceeding the 0,00€/monthly price are devices you can order by paying the presented price yourself.',
            getVatAsString()
          )}
        </p>
        <p>
          {t.WA9H(
            `Select the list where want to choose your device. Please note products of different lists can't be combined and only products of one catalog list can be ordered together, select list accordingly. If you do not know which list to choose, please contact your supervisor or your employer's administrator for details.`
          )}
        </p>
        <p>
          {t.QGK2(
            `Elisa Oyj does not manage the device list selection. The device list is managed by your employer. If you have any questions about the device list, please contact your company admin.`
          )}
        </p>
      </div>
      <CompositeList
        classes={['ds-padding-vertical--4']}
        columns={catalogProductsColumns}
        getRowId={catalog => catalog.catalogCode!}
        items={catalogs}
        onSelectRow={(id: string) => {
          if (id !== selectedCatalogCode && hasCartItems) {
            setSelectedCatalogId(id);
          } else {
            onSelectCatalog(id);
            onClickCatalog(resolveCategoryUrl(catalogs, id));
          }
        }}
        rowIcon="product-list"
      />
    </Grid>
  );
  return (
    <>
      <HeroHeading
        center={true}
        fullScreen={true}
        heroHeadingType={HeroHeadingType.EMPLOYEE_STORE}
        title={t.C6OR(deviceShopMsg)}
        subtitle={
          <>
            <span>{t.KB1F('Choose the suitable devices for your work.')}</span>
            <span>{t.PDYB('With Oma Lasku you always get the best option.')}</span>
          </>
        }
      />
      {content}
      {selectedCatalogId && (
        <ConfirmationModal
          onCancel={() => setSelectedCatalogId(undefined)}
          onSubmit={() => {
            clearCartItems();
            onSelectCatalog(selectedCatalogId);
            onClickCatalog(resolveCategoryUrl(catalogs, selectedCatalogId));
          }}
        />
      )}
    </>
  );
};
