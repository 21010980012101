import { DeviceCheckoutContactInfo } from '../../DeviceCheckoutContactInfo/DeviceCheckoutContactInfo.js';
import { LoginAndRegistration } from '../../LoginAndRegistration/LoginAndRegistration.js';
import { convertStringMapToCommonErrors } from '../../../common/utils/errorUtils.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { getContactInfo } from '../../../common/utils/stateUtils.js';
import { loadContacts, updateUserDetails } from '../../../selfservice/actions/index.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import type { FunctionComponent, ReactElement } from 'react';
import type { State } from '../../../selfservice/common/store.js';

interface CheckoutLoginOrContactInfoStepProps {
  onNext?: () => void;
}

export const CheckoutLoginOrContactInfoStep: FunctionComponent<CheckoutLoginOrContactInfoStepProps> = (
  props: CheckoutLoginOrContactInfoStepProps
): ReactElement => {
  const cartItems = useSelector((state: State) => state.deviceCheckout?.cartItems ?? [], deepEqual);
  const { authenticatedUser } = useAuth();
  const { ssoSessionValid } = useAuth();

  const dispatch = useDispatch();

  return ssoSessionValid && authenticatedUser ? (
    <DeviceCheckoutContactInfo
      cartItems={cartItems}
      contactInfo={getContactInfo(authenticatedUser)}
      companyName={authenticatedUser.companyName}
      user={authenticatedUser}
      saveContactInfoAndOpenNextSection={(contactInfo, validationErrors) => {
        dispatch(updateUserDetails(contactInfo, undefined, convertStringMapToCommonErrors(validationErrors)));
        dispatch(loadContacts());
        if (props.onNext) {
          props.onNext();
        }
      }}
    />
  ) : (
    <LoginAndRegistration />
  );
};
