import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView.js';
import { ContractValiditySummary } from '../SubscriptionLists/ContractValiditySummary.js';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table, TableTopActions } from '../Table/index.js';
import { DialogType, SubscriptionCategory } from '../../common/enums.js';
import { ShowAllAccountsToggleForListViews } from '../ShowAllAccountsToggle/ShowAllAccountsToggleForListViews.js';
import { SubscriptionHiddenFields } from '../SubscriptionLists/SubscriptionHiddenFields.js';
import { SubscriptionLink } from '../SubscriptionLists/SubscriptionLink.js';
import { TableSubscriptionTypeFilter } from './TableSubscriptionTypeFilter.js';
import {
  addressOrNumberMsg,
  billingAccountMsg,
  companyMsg,
  contractMsg,
  identifierMsg,
  orderMsg,
  t,
} from '../../common/i18n/index.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useLoaderData } from 'react-router-dom';
import { useMultiAccountRowClick } from '../../common/hooks/useMultiAccountRowClick.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type * as CL from '@design-system/component-library';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { MultiAccountRowClickFunction } from '../../common/hooks/useMultiAccountRowClick.js';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';
import type { SubscriptionSearchResponse, SubscriptionsResponse } from '../../generated/api/models.js';

const getBroadbandRows = (
  searchResults: SubscriptionSearchResponse[] = [],
  search?: string,
  authenticatedUser?: RealAuthenticatedUserState,
  onRowClick?: MultiAccountRowClickFunction
) => {
  return searchResults.map(({ result: subscriptionHeader, matchedFields }) => ({
    subscriptionName: (
      <SubscriptionLink
        subscription={subscriptionHeader}
        path={paths.PS_BROADBAND_SUBSCRIPTION}
        search={search}
        onRowClick={onRowClick}
      >
        <SubscriptionHiddenFields
          subscriptionHeader={subscriptionHeader}
          search={search}
          matchedFields={matchedFields}
        />
      </SubscriptionLink>
    ),
    subscriptionUserFriendlyId: matchedFields?.includes('subscriptionUserFriendlyId')
      ? highlightKeyword(subscriptionHeader.subscriptionUserFriendlyId || '', search)
      : subscriptionHeader.subscriptionUserFriendlyId || '',
    purposeOfUseOrContactName: highlightKeyword(
      subscriptionHeader.subscriptionPurposeOfUse || subscriptionHeader.subscriptionContactName || '',
      search
    ),
    billingAccountDisplayId: matchedFields?.includes('billingAccountDisplayId')
      ? highlightKeyword(subscriptionHeader.billingAccountDisplayId || '', search)
      : subscriptionHeader.billingAccountDisplayId || '',
    contractValidity: <ContractValiditySummary commitmentEndDate={subscriptionHeader?.commitmentEndDate} />,
    company: getCompanyName(authenticatedUser, subscriptionHeader.accountMasterId),
  }));
};

export const SubscriptionsBroadband = () => {
  const dispatch = useDispatch();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;
  const { searchResults, total } = useLoaderData() as SubscriptionsResponse;
  const { authenticatedUser } = useAuth();
  const onRowClick = useMultiAccountRowClick();

  const broadbandColumns: CL.Column[] = [
    {
      key: 'subscriptionName',
      label: <TableSubscriptionTypeFilter subscriptionCategory={SubscriptionCategory.BROADBAND} />,
      width: '25%',
    },
    { key: 'subscriptionUserFriendlyId', label: t.LK7B(addressOrNumberMsg), sortable: true, width: '20%' },
    { key: 'purposeOfUseOrContactName', label: t.L9QG(identifierMsg), sortable: true, width: '25%' },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true, width: '5%' },
    { key: 'contractValidity', label: t.C8I6(contractMsg), sortable: false, width: '25%' },
    { key: 'company', label: t.KJTS(companyMsg), sortable: false },
  ];

  return (
    <div className="of-subscription-table">
      <TableTopActions
        buttons={[
          {
            label: t.AQOL(orderMsg),
            onClick: () =>
              dispatch(
                showDialog({ type: DialogType.SELECT_NEW_SUBSCRIPTION_TYPE, hideVoice: true, hideBlankSim: true })
              ),
          },
        ]}
      />
      <ConsolidatedListView
        authenticatedUser={authenticatedUser}
        listElement={
          <Table
            columns={broadbandColumns}
            noItemsText={t.UF2D('No internet subscriptions')}
            rows={getBroadbandRows(searchResults, search, authenticatedUser, onRowClick)}
          />
        }
        listSearch={
          <ListSearch
            onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
          />
        }
        listPagination={<ListPagination totalItems={total || 0} />}
        toggleElement={<ShowAllAccountsToggleForListViews className="ds-margin-bottom--4" />}
      />
    </div>
  );
};
