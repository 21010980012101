import { CommercialProductSubType } from '../../generated/api/commercialProductSubType.js';
import { CommercialProductType } from '../../generated/api/commercialProductType.js';
import { formatSumToString } from '../../common/utils/priceUtils.js';
import { getPriceForSalesProduct } from './ShoppingCartWithVoucherUtils.js';
import {
  monthlyChargesMsg,
  monthsMsg,
  oneTimePaymentMsg,
  singleFaresMsg,
  sumPerMonthMsg,
  t,
} from '../../common/i18n/index.js';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { DiscountedPrices } from '../../generated/api/discountedPrices.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { OnlineModelsResponse } from '../../generated/api/onlineModelsResponse.js';
import type { Price } from '../../generated/api/price.js';
import type { ShoppingCartAddOn, ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type {
  ShoppingCartPaymentOption,
  ShoppingCartPriceType,
  ShoppingCartTotalPrice,
} from '@design-system/component-library';

const emptyPrice = {
  amount: '',
  unit: '',
};

export const getCommercialProductForGuid = (guid: string, model?: OnlineModel): CommercialProduct | undefined => {
  return model?.offers
    .flatMap(offer => offer.commercialProducts)
    .find(commercialProduct => commercialProduct?.commercialProductCode === guid);
};

export const getPriceToDisplay = (price: Price | undefined, quantity: number): ShoppingCartPriceType => {
  if (price?.monthlyRecurringCharge) {
    return {
      amount: formatSumToString(price.monthlyRecurringCharge * quantity, true),
      unit: t.YO7F(sumPerMonthMsg, '€'),
    };
  } else if (price?.oneTimeCharge) {
    return { amount: formatSumToString(price.oneTimeCharge * quantity, true), unit: '€' };
  }
  return emptyPrice;
};

export const findDiscountedPrice = (discountedPrices: DiscountedPrices[], commercialProductCode?: string) => {
  return discountedPrices
    .flatMap(discountedPricesEntry => discountedPricesEntry.prices)
    .find(discountedPrice => discountedPrice.commercialProductCode === commercialProductCode);
};

export interface PriceData {
  isDiscountedPrice?: boolean;
  price?: Price;
}

export interface PriceDisplayData {
  isDiscountedPrice?: boolean;
  shoppingCartPrice: ShoppingCartPriceType;
}

export const getPriceForGuid = (
  guid: string,
  model: OnlineModel | undefined,
  discountedPrices: DiscountedPrices[]
): PriceData | undefined => {
  const commercialProduct = getCommercialProductForGuid(guid, model);
  if (commercialProduct) {
    const discountedPrice = findDiscountedPrice(discountedPrices, commercialProduct.commercialProductCode);
    return {
      isDiscountedPrice: !!discountedPrice,
      price: discountedPrice ?? commercialProduct.price?.listPrice,
    };
  }
  return undefined;
};

export const getPriceForGuidToDisplay = (
  guid: string,
  model: OnlineModel | undefined,
  discountedPricesForModel: DiscountedPrices | undefined,
  quantity: number
): PriceDisplayData => {
  const priceData = getPriceForGuid(guid, model, discountedPricesForModel ? [discountedPricesForModel] : []);
  return {
    isDiscountedPrice: priceData?.isDiscountedPrice || false,
    shoppingCartPrice: priceData ? getPriceToDisplay(priceData.price, quantity) : emptyPrice,
  };
};

export const calculateTotalPrices = (
  cartItems: ShoppingCartItemForCheckout[],
  models: OnlineModel[],
  onlineModelsHeadersForSalesProducts: OnlineModelsResponse,
  discountedPrices: DiscountedPrices[]
): ShoppingCartTotalPrice[] => {
  const calculateAddonPricesOneTime = (addOns: ShoppingCartAddOn[]): number =>
    addOns.reduce((total, addOn) => total + (addOn.addOnOneTimeCharge ?? 0), 0);

  const calculateAddonPricesMonthly = (addOns: ShoppingCartAddOn[]): number =>
    addOns.reduce((total, addOn) => total + (addOn.addOnMonthlyRecurringCharge ?? 0), 0);

  const { monthlyRecurringCharges, onetimeCharges } = cartItems.reduce(
    (acc, cartItem) => {
      const onlineModelForItem = models?.find(model => model.onlineModelCode === cartItem.onlineModelCode);
      const commercialProduct = getCommercialProductForGuid(cartItem.price.guid, onlineModelForItem);

      const discountedPrice = findDiscountedPrice(discountedPrices, commercialProduct?.commercialProductCode);
      const price = discountedPrice ?? commercialProduct?.price?.listPrice;

      if (onlineModelForItem?.category === CommercialProductType.SALES_PRODUCT) {
        const priceData = getPriceForSalesProduct(cartItem, onlineModelsHeadersForSalesProducts);
        acc.monthlyRecurringCharges += (priceData.price?.monthlyRecurringCharge || 0) * cartItem.quantity;
        acc.onetimeCharges += (priceData.price?.oneTimeCharge || 0) * cartItem.quantity;
      } else {
        acc.monthlyRecurringCharges += (price?.monthlyRecurringCharge || 0) * cartItem.quantity;
        acc.onetimeCharges += (price?.oneTimeCharge || 0) * cartItem.quantity;
      }

      acc.onetimeCharges += calculateAddonPricesOneTime(cartItem.selectedAddOns) * cartItem.quantity;
      acc.monthlyRecurringCharges += calculateAddonPricesMonthly(cartItem.selectedAddOns) * cartItem.quantity;

      return acc;
    },
    { monthlyRecurringCharges: 0, onetimeCharges: 0 }
  );

  return [
    ...(monthlyRecurringCharges
      ? [
          {
            name: t.P6BC(monthlyChargesMsg),
            amount: formatSumToString(monthlyRecurringCharges, true),
            unit: t.YO7F(sumPerMonthMsg, '€'),
          },
        ]
      : []),
    ...(onetimeCharges
      ? [
          {
            amount: formatSumToString(onetimeCharges, true),
            name: t.GOBY(singleFaresMsg),
            unit: '€',
          },
        ]
      : []),
  ];
};

export const findOfferForCommercialProduct = (offers: Offer[], commercialProductCode?: string) =>
  offers.find(offer =>
    offer.commercialProducts?.some(
      commercialProduct => commercialProduct.commercialProductCode === commercialProductCode
    )
  );

export interface PaymentOptionData {
  id: string;
  price: Price | undefined;
  isEpp: boolean;
  selected: boolean;
}

const getPriceOptionsForGuid = (
  priceGuid: string,
  onlineModel: OnlineModel | undefined,
  isLoggedIn: boolean,
  discountedPrices: DiscountedPrices[]
): PaymentOptionData[] => {
  const commercialProduct = getCommercialProductForGuid(priceGuid, onlineModel);
  const offerForCommercialProduct = findOfferForCommercialProduct(
    onlineModel?.offers || [],
    commercialProduct?.commercialProductCode
  );
  return (
    offerForCommercialProduct?.commercialProducts
      .map(cp => ({
        id: `${cp.commercialProductCode}_${cp?.productSubType}`,
        price: findDiscountedPrice(discountedPrices, cp.commercialProductCode) || cp.price?.listPrice,
        isEpp: cp?.productSubType === CommercialProductSubType.EPP_DEVICE,
        selected: cp.commercialProductCode === priceGuid,
      }))
      // Show EPP payment options only for logged-in users
      .filter(paymentOptionData => isLoggedIn || (!isLoggedIn && !paymentOptionData.isEpp)) || []
  );
};

const getPaymentLabel = (price: Price | undefined, isEpp: boolean) => {
  if (price?.monthlyRecurringCharge) {
    return `${price.payments}${t.XXVX(monthsMsg)}, ${formatSumToString(price.monthlyRecurringCharge, true)} ${t.YO7F(
      sumPerMonthMsg,
      '€'
    )}${isEpp ? ' (EPP)' : ''}`;
  } else {
    return `${t.ASEI(oneTimePaymentMsg)} ${formatSumToString(price?.oneTimeCharge)}`;
  }
};

export const getPaymentOptions = (
  selectedPriceGuid: string,
  onlineModel: OnlineModel | undefined,
  isLoggedIn: boolean,
  discountedPrices: DiscountedPrices[]
): ShoppingCartPaymentOption[] => {
  const priceOptions = getPriceOptionsForGuid(selectedPriceGuid, onlineModel, isLoggedIn, discountedPrices);
  return (
    priceOptions
      // Sort lowest monthly charge first
      .sort((a, b) => (a.price?.monthlyRecurringCharge || 0) - (b.price?.monthlyRecurringCharge || 0))
      // Sort EPPs first
      .sort((a, b) => Number(b.isEpp) - Number(a.isEpp))
      // Sort one time charges last
      .sort((a, b) => (a.price?.oneTimeCharge || 0) - (b.price?.oneTimeCharge || 0))
      .map(sortedPrice => ({
        id: sortedPrice.id,
        label: getPaymentLabel(sortedPrice.price, sortedPrice.isEpp) || '',
        selected: sortedPrice.selected,
      }))
  );
};
