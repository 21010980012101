import classNames from 'classnames';
import type { ComponentProps, ReactNode } from 'react';

import './FormGridFieldset.scss';

/*
Starts a new row (i.e. the item with this class will be the first item on the row).
If the item is full width, you can just use FORM_GRID_FULL_WIDTH alone.
 */
export const FORM_GRID_CLEAR = 'form-grid-clear';
// Makes the item fill the row horizontally, forcing the next item to a new row.
export const FORM_GRID_FULL_WIDTH = 'form-grid-full-width';
// Places item in the last column, possibly leaving preceding column(s) empty.
export const FORM_GRID_FORCE_END = 'form-grid-force-end';

interface FormGridFieldsetProps extends ComponentProps<'fieldset'> {
  // Contents of the legend (i.e. don't shove a legend element in here...)
  legend?: ReactNode;
}

export const FormGridFieldset = ({ children, className, legend, ...fieldsetProps }: FormGridFieldsetProps) => {
  return (
    <fieldset {...fieldsetProps} className={classNames('form-grid-fieldset', className)}>
      {legend && (
        <legend className={classNames(FORM_GRID_FULL_WIDTH, 'ds-font-weight--700', 'ds-padding-bottom--4')}>
          {legend}
        </legend>
      )}
      {children}
    </fieldset>
  );
};
