import * as CL from '@design-system/component-library';
import { Loading } from '../Loading/Loading.js';
import { ProductGridHeader } from './ProductGridHeader.js';
import { ProductGridItems } from './ProductGridItems.js';
import { ProductGridTitle } from './ProductGridTitle.js';
import { createFilterEvent, createSortEvent } from '../../common/utils/analyticsUtils.js';
import { filterFn } from './ProductGridFilter.js';
import { getLocationFromQueryParams, parseQueryParams } from './ProductGridUrlUtils.js';
import { searchFn } from './ProductGridSearch.js';
import { showMoreMsg, t } from '../../common/i18n/index.js';
import { sortFn } from './ProductGridSort.js';
import { useDebounce } from '../../common/hooks/useDebounce.js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller'; // eslint-disable-line
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { ECommerceEvents } from '../PublicPage/types.js';
import type { Filters } from './ProductGridFilter.js';
import type { OnlineModelCategory } from '../../generated/api/models.js';
import type { SortType } from './ProductGridSort.js';

import './ProductGrid.scss';

export interface ProductGridProps {
  category: OnlineModelCategory;
  eCommerceEvents?: ECommerceEvents;
  items: ProductGridItem[];
  onAnalyticsEvent?: (eventData: object) => void;
  infiniteScrollActive?: boolean;
  itemLimit?: number;
  title?: string;
  titleTag?: string;
  content?: string;
  id?: string;
  inEOE?: boolean;
}

export type ProductGridItem = CatalogProduct;

export const ProductGrid = (props: ProductGridProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category, eCommerceEvents, items, onAnalyticsEvent, infiniteScrollActive, itemLimit, id, inEOE } = props;
  // This exists to enable filtering in multiple product grids on the same page and remember their settings
  const urlParameterPrefix = id ?? '';
  const itemsPerScroll = itemLimit || 8;
  const urlSearchParams = new URLSearchParams(location.search);
  const [itemsToDisplay, setItemsToDisplay] = useState(itemsPerScroll);
  const debouncedNavigate = useDebounce(url => navigate(url, { replace: true }), 1000);
  const [productGridItems, setProductGridItems] = useState<ProductGridItem[]>(() => {
    const params = parseQueryParams(items, category, urlSearchParams, urlParameterPrefix);
    return items.filter(searchFn(params.search)).filter(filterFn(params.filters)).sort(sortFn(params.sort));
  });
  const [currentParams, setCurrentParams] = useState(
    parseQueryParams(items, category, urlSearchParams, urlParameterPrefix)
  );

  const fetchMoreItems = () => {
    if (itemsToDisplay <= productGridItems.length) {
      setItemsToDisplay(itemsToDisplay + itemsPerScroll);
    }
  };

  const hasMoreItems = () => {
    return itemsToDisplay < productGridItems.length;
  };

  const getSlicedItems = () => {
    return productGridItems.slice(0, itemsToDisplay);
  };

  const handleSortChange = (sortType: SortType) => {
    const params = parseQueryParams(items, category, urlSearchParams, urlParameterPrefix);
    params.sort = sortType;
    setCurrentParams(params);
    debouncedNavigate(getLocationFromQueryParams(params, urlParameterPrefix));
  };

  const handleFilterChange = (filter: Filters) => {
    const params = parseQueryParams(items, category, urlSearchParams, urlParameterPrefix);
    params.filters = filter;
    setCurrentParams(params);
    debouncedNavigate(getLocationFromQueryParams(params, urlParameterPrefix));
  };

  const handleSearchChange = (searchValue: string) => {
    const params = parseQueryParams(items, category, urlSearchParams, urlParameterPrefix);
    params.search = searchValue;
    setCurrentParams(params);
    debouncedNavigate(getLocationFromQueryParams(params, urlParameterPrefix));
  };

  useEffect(() => {
    setProductGridItems(
      items
        .filter(searchFn(currentParams.search))
        .filter(filterFn(currentParams.filters))
        .sort(sortFn(currentParams.sort))
    );
    setItemsToDisplay(itemsPerScroll);
  }, [currentParams, items, category, itemsPerScroll]);

  return (
    <div className="of-product-grid">
      <ProductGridTitle {...props} />
      <ProductGridHeader
        filters={currentParams.filters}
        search={currentParams.search}
        sort={currentParams.sort}
        setFilters={(filter: Filters) => {
          if (onAnalyticsEvent) {
            onAnalyticsEvent(createFilterEvent(filter));
          }
          handleFilterChange(filter);
        }}
        setSearch={handleSearchChange}
        setSort={(sortType: SortType) => {
          if (onAnalyticsEvent) {
            onAnalyticsEvent(createSortEvent(sortType));
          }
          handleSortChange(sortType);
        }}
      />

      {infiniteScrollActive ? (
        <InfiniteScroll
          hasMore={hasMoreItems()}
          initialLoad={true}
          loader={<Loading key="loader" big />}
          loadMore={fetchMoreItems}
          pageStart={0}
        >
          <ProductGridItems inEOE={inEOE} eCommerceEvents={eCommerceEvents} productGridItems={getSlicedItems()} />
        </InfiniteScroll>
      ) : (
        <>
          {/* TODO: We have CL.ProductGrid also which should be used instead of this.
                    Things to consider before doing the refactor:
                      - what is the current DS version used? Migration to DS 4.x.x should be done
                      - check differences in data model used:
                          CatalogProduct vs. CL.ProductGridProduct
          */}
          <ProductGridItems inEOE={inEOE} eCommerceEvents={eCommerceEvents} productGridItems={getSlicedItems()} />
          {hasMoreItems() && (
            <div className="ds-text-align--center">
              <CL.Button
                size="l"
                onClick={fetchMoreItems}
                color="light"
                className="ds-margin-top--4 ds-margin-bottom--4"
              >
                {t.H6OA(showMoreMsg)}
              </CL.Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
