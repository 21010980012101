import classNames from 'classnames';
import type { ElementType } from 'react';

// CSS is included in SitePath.tsx to make sure it's always (globally) available

type IntrinsicElement = keyof JSX.IntrinsicElements;

type PageMarginsProps<T extends IntrinsicElement> = {
  as?: 'grid' | 'margin' | 'padding';
  tag?: IntrinsicElement;
} & JSX.IntrinsicElements[T];

const BASE_CLASS = 'of-page-margins';
export const PAGE_MARGINS_AS_GRID = `${BASE_CLASS}--as-grid`;
export const PAGE_MARGINS_AS_MARGIN = `${BASE_CLASS}--as-margin`;
export const PAGE_MARGINS_AS_PADDING = `${BASE_CLASS}--as-padding`;
export const PAGE_MARGINS_BLEED = `${BASE_CLASS}__bleed`;
export const PAGE_MARGINS_BLEED_LEFT = `${PAGE_MARGINS_BLEED}-left`;
export const PAGE_MARGINS_BLEED_RIGHT = `${PAGE_MARGINS_BLEED}-right`;

export const PageMargins = <T extends IntrinsicElement>({
  as = 'margin',
  tag = 'div',
  children,
  className,
  ...rest
}: PageMarginsProps<T>) => {
  const Component = tag as ElementType;
  return (
    <Component
      className={classNames(
        'of-page-margins',
        as === 'grid' && PAGE_MARGINS_AS_GRID,
        as === 'margin' && PAGE_MARGINS_AS_MARGIN,
        as === 'padding' && PAGE_MARGINS_AS_PADDING,
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};
