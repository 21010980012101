import { ChangeRequestStatus } from '../generated/api/models.js';
import {
  activateTurbonappiOrderPublicMethod,
  changeSubscriptionBillingAccountPrivateMethod,
  createAdminUserPrivateMethod,
  createAnonymousLeadPublicMethod,
  createAnonymousSupportCasePublicMethod,
  createBillingAccountPrivateMethod,
  createContactPrivateMethod,
  createDnsRecordPrivateMethod,
  createSupportCasePrivateMethod,
  deleteAdminUserPrivateMethod,
  deleteDnsRecordPrivateMethod,
  deprecateVirtualCatalogPrivateMethod,
  domainGetPublicMethod,
  generateNumbersPrivateMethod,
  generateReportPrivateMethod,
  getAddOnRulesPublicMethod,
  getAddOnVisibilityPrivateMethod,
  getAddOnVisibilityPublicMethod,
  getBillChannelsPublicMethod,
  getBillingAccountScheduledChangePrivateMethod,
  getBillingAccountsPrivateMethod,
  getChatHistoryPublicMethod,
  getContactsPrivateMethod,
  getCustomerOrderAdditionalInfoPrivateMethod,
  getCustomerOrdersPrivateMethod,
  getDnsRecordHistoryPrivateMethod,
  getDnsRecordsHistoryPrivateMethod,
  getDnsRecordsPrivateMethod,
  getDocumentsPrivateMethod,
  getExternalAuthenticationMethodsPublicMethod,
  getGeneratedReportPrivateMethod,
  getInvoicesPrivateMethod,
  getMyselfAccountPrivateMethod,
  getOnlineModelPublicMethod,
  getOnlineModelsPublicMethod,
  getOpenSupportCasesPrivateMethod,
  getPagePublicMethod,
  getReportsPrivateMethod,
  getSubscriptionActionsPrivateMethod,
  getSubscriptionAggregationsPrivateMethod,
  getSubscriptionsPrivateMethod,
  getSupportCaseHistoryPrivateMethod,
  getSupportCasePrivateMethod,
  getSupportCasesPrivateMethod,
  getTrainingPublicMethod,
  getVirtualCatalogsPrivateMethod,
  globalSearchPrivateMethod,
  handleTurbonappiOrderPublicMethod,
  licenseManagementUrlPrivateMethod,
  postChatConversationPublicMethod,
  postCreateInterworksAccountPrivateMethod,
  postFilePrivateMethod,
  postScheduledBillingAccountChangePrivateMethod,
  putScheduledBillingAccountChangePrivateMethod,
  raiseAuthenticationLevelPrivateMethod,
  raiseAuthenticationLevelValidateOtpPrivateMethod,
  replaceVirtualCatalogsPrivateMethod,
  searchCompaniesPublicMethod,
  updateBillingAccountPrivateMethod,
  updateContactPrivateMethod,
  updateDnsRecordPrivateMethod,
  validateAvailabilityOfNumbersPrivateMethod,
  validateMsisdnForTurbonappiPublicMethod,
} from '../generated/api/uiApiMethods.js';
import { createRedirectURL } from './utils/urlUtils.js';
import { emptyAddOnRulesResponse } from './utils/addOnRulesUtils.js';
import {
  getActiveAccountMasterId,
  getAiChatSessionId,
  isSearchAllAccounts,
} from '../selfservice/common/localStorageUtils.js';
import type {
  AddOnRulesResponse,
  AddOnVisibilityResponse,
  AiChatResponse,
  AuthenticationMethod,
  BillingAccount,
  BillingAccountScheduledChangeResponse,
  BillingAccountScheduledChangeState,
  BillingAccountsResponse,
  Contact,
  ContactsResponse,
  CreateInterworksAccountRequest,
  CustomerOrdersResponse,
  DnsRecordRequest,
  DnsRecordsHistoryResponse,
  DnsRecordsResponse,
  FinalizeOnlineOrderCardPaymentRequest,
  InitializeOnlineOrderCardPaymentResponse,
  InvoiceDocumentsResponse,
  InvoicesResponse,
  NewNumbersResponse,
  OnlineModel,
  OnlineModelsResponse,
  PageResponse,
  PostAnonymousLeadRequest,
  PostFileResponse,
  ReportItemResponse,
  ReportType,
  SubscriptionAggregationsResponse,
  SubscriptionsResponse,
  SupportCase,
  TrainingResponse,
  TurbonappiMsisdnActionResponse,
  TurbonappiMsisdnValidationRequest,
  TurbonappiOrder,
  TurbonappiOrderRequest,
  ValidateAvailabilityOfNumbersRequest,
  ValidateAvailabilityOfNumbersResponse,
  VirtualCatalogReplaceRequest,
  VirtualCatalogReplaceResponse,
} from '../generated/api/models.js';
import type { AddOnVisibilityOperationType } from './enums.js';
import type { ApiMethod, SortableLimitableQuery } from '../generated/api/uiApiMethods.js';
import type { ContactSupportInfo } from '../components/ContactSupportForm/index.js';
import type { DnsRecordsRequestQuery } from '../components/DnsManagement/dnsManagementUtils.js';
import type { LoaderFunctionArgs } from 'react-router-dom';
import type { SubscriptionsDeviceMultiFilter } from '../components/SubscriptionLists/SubscriptionTable.js';

export interface CustomerOrdersQuery extends SortableLimitableQuery {
  search?: string;
  status?: string;
  useSearchService?: boolean;
}

export interface SupportCasesQuery extends SortableLimitableQuery {
  search?: string;
  feature?: string;
  status?: string;
}

export interface VirtualCatalogsQuery extends SortableLimitableQuery {
  search?: string;
  searchAllAccounts?: boolean;
}

export interface BillingAccountsQuery extends SortableLimitableQuery {
  useSearchService?: boolean;
  search?: string;
  sourceSystem?: string;
  billingContactId?: string;
}

export interface SubscriptionActionsQuery extends SortableLimitableQuery {
  search?: string;
}

export interface InvoicesQuery extends SortableLimitableQuery {
  balanceRange?: string;
  search?: string;
  useSearchService?: boolean;
  category?: string;
  openInvoicesOnly?: boolean;
}

export interface InvoiceDocumentsQuery extends SortableLimitableQuery {
  useSearchService?: boolean;
  search?: string;
}

interface CallUiApiOpts {
  body?: BodyInit;
  headers?: HeadersInit;
  mdmId?: string;
  useDefaultMdmId?: boolean;
  noThrow?: boolean;
  jsonContent?: boolean;
}

const callUiApi = async (apiCall: ApiMethod, opts?: CallUiApiOpts) => {
  const { body, useDefaultMdmId = true, noThrow = false, jsonContent = true } = opts ?? {};
  const { path, verb: method } = apiCall;

  const mdmId = opts?.mdmId ?? (useDefaultMdmId ? getActiveAccountMasterId() : undefined);
  const headers = {
    ...opts?.headers,
    ...(jsonContent ? { 'Content-Type': 'application/json' } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    ...(mdmId ? { 'X-API-Account-Master-ID': mdmId } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    ...(mdmId ? { 'X-Elisa-Company-MDM-ID': mdmId } : {}), // eslint-disable-line @typescript-eslint/naming-convention
  };

  const init = { body, method, headers };
  const res = await fetch(path, init);

  if (noThrow || res.ok) {
    return res;
  } else {
    throw res;
  }
};

/**
 * Create only functions in this file that call fetch and use functions from uiApiMethods
 * Do not call these functions inside Epics
 * Do not mix fetch usages with redux usages
 */

export const fetchCompanyList = async (query: string) => {
  return (await (await callUiApi(searchCompaniesPublicMethod({ query }))).json()).result;
};

export const fetchTrainings = async (query: string): Promise<TrainingResponse> => {
  return (await callUiApi(getTrainingPublicMethod(query))).json();
};

export const fetchPublicPage = async (path: string): Promise<PageResponse> => {
  return (await callUiApi(getPagePublicMethod({ path }), { headers: { redirect: 'manual' } })).json();
};

export const fetchPublicPageLoader = async (args: LoaderFunctionArgs): Promise<PageResponse> => {
  const { origin, pathname } = new URL(args.request.url);
  // Decode scandic letters; otherwise, getPagePublicMethod double encodes it
  const apiCall = getPagePublicMethod({ path: decodeURIComponent(pathname) });
  apiCall.path = origin + apiCall.path;
  const res = await callUiApi(apiCall, { headers: { redirect: 'manual' }, useDefaultMdmId: false, noThrow: true });

  if (res.status === 204) {
    throw new Response(res.body, {
      status: 301,
      headers: new Headers({ location: createRedirectURL(args, res) }),
    });
  }
  if (res.ok) {
    return res.json();
  }
  const status = res.status === 503 ? 503 : 404;
  throw new Response(res.body, { status, statusText: res.statusText });
};

export const createAdminUser = (contact: Contact) => {
  return callUiApi(createAdminUserPrivateMethod(contact.contactId!), { body: JSON.stringify(contact) });
};

export const upsertContactUser = (
  contact: Contact,
  forceUpsert: boolean,
  bothNamesChanged?: boolean,
  noThrowOnNewContact?: boolean,
  mdmId?: string
) => {
  if (contact.contactId) {
    const apiCall = updateContactPrivateMethod(contact.contactId, { forceUpsert, bothNamesChanged });
    return callUiApi(apiCall, { body: JSON.stringify(contact), mdmId });
  } else {
    return callUiApi(createContactPrivateMethod({ forceUpsert }), {
      body: JSON.stringify(contact),
      noThrow: noThrowOnNewContact,
      mdmId,
    });
  }
};

export const deleteAdminUser = (contact: Contact) => {
  return callUiApi(deleteAdminUserPrivateMethod(contact.contactId!), { body: JSON.stringify(contact) });
};

export const fetchOnlineModelHeaders = async (productsWithTags: string[]): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPublicMethod({ headersOnly: true, tags: productsWithTags }))).json();
};

export const fetchCatalogOnlineModelHeaders = async (category: string): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPublicMethod({ headersOnly: true, category: category }))).json();
};

export const fetchOnlineModels = async (guids: string[]): Promise<OnlineModel[]> => {
  const responses = await Promise.all(
    guids.map(guid => callUiApi(getOnlineModelPublicMethod(guid), { noThrow: true }))
  );

  const result = await Promise.all(responses.map(r => (r.ok ? r.json() : undefined)));
  return result.filter(r => !!r);
};

export const fetchDomains = async (query: string) => {
  return (await callUiApi(domainGetPublicMethod({ query }))).json();
};

export const fetchDnsRecords = async (subId: string, query: DnsRecordsRequestQuery): Promise<DnsRecordsResponse> => {
  return (await callUiApi(getDnsRecordsPrivateMethod(subId, query))).json();
};

export const fetchDnsRecordsHistory = async (
  subId: string,
  query: DnsRecordsRequestQuery
): Promise<DnsRecordsHistoryResponse> => {
  return (await callUiApi(getDnsRecordsHistoryPrivateMethod(subId, query))).json();
};

export const fetchDnsRecordHistory = async (subId: string, historyId: number): Promise<DnsRecordsHistoryResponse> => {
  return (await callUiApi(getDnsRecordHistoryPrivateMethod(historyId, subId))).json();
};

export const deleteDnsRecord = async (subscriptionId: string, recordId: number) => {
  return callUiApi(deleteDnsRecordPrivateMethod(recordId, subscriptionId));
};

export const updateDnsRecord = async (subscriptionId: string, recordId: number, payload: DnsRecordRequest) => {
  return callUiApi(updateDnsRecordPrivateMethod(recordId, subscriptionId), { body: JSON.stringify(payload) });
};

export const createDnsRecord = async (subscriptionId: string, payload: DnsRecordRequest) => {
  return callUiApi(createDnsRecordPrivateMethod(subscriptionId), { body: JSON.stringify(payload) });
};

export const postAnonymousLead = (body: PostAnonymousLeadRequest) => {
  return callUiApi(createAnonymousLeadPublicMethod(), {
    body: JSON.stringify(body),
    useDefaultMdmId: false,
    noThrow: true,
  });
};

export const fetchAddOnRules = async (subscriptionType: string): Promise<AddOnRulesResponse> => {
  const apiCall = getAddOnRulesPublicMethod({ subscriptionType });
  const res = await callUiApi(apiCall, { useDefaultMdmId: false, noThrow: true });

  if (res.ok) {
    return res.json();
  }

  // If add-on rules cannot be fetched, the add-on cannot be sold but the main article is still potentially available for purchase. By returning an empty response, buying the main article is still possible.
  return { ...emptyAddOnRulesResponse };
};

export const fetchNewSalesAddOnsVisibilities = async (cpCode: string): Promise<AddOnVisibilityResponse> => {
  const apiCall = getAddOnVisibilityPublicMethod(cpCode, { operationType: 'NEW_SALES' });
  const res = await callUiApi(apiCall, { useDefaultMdmId: false, noThrow: true });

  if (res.ok) {
    return res.json();
  }

  // If add-on visibilities cannot be fetched, we cannot decide which add-ons are available for selection. The main article is still potentially available for purchase. By returning an empty response, buying the main article is still possible.
  return {
    total: 0,
    addOnVisibilities: [],
  };
};

export const fetchAddOnsVisibilities = async (
  cpCode: string,
  operationType: AddOnVisibilityOperationType
): Promise<AddOnVisibilityResponse> => {
  return (await callUiApi(getAddOnVisibilityPrivateMethod(cpCode, { operationType: operationType }))).json();
};

export const postTurbonappiValidation = async (
  payload: TurbonappiMsisdnValidationRequest
): Promise<TurbonappiMsisdnActionResponse> => {
  return (await callUiApi(validateMsisdnForTurbonappiPublicMethod(), { body: JSON.stringify(payload) })).json();
};

export const postTurbonappiPaymentInitialization = async (
  payload: TurbonappiOrderRequest
): Promise<InitializeOnlineOrderCardPaymentResponse> => {
  return (await callUiApi(handleTurbonappiOrderPublicMethod(), { body: JSON.stringify(payload) })).json();
};

export const postTurbonappiActivation = async (
  payload: FinalizeOnlineOrderCardPaymentRequest
): Promise<TurbonappiOrder> => {
  return (await callUiApi(activateTurbonappiOrderPublicMethod(), { body: JSON.stringify(payload) })).json();
};

export const postFile = async (file: File): Promise<PostFileResponse> => {
  const body = new FormData();
  body.append('fileData', file);
  return (await callUiApi(postFilePrivateMethod(), { body, jsonContent: false })).json();
};

export const fetchReports = async (): Promise<ReportItemResponse[]> => {
  return (await callUiApi(getReportsPrivateMethod())).json();
};

export const generateReport = (reportType: ReportType) => {
  return callUiApi(generateReportPrivateMethod(reportType), { noThrow: true });
};

export const downloadReport = (reportId: string) => {
  return callUiApi(getGeneratedReportPrivateMethod(reportId), { noThrow: true });
};

export const fetchBillingAccount = async (
  billingAccountDisplayId?: string,
  mdmId?: string
): Promise<BillingAccountsResponse> => {
  const apiCall = getBillingAccountsPrivateMethod({ offset: 0, billingAccountDisplayId });
  return (await callUiApi(apiCall, { useDefaultMdmId: !mdmId, mdmId })).json();
};

export const fetchBillingAccounts = async (
  query?: BillingAccountsQuery,
  mdmId?: string
): Promise<BillingAccountsResponse> => {
  const apiCall = getBillingAccountsPrivateMethod(query);
  return (await callUiApi(apiCall, { useDefaultMdmId: !isSearchAllAccounts(), mdmId })).json();
};

export const replaceCatalogs = async (
  payload: VirtualCatalogReplaceRequest
): Promise<VirtualCatalogReplaceResponse> => {
  return (await callUiApi(replaceVirtualCatalogsPrivateMethod(), { body: JSON.stringify(payload) })).json();
};

export const createBillingAccount = async (billingAccount: BillingAccount, mdmId?: string) => {
  return (await callUiApi(createBillingAccountPrivateMethod(), { body: JSON.stringify(billingAccount), mdmId })).json();
};

export const updateBillingAccount = async (billingAccountId: string, billingAccount: BillingAccount) => {
  return (
    await callUiApi(updateBillingAccountPrivateMethod(billingAccountId), { body: JSON.stringify(billingAccount) })
  ).json();
};

export const fetchBillChannels = async () => {
  return (await callUiApi(getBillChannelsPublicMethod())).json();
};

export interface GlobalSearchQuery {
  query: string;
  searchAllAccounts?: boolean;
  searchType?: string[];
}

export const fetchGlobalSearch = async ({ query, searchAllAccounts, searchType }: GlobalSearchQuery) => {
  const apiCall = globalSearchPrivateMethod({ query, searchType });
  return (await callUiApi(apiCall, { useDefaultMdmId: !searchAllAccounts })).json();
};

export const fetchSubscriptionAggregates = async (
  subscriptionType: string,
  { request }: LoaderFunctionArgs
): Promise<SubscriptionAggregationsResponse> => {
  const params = new URLSearchParams(new URL(request.url).search);
  const search = params.get('search') || undefined;
  const apiCall = getSubscriptionAggregationsPrivateMethod({ subscriptionType, search });
  return (await callUiApi(apiCall, { useDefaultMdmId: !isSearchAllAccounts() })).json();
};

export type SubscriptionsQuery = SortableLimitableQuery &
  SubscriptionsDeviceMultiFilter & {
    details?: boolean;
    search?: string;
    subscriptionType?: string[];
    useSearchService?: boolean;
    subscriptionContactId?: string;
  };

export const fetchSubscription = async (
  subscriptionDisplayId: string,
  mdmId?: string
): Promise<SubscriptionsResponse> => {
  return (
    await callUiApi(getSubscriptionsPrivateMethod({ subscriptionDisplayId }), {
      useDefaultMdmId: !mdmId,
      mdmId,
    })
  ).json();
};

export const fetchSubscriptions = async (query: SubscriptionsQuery): Promise<SubscriptionsResponse> => {
  return (await callUiApi(getSubscriptionsPrivateMethod(query), { useDefaultMdmId: !isSearchAllAccounts() })).json();
};

export const createInterworksRedirectUrl = () => {
  return callUiApi(licenseManagementUrlPrivateMethod());
};

export type ContactFetchQuery = SortableLimitableQuery & {
  search?: string;
  useSearchService?: boolean;
};

export const fetchContacts = async (query: ContactFetchQuery, mdmId?: string): Promise<ContactsResponse> => {
  return (await callUiApi(getContactsPrivateMethod(query), { mdmId, useDefaultMdmId: !isSearchAllAccounts() })).json();
};

export const fetchExternalAuthMethods = async (): Promise<AuthenticationMethod[] | 'failed'> => {
  try {
    return (await (await callUiApi(getExternalAuthenticationMethodsPublicMethod())).json()).authenticationMethods;
  } catch {
    // This is a special case, we want to have graceful degraded functionality. The extAuthMethods come from 3rd party, if that system has issues we don't want it to impact online-ui more than it has to.
    return 'failed';
  }
};

export const fetchContact = async ({ contactMasterId }: { contactMasterId: string }): Promise<ContactsResponse> => {
  return (await callUiApi(getContactsPrivateMethod({ contactMasterId }))).json();
};

export const fetchContactFromES = async ({
  contactMasterId,
}: {
  contactMasterId: string;
}): Promise<ContactsResponse> => {
  return (await callUiApi(getContactsPrivateMethod({ contactMasterId, useSearchService: true }))).json();
};

export const requestMfaOtp = async () => {
  return callUiApi(raiseAuthenticationLevelPrivateMethod());
};

export const sendMfaOtp = async (otp: string): Promise<boolean> => {
  const res = await callUiApi(raiseAuthenticationLevelValidateOtpPrivateMethod(otp), { noThrow: true });

  if (res.ok) {
    return true;
  } else if (res.status === 400) {
    return false;
  }

  throw new Error(res.statusText);
};

export const validateNumbers = async (
  payload: ValidateAvailabilityOfNumbersRequest
): Promise<ValidateAvailabilityOfNumbersResponse> => {
  return (await callUiApi(validateAvailabilityOfNumbersPrivateMethod(), { body: JSON.stringify(payload) })).json();
};

export type GenerateNumbersQuery = {
  count: number;
};

export const generateNumbers = async (query: GenerateNumbersQuery): Promise<NewNumbersResponse> => {
  return (await callUiApi(generateNumbersPrivateMethod(query))).json();
};

export const createSupportCaseAnonymous = async (
  supportCase: SupportCase,
  contactDetails: ContactSupportInfo,
  reCaptchaResponse?: string
) => {
  const body = JSON.stringify({ supportCase, contactDetails, reCaptchaResponse });
  return (await callUiApi(createAnonymousSupportCasePublicMethod(), { body: body })).json();
};

export const createSupportCaseAuthenticated = async (supportCase: SupportCase, mdmId: string) => {
  return (
    await callUiApi(createSupportCasePrivateMethod(), {
      body: JSON.stringify(supportCase),
      mdmId,
      useDefaultMdmId: false,
    })
  ).json();
};

export const fetchSupportCase = async (supportCaseDisplayId: string, mdmId?: string) => {
  const apiCall = getSupportCasePrivateMethod(supportCaseDisplayId);
  return (await callUiApi(apiCall, { useDefaultMdmId: !mdmId, mdmId })).json();
};

export const fetchSupportCases = async (query: SupportCasesQuery) => {
  return (await callUiApi(getSupportCasesPrivateMethod(query), { useDefaultMdmId: !isSearchAllAccounts() })).json();
};

export const fetchSupportCaseHistory = async (supportCaseDisplayId: string, mdmId?: string) => {
  const apiCall = getSupportCaseHistoryPrivateMethod(supportCaseDisplayId);
  return (await callUiApi(apiCall, { mdmId })).json();
};

export const fetchOpenSupportCases = async (query?: SupportCasesQuery, mdmId?: string) => {
  return (
    await callUiApi(getOpenSupportCasesPrivateMethod(query), { useDefaultMdmId: !isSearchAllAccounts(), mdmId })
  ).json();
};

export const fetchCustomerOrder = async (
  customerOrderDisplayId?: string,
  mdmId?: string
): Promise<CustomerOrdersResponse> => {
  return (
    await callUiApi(getCustomerOrdersPrivateMethod({ offset: 0, customerOrderDisplayId }), {
      useDefaultMdmId: !mdmId,
      mdmId,
    })
  ).json();
};

export const fetchCustomerOrders = async (
  query: CustomerOrdersQuery,
  mdmId?: string
): Promise<CustomerOrdersResponse> => {
  return (
    await callUiApi(getCustomerOrdersPrivateMethod(query), {
      useDefaultMdmId: !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const fetchCustomerOrderAdditionalInfo = async (customerOrderId: string) => {
  return (
    await callUiApi(getCustomerOrderAdditionalInfoPrivateMethod(customerOrderId), {
      useDefaultMdmId: !isSearchAllAccounts(),
    })
  ).json();
};

export const fetchBillingAccountScheduledChange = async (
  billingAccountId: string
): Promise<BillingAccountScheduledChangeResponse> => {
  return (await callUiApi(getBillingAccountScheduledChangePrivateMethod(billingAccountId))).json();
};

export const scheduleBillingAccountChange = async (
  billingAccountId: string,
  scheduledChangeTimestamp: number,
  billingAccount: BillingAccount
): Promise<BillingAccountScheduledChangeState> => {
  const apiCall = postScheduledBillingAccountChangePrivateMethod(billingAccountId);
  return (await callUiApi(apiCall, { body: JSON.stringify({ billingAccount, scheduledChangeTimestamp }) })).json();
};

export const reScheduleBillingAccountChange = async (
  billingAccountId: string,
  scheduledChangState: BillingAccountScheduledChangeState
): Promise<BillingAccountScheduledChangeState> => {
  const body: BillingAccountScheduledChangeState = {
    billingAccountId,
    changeRequestId: scheduledChangState.changeRequestId,
    scheduledChangeTimestamp: scheduledChangState.scheduledChangeTimestamp,
  };
  return (
    await callUiApi(putScheduledBillingAccountChangePrivateMethod(billingAccountId), { body: JSON.stringify(body) })
  ).json();
};

export const cancelChangeRequest = async (
  billingAccountId: string,
  changeRequestId: string
): Promise<BillingAccountScheduledChangeState> => {
  const body: BillingAccountScheduledChangeState = {
    billingAccountId,
    changeRequestId,
    changeRequestStatus: ChangeRequestStatus.CANCELLED,
  };
  return (
    await callUiApi(putScheduledBillingAccountChangePrivateMethod(billingAccountId), { body: JSON.stringify(body) })
  ).json();
};

export const fetchSubscriptionAction = async (subscriptionActionDisplayId?: string, mdmId?: string) => {
  const apiCall = getSubscriptionActionsPrivateMethod({ subscriptionActionDisplayId });
  return (await callUiApi(apiCall, { useDefaultMdmId: !mdmId, mdmId })).json();
};

export const fetchSubscriptionActions = async (query: SubscriptionActionsQuery, mdmId?: string) => {
  const apiCall = getSubscriptionActionsPrivateMethod(query);
  return (await callUiApi(apiCall, { useDefaultMdmId: !isSearchAllAccounts(), mdmId })).json();
};

export const fetchVirtualCatalogs = async (query: VirtualCatalogsQuery) => {
  const apiCall = getVirtualCatalogsPrivateMethod(query);
  return (await callUiApi(apiCall, { useDefaultMdmId: !(isSearchAllAccounts() || query.searchAllAccounts) })).json();
};

export const deprecateVirtualCatalog = async (virtualCatalogCode: string, mdmId?: string) => {
  return callUiApi(deprecateVirtualCatalogPrivateMethod(virtualCatalogCode), { mdmId, useDefaultMdmId: false });
};

export const fetchCompanyInfo = async (mdmId?: string) => {
  return (await callUiApi(getMyselfAccountPrivateMethod(), { mdmId, useDefaultMdmId: mdmId !== undefined })).json();
};

export const getMessagesFromChatHistory = async (sessionId: string) => {
  return await callUiApi(getChatHistoryPublicMethod({ sessionId }));
};

export const postMessageToChatConversation = async (message: string): Promise<AiChatResponse> => {
  return (
    await callUiApi(postChatConversationPublicMethod(), {
      body: JSON.stringify({ message, sessionId: getAiChatSessionId() || '' }),
    })
  ).json();
};

export const createInterworksAccount = (request: CreateInterworksAccountRequest) => {
  return callUiApi(postCreateInterworksAccountPrivateMethod(), {
    body: JSON.stringify(request),
  });
};

export const fetchInvoice = async (invoiceDisplayId?: string, mdmId?: string): Promise<InvoicesResponse> => {
  return (
    await callUiApi(getInvoicesPrivateMethod({ offset: 0, invoiceDisplayId }), {
      useDefaultMdmId: !mdmId,
      mdmId,
    })
  ).json();
};

export const fetchInvoices = async (query?: InvoicesQuery, mdmId?: string): Promise<InvoicesResponse> => {
  return (
    await callUiApi(getInvoicesPrivateMethod(query), {
      useDefaultMdmId: !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const fetchInvoiceDocuments = async (query?: InvoiceDocumentsQuery): Promise<InvoiceDocumentsResponse> => {
  return (await callUiApi(getDocumentsPrivateMethod(query), { useDefaultMdmId: !isSearchAllAccounts() })).json();
};

export const changeSubscriptionBillingAccount = async (
  subscriptionId: string,
  billingAccountId: string,
  mdmId?: string
) => {
  const payload = { billingAccountId };
  return (
    await callUiApi(changeSubscriptionBillingAccountPrivateMethod(subscriptionId), {
      body: JSON.stringify(payload),
      mdmId,
      useDefaultMdmId: !mdmId,
    })
  ).json();
};
