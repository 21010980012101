import { GridNarrow } from '../../../components/Grid/Grid.js';
import { LoginAndRegistration } from '../../../components/LoginAndRegistration/LoginAndRegistration.js';
import { ShoppingCartWithVoucher } from '../../../components/ShoppingCartWithVoucher/ShoppingCartWithVoucher.js';
import { ShoppingCartWithVoucherCompanySelector } from '../../../components/ShoppingCartWithVoucher/ShoppingCartWithVoucherCompanySelector.js';
import { Spinner } from '../siteUtils.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { getUserAccounts } from '../../../components/Header/dynamic/headerFunctions.js';
import { isCompanyInfoLoading } from '../../../common/utils/stateUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useAuth } from '../AuthProvider.js';
import { useEffect } from 'react';
import { useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useShoppingCart } from '../../../common/hooks/useShoppingCart.js';
import type { OnlineModel } from '../../../generated/api/onlineModel.js';
import type { OnlineModelsResponse } from '../../../generated/api/onlineModelsResponse.js';
import type { State } from '../../../selfservice/common/store.js';

export const ShoppingCartWithVoucherPath = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const loginInProgress = location.state?.loginInProgress === true;
  const { isAuthenticated, authenticatedUser } = useAuth();
  const loaderData = useRouteLoaderData('newShoppingCart') as {
    onlineModels: OnlineModel[];
    onlineModelsHeadersForSalesProducts: OnlineModelsResponse;
  };

  const { cartItems, companyInfo } = useSelector((state: State) => {
    return {
      cartItems: state.deviceCheckout?.cartItems || [],
      companyInfo: state.selfservice?.companyInfo,
    };
  }, deepEqual);

  const userAccounts = getUserAccounts(authenticatedUser);

  const isLoading = authenticatedUser?.isFetching || isCompanyInfoLoading(companyInfo);

  useEffect(() => {
    if (!isLoading && loginInProgress && userAccounts.length === 1) {
      // If there is no need for user to select company first: reset state
      navigate(paths.NEW_SHOPPING_CART, { replace: true });
    }
  }, [loginInProgress, isLoading, navigate, userAccounts.length]);

  // Loads cart items from localstorage to state deviceCheckout.cartItems
  useShoppingCart();

  if (isLoading) {
    return <Spinner />;
  }

  const loggedIn = isAuthenticated;

  return !loggedIn && loginInProgress ? (
    <GridNarrow>
      <LoginAndRegistration redirectTargetAfterRegisterUrl={paths.NEW_SHOPPING_CART} />
    </GridNarrow>
  ) : (
    <div>
      {loggedIn && userAccounts.length > 1 && (
        <ShoppingCartWithVoucherCompanySelector userAccounts={userAccounts} showInDialog={loginInProgress} />
      )}
      <ShoppingCartWithVoucher
        cartItems={cartItems}
        discountedPrices={companyInfo?.discountedPrices || []}
        models={loaderData.onlineModels}
        onlineModelsHeadersForSalesProducts={loaderData.onlineModelsHeadersForSalesProducts}
        loggedIn={loggedIn}
      />
    </div>
  );
};
