import { CatalogProductsSelection } from '../../../../../components/CatalogProductsSelection/CatalogProductsSelection.js';
import { Loading } from '../../../../../components/Loading/index.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { mapLoaderDataToCatalogProducts } from '../../../../../components/CatalogProductsSelection/CatalogProductSelectionUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useEffect } from 'react';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';
import type { CatalogParams } from '../../../../../components/CatalogConfiguration/CatalogConfiguration.js';
import type { CatalogProduct } from '../../../../../common/utils/catalogUtils.js';
import type { OnlineModelLoaderData } from '../../../../../common/loaders.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoCatalogListEditPath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const config = useStore<State>().getState().config;
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const onlineModels = useLoaderData() as OnlineModelLoaderData;

  useEffect(() => {
    if (!location.state) {
      navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
    }
  }, [location.state, navigate]);

  if (!companyInfo?.discountedPrices) {
    return <Loading />;
  }

  return (
    <CatalogProductsSelection
      catalogProducts={mapLoaderDataToCatalogProducts(
        onlineModels,
        location.state.catalog,
        companyInfo.discountedPrices
      )}
      onSetProductsForCatalog={(catalogProducts: Record<string, CatalogProduct[]>) => {
        const catalogParams: CatalogParams = { ...location.state, products: catalogProducts };
        navigate(paths.COMPANY_INFO_CATALOG_LIST_INFO, { state: catalogParams });
      }}
      preSelectedProducts={location.state.products}
      contractPeriod={location.state.catalog.contractPeriod}
      imagesBaseUrl={config.imagesBaseUrl}
    />
  );
};
