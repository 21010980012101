import * as CL from '@design-system/component-library';
import * as React from 'react';
import { DEFAULT_QUANTITY, PRODUCT_DETAILS_CLASS } from './productDetailsConstants.js';
import { JsonLd } from 'react-schemaorg';
import { Loading } from '../Loading/index.js';
import { ProductDetailsAddOn } from './partials/ProductDetailsAddOn.js';
import { ProductDetailsAvailability } from './partials/ProductDetailsAvailability.js';
import { ProductDetailsDescription } from './partials/ProductDetailsDescription.js';
import { ProductDetailsDiscontinued } from './partials/ProductDetailsDiscontinued.js';
import { ProductDetailsOffer } from './partials/ProductDetailsOffer.js';
import { ProductDetailsPayment } from './partials/ProductDetailsPayment.js';
import { ProductDetailsPicture } from './partials/ProductDetailsPicture.js';
import { ProductDetailsPriceDisclaimer } from './partials/ProductDetailsPriceDisclaimer.js';
import { ProductDetailsQuantity } from './partials/ProductDetailsQuantity.js';
import { ProductDetailsSummary } from './partials/ProductDetailsSummary.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import {
  assemblePictures,
  calculatePayments,
  filterAndSortOffersByAvailability,
  filterAndSortOffersByName,
  getAddOnsAndDefaultValues,
  getDefaultPaymentTerm,
  isCommercialProductPaymentAllowed,
  onClickAddToCart,
  useProcessOnlineModel,
  valueOrZero,
} from './utils/productDetailsUtils.js';
import { createProductStructuredDataFromOnlineModel } from '../../common/structured-data/product.js';
import { isSquareTradeAddOn } from '../../common/utils/addOnUtils.js';
import { t } from '../../common/i18n/index.js';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { userHasEmptyOrSMEPriceGroup } from '../../common/utils/employeeUtils.js';
import type {
  AddOn,
  AddOnPurpose,
  Catalog,
  CommercialProduct,
  Offer,
  OnlineModel,
} from '../../generated/api/models.js';
import type { AdditionalField } from '../../selfservice/common/shopping-cart/shoppingCartFunctions.js';
import type { AuthenticatedUserState, CompanyInfoState } from '../../common/types/states.js';
import type { ECommerceEvents } from '../PublicPage/types.js';
import type { PictureData } from './partials/ProductDetailsPicture.js';
import type { Product } from 'schema-dts';
import type { ProductDetailsAddOnStateProperties } from './partials/ProductDetailsAddOn.js';
import type { ProductDetailsDescriptionProps } from './partials/ProductDetailsDescription.js';

import './ProductDetails.scss';

export interface ProductDetailsProps extends ProductDetailsPropsWithShoppingCart {
  showOfferAndPrices: boolean;
}

export interface ProductDetailsPropsWithShoppingCart extends ProductDetailsDescriptionProps {
  addToCart: (cartItemContainer: CartItemContainer) => void;
  eCommerceEvents?: ECommerceEvents;
  isPublicSite: boolean;
  isEmployee: boolean;
  companyInfo?: CompanyInfoState;
  catalog?: Catalog;
  user?: AuthenticatedUserState;
}

export interface ProductDetailsComponentProps extends ProductDetailsProps {
  addOns: AddOn[];
  offers: Offer[];
  pictures: PictureData[];
  isYtt?: boolean;
}

export interface ProductDetailsComponentRightSideProps extends ProductDetailsComponentProps {
  offer: Offer;
  setOffer: (offer: Offer) => void;
}

export interface ProductDetailsPropsComponentBottomRightSide extends ProductDetailsPropsWithShoppingCart {
  commercialProduct: CommercialProduct;
  productQuantity: number;
  offer: Offer;
  addOns: AddOn[];
}

export interface CartItemContainer {
  onlineModel: OnlineModel;
  offer: Offer;
  commercialProduct: CommercialProduct;
  quantity: number;
  isPublicSite: boolean;
  selectedAddOnBundles: SelectedAddOnBundle[];
  additionalFields: AdditionalField[];
  productPath: string;
}

export interface SelectedAddOnBundle {
  addOnAssociationCode: string;
  addOnAssociationId: string;
  addOnPurpose: AddOnPurpose;
  display: boolean;
  addOnCode: string;
  count: number;
  isMandatory: boolean;
}

const YTT_DEFAULT_SELECTED_OFFER_NAME = 'Elisa Yritystietoturva 3 laitteeseen 12 kk laskutusväli';

const ProductDetailsComponentBottomRightSide = (props: ProductDetailsPropsComponentBottomRightSide) => {
  const { isEmployee, commercialProduct, companyInfo, catalog, productQuantity, offer, addOns, user } = props;
  const { pathname } = useLocation();
  const [addOnsState, setAddOnsState] = useState<ProductDetailsAddOnStateProperties[]>(
    getAddOnsAndDefaultValues(
      addOns,
      commercialProduct,
      isEmployee ? catalog?.damageInsurance : companyInfo?.eppSolution?.damageInsuranceDeviceCategories,
      isEmployee,
      productQuantity,
      offer.eppCategory,
      user
    )
  );

  useEffect(() => {
    setAddOnsState(previousAddOns =>
      previousAddOns.map(addOn => ({
        ...addOn,
        quantity: productQuantity,
      }))
    );
  }, [productQuantity]);

  return (
    <>
      <ProductDetailsAddOn addOns={addOnsState} setStatus={setAddOnsState} productQuantity={productQuantity} />
      <ProductDetailsSummary
        commercialProduct={commercialProduct}
        offer={offer}
        addToCart={() => onClickAddToCart(props, addOnsState, decodeURI(pathname))}
        quantity={productQuantity}
        isEmployee={isEmployee}
        addOns={addOnsState}
      />
      {isEmployee ? <ProductDetailsPriceDisclaimer /> : null}
    </>
  );
};

const ProductDetailsComponentRightSide = ({
  onlineModel,
  offers,
  addToCart,
  isPublicSite,
  isEmployee,
  companyInfo,
  catalog,
  addOns,
  offer,
  setOffer,
  user,
}: ProductDetailsComponentRightSideProps) => {
  const [quantity, setQuantity] = useState(DEFAULT_QUANTITY);
  // Any other group than SME means that recurring charges for non-EPP are disabled if not explicitly allowed
  const commercialProducts = offer.commercialProducts.filter(cp =>
    isCommercialProductPaymentAllowed(cp, user?.segmentPricingGroup, companyInfo?.eppSolution)
  );
  const [commercialProduct, setCommercialProduct] = useState<CommercialProduct>(
    commercialProducts.find(product => product.payments === getDefaultPaymentTerm(onlineModel.category)) ||
      commercialProducts[0]
  );
  const [paymentMethod, setPaymentMethod] = useState(commercialProduct?.payments ?? 0);
  const [subType, setSubType] = useState(commercialProduct?.productSubType ?? '');

  const payments = calculatePayments(
    commercialProduct,
    offer,
    quantity,
    getAddOnsAndDefaultValues(
      addOns,
      commercialProduct,
      companyInfo?.eppSolution?.damageInsuranceDeviceCategories || catalog?.damageInsurance,
      isEmployee,
      quantity,
      offer.eppCategory
    ),
    isEmployee
  );
  const filteredAddons = userHasEmptyOrSMEPriceGroup(user?.segmentPricingGroup)
    ? addOns
    : addOns.filter(addon => !isSquareTradeAddOn(addon));
  useEffect(() => {
    const selectedProduct =
      commercialProducts.find(
        p =>
          paymentMethod === p.payments &&
          p.productSubType === subType &&
          p.monthlyRecurringCharge === commercialProduct.monthlyRecurringCharge &&
          p.oneTimeCharge === commercialProduct.oneTimeCharge
      ) || commercialProducts[0];
    setPaymentMethod(selectedProduct.payments || getDefaultPaymentTerm(onlineModel.category));
    setSubType(selectedProduct?.productSubType ?? '');
    setCommercialProduct(selectedProduct);
  }, [commercialProducts]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ProductDetailsOffer
        offers={offers}
        offer={offer}
        setOffer={setOffer}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        onlineModelCategory={onlineModel.category}
      />
      {isEmployee && (
        <div className="ds-margin-top--2 ds-margin-bottom--5">
          {valueOrZero(payments.monthlyRecurringCharge) === 0 && valueOrZero(payments.oneTimeCharge) === 0 ? (
            <span className="ds-display--flex ds-align-items--center">
              <span className="ds-margin-top--1">
                <CL.Icon icon="check" size="m" type="filled" color="white--dark" />
              </span>
              <span className="ds-margin-left--2"> {t.E12Y('Your employer entirely pays for this device')}</span>
            </span>
          ) : (
            <span className="ds-display--flex">
              <span className="ds-margin-top--1">
                {' '}
                <CL.Icon icon="information" size="m" type="regular" color="white--dark" />
              </span>
              <span className="ds-margin-left--2">
                {t.EYS6(
                  'Your employer does not entirely pay for this device, but you can order it by paying the presented monthly charge yourself.'
                )}
              </span>
            </span>
          )}
        </div>
      )}
      {!(isEmployee && commercialProducts.length < 2) && (
        <ProductDetailsPayment
          commercialProducts={commercialProducts}
          commercialProduct={commercialProduct}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          subType={subType}
          setSubType={setSubType}
          quantity={quantity}
          setCommercialProduct={setCommercialProduct}
          onlineModelCategory={onlineModel.category}
          discountedPrices={companyInfo?.discountedPrices?.find(price => price.model === onlineModel.onlineModelCode)}
          eppSolution={companyInfo?.eppSolution}
          segmentPricingGroup={user?.segmentPricingGroup}
          isEmployee={isEmployee}
        />
      )}
      <ProductDetailsQuantity quantity={quantity} setQuantity={setQuantity} />
      <div className="ds-margin-bottom--5">
        <ProductDetailsAvailability offer={offer} />
      </div>
      <ProductDetailsComponentBottomRightSide
        key={`${commercialProduct.commercialProductId}-${commercialProduct.commercialProductName}`}
        commercialProduct={commercialProduct}
        productQuantity={quantity}
        offer={offer}
        addToCart={addToCart}
        isPublicSite={isPublicSite}
        isEmployee={isEmployee}
        onlineModel={onlineModel}
        companyInfo={companyInfo}
        catalog={catalog}
        addOns={filteredAddons}
        user={user}
      />
    </>
  );
};

const PricingNotAvailable = (props: { discountedPricesLoading?: boolean }) => {
  if (props.discountedPricesLoading) {
    return <Loading />;
  }
  return null;
};

function getYttDefaultOffer(offers: Offer[]) {
  return offers.find(o => o.offerName.toUpperCase().trim() === YTT_DEFAULT_SELECTED_OFFER_NAME.toUpperCase().trim());
}

export const ProductDetailsComponent = (props: ProductDetailsComponentProps) => {
  const {
    companyInfo,
    eCommerceEvents,
    isPublicSite,
    offers,
    onlineModel,
    pictures,
    showOfferAndPrices,
    isYtt = false,
  } = props;
  const { siteBaseUrl } = useContext(SiteContext);

  // For YTT, we want certain offer as default if available
  const defaultOffer = isYtt ? getYttDefaultOffer(offers) || offers[0] : offers[0];
  const [offer, setOffer] = useState<Offer>(defaultOffer);

  React.useEffect(() => {
    if (eCommerceEvents?.onSeeProduct && offers.length) {
      eCommerceEvents?.onSeeProduct([onlineModel], [offers]);
    }
  }, []); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  // Sets the offer when it updates in props, without this offer stays as put in useState
  // Fixes OFI-33815 EPP prices not showing.
  React.useEffect(() => {
    setOffer(defaultOffer);
  }, [defaultOffer]);

  const eppSolutionStatus = companyInfo?.eppSolution?.eppSolutionStatus || '';
  const offersWithCommercialProducts = props.onlineModel.offers.length > 0;
  const isAvailable = Boolean(offers.length);

  return (
    <div className={PRODUCT_DETAILS_CLASS}>
      <CL.Grid>
        <CL.GridRow>
          <CL.GridCol colWidthL={7} colWidthM={6} colWidthS={6} colWidthXS={4}>
            <ProductDetailsPicture pictures={pictures} offer={offer} />
          </CL.GridCol>
          <CL.GridCol colWidthL={5} colWidthM={6} colWidthS={6} colWidthXS={4}>
            <ProductDetailsDescription
              onlineModel={onlineModel}
              isPublicSite={isPublicSite}
              isAvailable={isAvailable}
            />
            <ProductDetailsDiscontinued
              isAvailable={isAvailable}
              eppSolutionStatus={eppSolutionStatus}
              offersWithCommercialProducts={offersWithCommercialProducts}
            />
            {isAvailable &&
              (showOfferAndPrices ? (
                <ProductDetailsComponentRightSide {...props} {...{ offer, setOffer }} />
              ) : (
                <PricingNotAvailable discountedPricesLoading={props.companyInfo?.discountedPricesLoading} />
              ))}
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
      {onlineModel.offers.length > 0 && (
        <JsonLd<Product> item={createProductStructuredDataFromOnlineModel(onlineModel, siteBaseUrl)} />
      )}
    </div>
  );
};

export const ProductDetails = (props: ProductDetailsProps) => {
  const processedOnlineModel = useProcessOnlineModel(props.onlineModel, props.companyInfo, props.user, props.catalog);

  const isYtt = processedOnlineModel.onlineModelName === 'Elisa Yritystietoturva';

  const offers = useMemo(
    () =>
      isYtt
        ? filterAndSortOffersByName(processedOnlineModel.offers)
        : filterAndSortOffersByAvailability(processedOnlineModel.offers),
    [processedOnlineModel, isYtt]
  );

  const pictures = assemblePictures(offers, props.onlineModel);
  return (
    <ProductDetailsComponent
      {...props}
      addOns={processedOnlineModel.addOns || []}
      offers={offers}
      pictures={pictures}
      isYtt={isYtt}
    />
  );
};
