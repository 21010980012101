import { BillingAccountDetails } from '../../../../../components/BillingAccountDetails/BillingAccountDetails.js';
import { InvoiceSystemError } from '../../../../../components/Invoices/Invoices.js';
import { useLoaderData } from 'react-router-dom';
import type { BillingAccountLoaderResponse } from '../../../../../common/loaders.js';

export const BillingAccountPath = () => {
  const { billingAccount, billChannels, contacts } = useLoaderData() as BillingAccountLoaderResponse;

  return (
    <InvoiceSystemError>
      <BillingAccountDetails billingAccount={billingAccount} billChannels={billChannels} contacts={contacts} />
    </InvoiceSystemError>
  );
};
