import * as CL from '@design-system/component-library';
import { isDefined } from '../../common/utils/objectUtils.js';
import { t } from '../../common/i18n/index.js';
import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';

import './CheckboxTree.scss';

export interface CheckboxTreeProps {
  data: CheckboxTreeItem[];
  onChange: (selectedValues: string[]) => void;
}

export interface CheckboxTreeItem {
  label: ReactNode;
  value: string;
  children?: CheckboxTreeItem[];
}

interface TreeItemProps extends CheckboxTreeItem {
  selectedValues: Set<string>;
  onChange: (item: TreeItemProps, checked: boolean) => void;
  level: number;
  sibling: number;
}

export type TreeItemState = 'UNCHECKED' | 'CHECKED' | 'INDETERMINATE';

export const TreeItemStateEnum = {
  UNCHECKED: 'UNCHECKED' as TreeItemState,
  CHECKED: 'CHECKED' as TreeItemState,
  INDETERMINATE: 'INDETERMINATE' as TreeItemState,
};

export const getItemStatus = (
  selectedValues: Set<string>,
  value?: string,
  children?: CheckboxTreeItem[]
): TreeItemState => {
  // Node with children
  if (children) {
    const unselectedChildren = children.filter(child => {
      const itemStatus = getItemStatus(selectedValues, child.value, child.children);
      return itemStatus !== TreeItemStateEnum.CHECKED;
    });

    const indeterminateChildren = children.filter(child => {
      const itemStatus = getItemStatus(selectedValues, child.value, child.children);
      return itemStatus === TreeItemStateEnum.INDETERMINATE;
    });

    if (unselectedChildren.length === 0) {
      return TreeItemStateEnum.CHECKED;
    } else if (unselectedChildren.length !== children.length || indeterminateChildren.length > 0) {
      return TreeItemStateEnum.INDETERMINATE;
    }

    return TreeItemStateEnum.UNCHECKED;
  }

  // Node without children
  if (value) {
    return selectedValues.has(value) ? TreeItemStateEnum.CHECKED : TreeItemStateEnum.UNCHECKED;
  }

  return TreeItemStateEnum.UNCHECKED;
};

const TreeItemCheckbox = ({
  label,
  value,
  item,
  itemStatus,
  onChange,
}: {
  label: ReactNode;
  value?: string;
  item: TreeItemProps;
  itemStatus: TreeItemState;
  onChange: (item: TreeItemProps, checked: boolean) => void;
}) => {
  const checkRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkRef.current) {
      checkRef.current.checked = itemStatus === TreeItemStateEnum.CHECKED;
      checkRef.current.indeterminate = itemStatus === TreeItemStateEnum.INDETERMINATE;
    }
  }, [checkRef, itemStatus]);

  return (
    <label className="ds-checkbox">
      <input
        type="checkbox"
        ref={checkRef}
        value={value}
        onChange={() => onChange(item, !(itemStatus === TreeItemStateEnum.CHECKED))}
      />
      <label className="ds-checkbox__label">
        {itemStatus === TreeItemStateEnum.CHECKED ? (
          <CL.Icon key="check" icon="check" type="filled" className="ds-checkbox__icon" />
        ) : (
          <CL.Icon key="remove" icon="remove" type="filled" className="ds-checkbox__icon" />
        )}
        {label}
      </label>
    </label>
  );
};

const TreeItem = (item: TreeItemProps) => {
  const { label, value, children, onChange, selectedValues, level } = item;
  const itemStatus = getItemStatus(selectedValues, value, children);

  const marginClass = level === 0 ? '' : 'ds-margin-horizontal--4';
  return (
    <div className={marginClass}>
      <TreeItemCheckbox item={item} value={value} onChange={onChange} label={label} itemStatus={itemStatus} />
      {children?.map((child, index) => (
        <TreeItem
          level={level + 1}
          sibling={index}
          key={`${level}_${child.value}`}
          selectedValues={selectedValues}
          label={child.label}
          value={child.value}
          onChange={onChange}
        >
          {child.children}
        </TreeItem>
      ))}
    </div>
  );
};

const getAllChildValues = (children: CheckboxTreeItem[]): string[] =>
  children
    .map(child => {
      if (child.children) {
        return getAllChildValues(child.children);
      }
      return child.value;
    })
    .flat(1)
    .filter(isDefined);

export const CheckboxTree = ({ data, onChange }: CheckboxTreeProps) => {
  const [selectedValues, setSelectedValues] = useState(new Set<string>());

  const handleChange = (item: TreeItemProps, checked: boolean) => {
    const updatedSelectedValues = new Set(selectedValues);

    if (item.children) {
      getAllChildValues(item.children).map(value =>
        checked ? updatedSelectedValues.add(value) : updatedSelectedValues.delete(value)
      );
    } else if (item.value) {
      if (checked) {
        updatedSelectedValues.add(item.value);
      } else {
        updatedSelectedValues.delete(item.value);
      }
    }
    onChange(Array.from(Array.from(updatedSelectedValues)));
    setSelectedValues(updatedSelectedValues);
  };

  return (
    <div>
      <TreeItem
        label={t.A8VA('Select all')}
        selectedValues={selectedValues}
        onChange={handleChange}
        level={0}
        sibling={0}
        value="select_all"
      >
        {data}
      </TreeItem>
    </div>
  );
};
