import { ContactRole, SubscriptionType } from '../../generated/api/models.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import {
  companyDetailsMsg,
  deviceListsMsg,
  myAccountMsg,
  omaElisaForCompaniesMsg,
  reportsMsg,
  ringAdministratorsMsg,
  t,
  usersMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetErrors } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { AccountKeyUser, Subscription } from '../../generated/api/models.js';
import type {
  AccountKeyUsersState,
  CompanyInfoState,
  ContactsState,
  SubscriptionsState,
} from '../../common/types/states.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { LocalNaviItem } from '../LocalNavi/index.js';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

export interface SubscriptionStateCategories {
  broadband?: SubscriptionsState | null;
  device?: SubscriptionsState | null;
  service?: SubscriptionsState | null;
  voice?: SubscriptionsState | null;
}

export enum CompanyInfoSceneTab {
  BASIC = 'BASIC',
  CATALOGS = 'CATALOGS',
  CONTACTS = 'CONTACTS',
  REPORTS = 'REPORTS',
  USERS_NOE = 'USERS_NOE',
  MY_ACCOUNT = 'MY_ACCOUNT',
  INVITES = 'INVITES',
}

interface CompanyInfoContainerProps {
  children: ReactNode;
  isSingular?: boolean;
  tab: CompanyInfoSceneTab;
}

export const findFromPbxSolutionsByType = (
  companyInfoState: CompanyInfoState,
  subscriptionType: SubscriptionType
): Subscription[] | undefined => {
  return companyInfoState && companyInfoState.pbxSolutions
    ? companyInfoState.pbxSolutions.filter(item => item.subscriptionType === subscriptionType)
    : undefined;
};

export const filterAccountKeyUsers = (
  accountKeyUserContacts?: ContactsState,
  accountKeyUsersToFilter?: AccountKeyUsersState
): AccountKeyUser[] | undefined => {
  const adminContacts = accountKeyUserContacts?.items?.filter(item =>
    item.person?.roles?.includes(ContactRole.MOBILE_PBX_ADMIN)
  );
  const adminContactUserNames = adminContacts?.map(item => item.person?.userName);
  return accountKeyUsersToFilter?.items?.filter(item => !adminContactUserNames?.includes(item.userName));
};

const CompanyInfoLocalNavi = () => {
  const config = useStore<State>().getState().config;
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  let ringSolutionSubscriptions: Subscription[] | undefined;
  if (companyInfo) {
    ringSolutionSubscriptions = findFromPbxSolutionsByType(companyInfo, SubscriptionType.MOBILE_PBX);
  }

  const categories = [
    {
      children: t.GR1Y('Company details and settings'),
      to: paths.COMPANY_INFO_HOME,
    },
    {
      children: t.BE4A(usersMsg),
      to: paths.COMPANY_INFO_CONTACTS,
    },
    ringSolutionSubscriptions &&
      ringSolutionSubscriptions.length > 0 && {
        children: t.PFI5(ringAdministratorsMsg),
        to: paths.COMPANY_INFO_RING_ADMINS,
      },
    {
      children: t.HFD9('Invites'),
      to: paths.COMPANY_INFO_INVITES,
    },
    {
      children: t.COBB(deviceListsMsg),
      to: paths.COMPANY_INFO_CATALOGS,
    },
    {
      children: t.Y36X(reportsMsg),
      to: paths.COMPANY_INFO_REPORTS,
    },
    {
      children: t.SEBD(myAccountMsg),
      to: `${config.classicSiteUrl}/omat-tiedot`,
      target: '_blank',
    },
  ].filter(i => i) as LocalNaviItem[];
  return <LocalNavi categories={categories} />;
};

const useCompanyInfoErrors = () => {
  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);
  const onboardingRequests = useSelector((state: State) => state.selfservice?.onboardingRequests, deepEqual);
  const catalogs = useSelector((state: State) => state.selfservice?.catalogs, deepEqual);
  const virtualCatalogs = useSelector((state: State) => state.selfservice?.virtualCatalogs, deepEqual);
  const contacts = useSelector((state: State) => state.selfservice?.contacts, deepEqual);
  const customerOrders = useSelector((state: State) => state.selfservice?.customerOrders, deepEqual);
  const subscriptionActions = useSelector((state: State) => state.selfservice?.pendingSubscriptionActions, deepEqual);
  const errors =
    (billingAccounts && billingAccounts.errors) ||
    (catalogs && catalogs.errors) ||
    (virtualCatalogs && virtualCatalogs.errors) ||
    (subscriptionActions && subscriptionActions.errors) ||
    (customerOrders && customerOrders?.errors) ||
    (contacts && contacts.errors) ||
    (onboardingRequests && onboardingRequests.errors);
  const contactUpsertFailed =
    contacts?.errors?.filter(error => error.message === 'Failed to create duplicate contact').length !== 0;
  return hasSystemError(errors) && !contactUpsertFailed ? errors : null;
};

const getBreadCrumbPaths = (tab?: CompanyInfoSceneTab): BreadCrumbList | undefined => {
  switch (tab) {
    case CompanyInfoSceneTab.BASIC:
      return [
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.EHOL(companyDetailsMsg) },
      ];
    case CompanyInfoSceneTab.CATALOGS:
      return [
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.COBB(deviceListsMsg) },
      ];
    case CompanyInfoSceneTab.CONTACTS:
      return [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: t.BE4A(usersMsg) }];
    case CompanyInfoSceneTab.INVITES:
      return [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: t.HFD9('Invites') }];
    case CompanyInfoSceneTab.REPORTS:
      return [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: t.Y36X(reportsMsg) }];
    case CompanyInfoSceneTab.USERS_NOE:
      return [
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.PFI5(ringAdministratorsMsg) },
      ];
    default:
      return;
  }
};

export const CompanyInfoContainer = ({ children, isSingular = false, tab }: CompanyInfoContainerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errors = useCompanyInfoErrors();

  // Don't show generic "Oops" error page when contact creation fails due to duplicate contact
  // as this is handled in the dialog presented to the user
  if (errors) {
    return (
      <SystemError
        errors={errors}
        onButtonClick={() => {
          navigate(paths.SELF_SERVICE_HOME);
          dispatch(resetErrors());
        }}
      />
    );
  }

  if (isSingular) {
    return <>{children}</>;
  }

  return (
    <div className="of-company-info-scene">
      <HeroHeading
        breadCrumbPaths={getBreadCrumbPaths(tab)}
        heroHeadingType={HeroHeadingType.COMPANY_INFO}
        title={t.L7QB('Company Details')}
      />
      <CompanyInfoLocalNavi />
      {children}
    </div>
  );
};
