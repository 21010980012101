import { FormProvider, useForm } from 'react-hook-form';
import { SupportedModelCategories } from '../../common/utils/catalogUtils.js';
import React from 'react';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';

export interface FormWrapperProps {
  children: JSX.Element;
  preSelected?: string[];
  onSubmit: (data: DeviceSelection, e?: React.BaseSyntheticEvent) => void;
  products: Record<string, CatalogProduct[]>;
}

export interface DeviceSelection {
  devices: Record<string, DeviceSelectionFormValues>;
}

export type DeviceSelectionFormValues = {
  code: string;
  selected: boolean;
};

const getDevices = (prods: CatalogProduct[], preSelected?: string[]) => {
  return (
    prods.map(device => {
      return {
        index: device.code,
        value: {
          code: device.code,
          selected: preSelected?.includes(device.code),
        },
      };
    }) || []
  );
};

const toFormValues = (catalogProducts: Record<string, CatalogProduct[]>, preSelected?: string[]) => {
  return SupportedModelCategories.flatMap(cat => getDevices(catalogProducts[cat], preSelected))
    .filter(c => c !== undefined)
    .reduce((a, v) => ({ ...a, [v.index]: v.value }), {});
};

export const CatalogProductSelectionFormWrapper = ({ children, preSelected, onSubmit, products }: FormWrapperProps) => {
  const methods = useForm({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: {
      devices: toFormValues(products, preSelected),
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};
