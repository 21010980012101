import {
  AuthenticatedUserRole,
  Catalog,
  CommercialProductSubType,
  CommercialProductType,
  EppCategory,
  EppSolutionResponse,
  OnlineModelCategory,
} from '../../../generated/api/models.js';
import { VAT_MULTIPLIER, getVatAsString } from '../../../common/utils/taxUtils.js';
import {
  checkCpEligibilityForEmployee,
  filterOffersAndCommercialProductsForEmployee,
  isEmployeeUser,
} from './productDetailsEmployeeUtils.js';
import { isSquareTradeAddOn } from '../../../common/utils/addOnUtils.js';
import { pictureStorageBaseUrl } from '../../Picture/contentfulImageProxy.js';
import { useMemo } from 'react';
import { userHasEmptyOrSMEPriceGroup } from '../../../common/utils/employeeUtils.js';
import type {
  AddOn,
  AddOnAssociation,
  CampaignAssociation,
  CampaignPromotion,
  CommercialProduct,
  DiscountedPriceItem,
  DiscountedPrices,
  Offer,
  OfferEppCategory,
  OnlineModel,
  Price,
} from '../../../generated/api/models.js';
import type { AuthenticatedUserState, CompanyInfoState } from '../../../common/types/states.js';
import type { PictureData } from '../partials/ProductDetailsPicture.js';
import type { ProductDetailsAddOnStateProperties } from '../partials/ProductDetailsAddOn.js';
import type { ProductDetailsPropsComponentBottomRightSide, SelectedAddOnBundle } from '../ProductDetails.js';

const DAMAGE_INSURANCE_ADDON_GROUP = 'Vahinkopalvelu';

const fromOffers = (offers: Offer[]): PictureData[] => {
  // NOTE: this supports old images starting with static.elisa and new with full path. First can be removed after product variant migration
  const pictures = new Map<string, PictureData>();
  offers.forEach(({ images = [], offerName, offerId }) => {
    images.forEach(image => {
      const src = image.startsWith(pictureStorageBaseUrl)
        ? image
        : pictureStorageBaseUrl + image.split('/image/').pop();
      if (src) {
        pictures.set(image, {
          // the last part of the image url, the filename
          id: src.substring(src.lastIndexOf('/') + 1),
          src: src,
          name: offerName,
          offerIds: [...(pictures.get(image)?.offerIds || []), offerId],
        });
      }
    });
  });
  return Array.from(pictures.values());
};

const fromOnlineModel = (onlineModel: OnlineModel): PictureData[] => {
  if (onlineModel.images?.length) {
    return onlineModel.images.map((url, index) => {
      return {
        id: index.toString(),
        name: `${onlineModel.manufacturer} ${onlineModel.onlineModelName}`,
        src: url.startsWith(pictureStorageBaseUrl) ? url : pictureStorageBaseUrl + url.split('/image/').pop(),
        offerIds: onlineModel.offers.map(o => o.offerId),
      };
    });
  }
  if (onlineModel.listingImage) {
    return [
      {
        id: onlineModel.onlineModelCode,
        name: `${onlineModel.manufacturer} ${onlineModel.onlineModelName}`,
        src: onlineModel.listingImage.startsWith(pictureStorageBaseUrl)
          ? onlineModel.listingImage
          : pictureStorageBaseUrl + onlineModel.listingImage.split('/image/').pop(),
        offerIds: onlineModel.offers.map(o => o.offerId),
      },
    ];
  }
  return [];
};

export const valueOrZero = (input: number | undefined): number => input || 0;

/**
 * Primarily assemble pictures from Offers
 * Secondly do it from OnlineModel
 * @param offers Offer[]
 * @param onlineModel OnlineModel
 */
export const assemblePictures = (offers: Offer[], onlineModel: OnlineModel): PictureData[] => {
  return offers.length ? fromOffers(offers) : fromOnlineModel(onlineModel);
};

/**
 * Have those offers with active true
 * Sort with the best availability first and the worst availability last
 * @param offers Offer[]
 */
export const filterAndSortOffersByAvailability = (offers: Offer[]) => {
  return offers.length
    ? offers
        .filter(o => o.active)
        .sort((a, b) => {
          // Sort to last if data is not available at all
          if (!a.stockAvailabilityRange) {
            return 1;
          }

          const l1 = valueOrZero(a.stockAvailabilityRange?.lower);
          const l2 = valueOrZero(b.stockAvailabilityRange?.lower);

          if (l1 < l2) {
            return 1;
          }

          if (l1 > l2) {
            return -1;
          }

          return 0;
        })
    : [];
};

export const filterAndSortOffersByName = (offers: Offer[]) => {
  return offers.length
    ? offers.sort((a, b) => a.offerName.localeCompare(b.offerName, undefined, { numeric: true }))
    : [];
};

const isDevice = (commercialProduct: CommercialProduct) =>
  commercialProduct.productType === CommercialProductType.HANDHELD_DEVICE;

export const isCommercialProductPaymentAllowed = (
  cp: CommercialProduct,
  segmentPricingGroup?: string,
  eppSolution?: EppSolutionResponse
) =>
  userHasEmptyOrSMEPriceGroup(segmentPricingGroup) ||
  eppSolution?.allowDevicesWithRecurringCharges ||
  cp.payments === 1 ||
  cp.productSubType === CommercialProductSubType.EPP_DEVICE ||
  !isDevice(cp);

const isEppProduct = (commercialProduct: CommercialProduct) =>
  commercialProduct.productSubType === CommercialProductSubType.EPP_DEVICE;

/**
 * TODO Will deprecate and be removed
 * There are two kind of products:
 * - those with productSubType: EPP_DEVICE
 * - and the public devices with other values
 * both are used in corresponding places
 * sorting to to show longer monthly plans first
 * @param commercialProducts CommercialProduct[]
 */
export const filterAndSortCommercialProductsPublic = (commercialProducts: CommercialProduct[]) => {
  return commercialProducts
    ? commercialProducts
        .filter(cp => cp.payments)
        .filter(cp => !isEppProduct(cp))
        .sort((a, b) => (valueOrZero(a.payments) > valueOrZero(b.payments) ? -1 : 1))
    : [];
};

const isAdminUser = (user?: AuthenticatedUserState): boolean =>
  user?.userRole === AuthenticatedUserRole.ADMIN || user?.userRole === AuthenticatedUserRole.KEY_USER;

const isEppSolutionActive = (companyInfo?: CompanyInfoState): boolean =>
  companyInfo?.eppSolution?.eppSolutionStatus === EppSolutionResponse.EppSolutionStatusEnum.ACTIVE;

const isRecurringChargesAllowed = (companyInfo: CompanyInfoState): boolean =>
  companyInfo.eppSolution?.allowDevicesWithRecurringCharges || false;

const isOneTimeChargeAllowed = (companyInfo: CompanyInfoState): boolean =>
  companyInfo.eppSolution?.allowDevicesWithOneTimeCharges || false;

const isEligibleEppAdmin = (user?: AuthenticatedUserState, companyInfo?: CompanyInfoState): boolean =>
  isAdminUser(user) && isEppSolutionActive(companyInfo);

const isOneTimePayment = (cp?: CommercialProduct, addOn?: AddOn) => cp?.payments === 1 || addOn?.addOnOneTimeCharge;

const calculateMonthlyChargeWithCorporateShare = (commercialProduct: CommercialProduct, corporateShare: number) =>
  valueOrZero(commercialProduct.monthlyRecurringCharge) - corporateShare;

// In case of EPP prices, subtract corporate share from the prices shown to employee (show 0 if corporate share is greater than device price, show full price if corporate share is undefined)
const calculateEmployeeMonthlyCharge = (commercialProduct: CommercialProduct, corporateShare: number) => {
  const monthlyCharge = Math.round(calculateMonthlyChargeWithCorporateShare(commercialProduct, corporateShare));
  return monthlyCharge > 0 && isEppProduct(commercialProduct) ? monthlyCharge : 0;
};

const isEppCatalog = (catalog: Catalog) => catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING;

const calculateEmployeeCharges = (cp: CommercialProduct, catalog: Catalog): CommercialProduct => {
  const cpNewPayments = { ...cp };
  if (!isEppCatalog(catalog!)) {
    cpNewPayments.oneTimeCharge = 0;
    cpNewPayments.monthlyRecurringCharge = 0;
  } else {
    cpNewPayments.oneTimeCharge = 0;
    cpNewPayments.monthlyRecurringCharge =
      catalog && catalog.corporateShare === undefined
        ? 0
        : calculateEmployeeMonthlyCharge(cp, valueOrZero(catalog?.corporateShare));
  }
  return cpNewPayments;
};

const shiftOneTimePaymentToLast = (commercialProducts: CommercialProduct[]) => {
  const sortedCps = [...commercialProducts];
  sortedCps.push(
    sortedCps.splice(
      sortedCps.findIndex(cp => isOneTimePayment(cp)),
      1
    )[0]
  );
  return sortedCps;
};

const sortDevices = (commercialProducts: CommercialProduct[]) => {
  const eppCps = commercialProducts
    .filter(cp => isEppProduct(cp))
    .sort((a, b) => (valueOrZero(a?.monthlyRecurringCharge) < valueOrZero(b?.monthlyRecurringCharge) ? -1 : 1));

  let nonEppCps = commercialProducts
    .filter(cp => !isEppProduct(cp))
    .sort((a, b) => (valueOrZero(a?.monthlyRecurringCharge) < valueOrZero(b?.monthlyRecurringCharge) ? -1 : 1));

  if (nonEppCps.length) {
    nonEppCps = shiftOneTimePaymentToLast(nonEppCps);
  }

  return [...eppCps, ...nonEppCps];
};

const sortAccessories = (commercialProducts: CommercialProduct[]) => {
  const sortedCps = commercialProducts.sort((a, b) =>
    valueOrZero(a?.monthlyRecurringCharge) < valueOrZero(b?.monthlyRecurringCharge) ? -1 : 1
  );

  return shiftOneTimePaymentToLast(sortedCps);
};

const getCompanyDiscounts = (companyInfo?: CompanyInfoState) => {
  const discountedPriceItems: DiscountedPriceItem[] = [];
  for (const discountedPrice of companyInfo?.discountedPrices || []) {
    for (const priceItem of discountedPrice.prices) {
      discountedPriceItems.push(priceItem);
    }
  }
  return discountedPriceItems;
};

const calculateCompanyDiscount = (
  cp: CommercialProduct,
  discountedPriceItems: DiscountedPriceItem[],
  overridePriceDifferenceCheck?: boolean
) => {
  const dpItem = discountedPriceItems.find(dp => dp.commercialProductCode === cp.commercialProductCode);
  if (dpItem) {
    const discountedCp = { ...cp };
    discountedCp.oneTimeCharge =
      overridePriceDifferenceCheck || valueOrZero(discountedCp.oneTimeCharge) < valueOrZero(dpItem.oneTimeCharge)
        ? discountedCp.oneTimeCharge
        : dpItem.oneTimeCharge;
    discountedCp.monthlyRecurringCharge =
      overridePriceDifferenceCheck ||
      valueOrZero(discountedCp.monthlyRecurringCharge) < valueOrZero(dpItem.monthlyRecurringCharge)
        ? discountedCp.monthlyRecurringCharge
        : dpItem.monthlyRecurringCharge;
    return discountedCp;
  }
  return cp;
};

const isValidCampaign = (campaignAssociation: CampaignAssociation, campaignPromotion: CampaignPromotion): boolean => {
  const currentDate = Date.now();
  return (
    !!(
      campaignAssociation.effectiveDate &&
      campaignAssociation.expirationDate &&
      campaignAssociation.effectiveDate <= currentDate &&
      currentDate <= campaignAssociation.expirationDate
    ) && campaignAssociation.campaignPromotionCode === campaignPromotion.campaignPromotionCode
  );
};

const calculateCampaignDiscount = (cp: CommercialProduct, campaigns?: CampaignPromotion[]) => {
  const discountedCp = { ...cp };
  if (campaigns && cp.campaignAssociations && cp.campaignAssociations?.length > 0) {
    const validCampaigns: CampaignAssociation[] = [];
    for (const campaign of campaigns) {
      for (const campaignAssociation of cp.campaignAssociations) {
        if (isValidCampaign(campaignAssociation, campaign)) {
          validCampaigns.push(campaignAssociation);
        }
      }
    }
    if (validCampaigns.length > 0) {
      const isOneTime = isOneTimePayment(cp);
      let biggestDiscount = 0;
      for (const validCampaign of validCampaigns) {
        if (isOneTime) {
          biggestDiscount = valueOrZero(
            valueOrZero(validCampaign.oneTimeCharge) > biggestDiscount
              ? validCampaign.oneTimeCharge
              : valueOrZero(biggestDiscount)
          );
        } else {
          biggestDiscount = valueOrZero(
            valueOrZero(validCampaign.monthlyRecurringCharge) > biggestDiscount
              ? validCampaign.monthlyRecurringCharge
              : valueOrZero(biggestDiscount)
          );
        }
      }
      if (isOneTime) {
        valueOrZero(cp.oneTimeCharge) < biggestDiscount
          ? (discountedCp.oneTimeCharge = cp.oneTimeCharge)
          : (discountedCp.oneTimeCharge = biggestDiscount);
      } else {
        valueOrZero(cp.monthlyRecurringCharge) < biggestDiscount
          ? (discountedCp.monthlyRecurringCharge = cp.monthlyRecurringCharge)
          : (discountedCp.monthlyRecurringCharge = biggestDiscount);
      }
    }
  }
  return discountedCp;
};

const getMostAffordableDiscount = (
  cp: CommercialProduct,
  discountedPriceItems: DiscountedPriceItem[],
  campaigns?: CampaignPromotion[]
) => {
  const cpWithCompanyDiscount = calculateCompanyDiscount(cp, discountedPriceItems);
  const cpWithCampaignDiscount = calculateCampaignDiscount(cp, campaigns);
  if (isOneTimePayment(cp)) {
    return valueOrZero(cpWithCampaignDiscount.oneTimeCharge) < valueOrZero(cpWithCompanyDiscount.oneTimeCharge)
      ? cpWithCampaignDiscount
      : cpWithCompanyDiscount;
  }
  return valueOrZero(cpWithCampaignDiscount.monthlyRecurringCharge) <
    valueOrZero(cpWithCompanyDiscount.monthlyRecurringCharge)
    ? cpWithCampaignDiscount
    : cpWithCompanyDiscount;
};

/*
 *  Epp solution is applied only to devices.
 *  A special rule to not show recurring prices for accessories when epp is allowed.
 * */
const filterAllowedEppAdminProducts = (
  cp: CommercialProduct,
  companyInfo: CompanyInfoState,
  category?: string | undefined
) => {
  if (!isDevice(cp)) {
    return true;
  }
  if (cp.oneTimeCharge && cp.oneTimeCharge > 0 && category !== OnlineModelCategory.ACCESSORIES) {
    return isOneTimeChargeAllowed(companyInfo);
  }
  if (
    cp.productSubType !== CommercialProductSubType.EPP_DEVICE &&
    cp.monthlyRecurringCharge &&
    cp.monthlyRecurringCharge > 0
  ) {
    if (category === OnlineModelCategory.ACCESSORIES) {
      return false;
    }
    return isRecurringChargesAllowed(companyInfo);
  }
  return true;
};

const processAddOns = (user: AuthenticatedUserState | undefined, addOns?: AddOn[]) => {
  return addOns?.map(addOn => {
    const newAddOn = { ...addOn };
    if (isEmployeeUser(user)) {
      newAddOn.addOnMonthlyRecurringCharge = 0;
      newAddOn.addOnOneTimeCharge = 0;
    } else {
      newAddOn.addOnMonthlyRecurringCharge = valueOrZero(addOn.addOnMonthlyRecurringCharge);
      newAddOn.addOnOneTimeCharge = valueOrZero(addOn.addOnOneTimeCharge);
    }
    return newAddOn;
  });
};

const addVatToCharges = (cp: CommercialProduct): CommercialProduct => {
  const cpWithVat = { ...cp };
  valueOrZero(cp?.oneTimeCharge) > 0
    ? (cpWithVat.oneTimeCharge = Math.round(valueOrZero(cp?.oneTimeCharge) * VAT_MULTIPLIER))
    : (cpWithVat.monthlyRecurringCharge = Math.round(valueOrZero(cp.monthlyRecurringCharge) * VAT_MULTIPLIER));
  return cpWithVat;
};

const processOffers = (
  onlineModel: OnlineModel,
  companyInfo?: CompanyInfoState,
  user?: AuthenticatedUserState,
  catalog?: Catalog
): Offer[] => {
  const discountedPriceItems = getCompanyDiscounts(companyInfo);
  const offers =
    isEmployeeUser(user) && catalog
      ? filterOffersAndCommercialProductsForEmployee(onlineModel?.offers, catalog, onlineModel.category)
      : onlineModel?.offers.filter(offer => offer.active);
  return (
    offers
      .map(offer => {
        const newOffer = { ...offer };
        if (isEmployeeUser(user) && catalog) {
          newOffer.commercialProducts = offer.commercialProducts.map(cp =>
            calculateEmployeeCharges(calculateCompanyDiscount(cp, discountedPriceItems), catalog)
          );
          return newOffer;
        } else if (isEligibleEppAdmin(user, companyInfo)) {
          const filteredCps = offer.commercialProducts
            .filter(cp => filterAllowedEppAdminProducts(cp, companyInfo!, onlineModel.category))
            .filter(cp => cp.active)
            .map(cp => getMostAffordableDiscount(cp, discountedPriceItems, onlineModel?.campaigns));
          if (filteredCps.length === 0) {
            newOffer.commercialProducts = [];
          } else {
            newOffer.commercialProducts = isDevice(filteredCps[0])
              ? sortDevices(filteredCps)
              : sortAccessories(filteredCps);
          }
          return newOffer;
        }
        newOffer.commercialProducts = offer.commercialProducts
          .filter(cp => cp.active)
          .filter(cp => !isEppProduct(cp))
          .filter(cp => isCommercialProductPaymentAllowed(cp, user?.segmentPricingGroup, companyInfo?.eppSolution))
          .filter(cp => cp.payments || cp.oneTimeCharge || cp.monthlyRecurringCharge)
          .map(cp =>
            userHasEmptyOrSMEPriceGroup(user?.segmentPricingGroup)
              ? cp
              : calculateCompanyDiscount(cp, discountedPriceItems, true)
          )
          .map(cp => calculateCampaignDiscount(cp, onlineModel?.campaigns))
          .map(cp => (isEmployeeUser(user) ? addVatToCharges(cp) : cp))
          .sort((a, b) => (valueOrZero(a.payments) > valueOrZero(b.payments) ? -1 : 1));
        return newOffer;
      })
      // filter away offer that doesn't contain commercialProducts
      .filter(offer => offer.commercialProducts.length !== 0)
  );
};

export const processOnlineModel = (
  onlineModel: OnlineModel,
  companyInfo?: CompanyInfoState,
  user?: AuthenticatedUserState,
  catalog?: Catalog
): OnlineModel => {
  const newModel = { ...onlineModel };
  newModel.offers = processOffers(onlineModel, companyInfo, user, catalog);
  newModel.addOns = processAddOns(user, onlineModel?.addOns);
  return newModel;
};

export const useProcessOnlineModel = (
  onlineModel: OnlineModel,
  companyInfo?: CompanyInfoState,
  user?: AuthenticatedUserState,
  catalog?: Catalog
): OnlineModel => {
  // TODO: leo 17.6.2022 - remove below `prettier-ignore` when below eslint disabling of react-hooks is removed
  // prettier-ignore
  return useMemo(
    () => processOnlineModel(onlineModel, companyInfo, user, catalog),
    [ /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps
      onlineModel.onlineModelCode,
      companyInfo?.eppSolution?.eppSolutionStatus,
      companyInfo?.discountedPrices?.length,
      user?.userRole,
      catalog?.catalogCode,
    ]
  );
};

// TODO take into use when commercialProducts have been moved from Offer to OnlineModel
// TODO check Offers filtering for offers with no CommercialProducts for employee users, before using this.
export const processOnlineModelV2 = (
  onlineModel: OnlineModel,
  companyInfo?: CompanyInfoState,
  user?: AuthenticatedUserState,
  catalog?: Catalog
): OnlineModel => {
  const discountedPriceItems = getCompanyDiscounts(companyInfo);
  const newModel = { ...onlineModel };
  let fixedCps: CommercialProduct[] = [];
  if (isEmployeeUser(user) && catalog) {
    fixedCps =
      onlineModel.commercialProducts ||
      []
        .filter(cp => checkCpEligibilityForEmployee(cp, catalog, onlineModel.category))
        .map(cp => calculateEmployeeCharges(calculateCompanyDiscount(cp, discountedPriceItems), catalog!));
  } else if (isEligibleEppAdmin(user, companyInfo)) {
    const filteredCps =
      onlineModel.commercialProducts ||
      []
        .filter(cp => filterAllowedEppAdminProducts(cp, companyInfo!, onlineModel.category))
        .map(cp => calculateCompanyDiscount(cp, discountedPriceItems));
    fixedCps = isDevice(filteredCps[0]) ? sortDevices(filteredCps) : sortAccessories(filteredCps);
  } else {
    fixedCps = filterAndSortCommercialProductsPublic(fixedCps);
  }
  newModel.commercialProducts = fixedCps;
  return newModel;
};

/** Addon handling functions **/

const formatEppCategory = (eppCategory: OfferEppCategory): EppCategory => {
  // @ts-ignore
  return EppCategory[eppCategory.trim().replace(/ /g, '_').toUpperCase()];
};

const isDamageInsuranceAddOnShown = (
  addOn: AddOn,
  eppCategories: EppCategory[] | undefined,
  offerEppCategory: OfferEppCategory | undefined
): boolean => {
  if (addOn.addOnGroup === DAMAGE_INSURANCE_ADDON_GROUP) {
    if (eppCategories && offerEppCategory) {
      return eppCategories.includes(formatEppCategory(offerEppCategory));
    }
    return false;
  }
  return true;
};

const isAddOnShown = (addOn: AddOn, addOnAssociations: AddOnAssociation[]): boolean => {
  for (const addOnAssociation of addOnAssociations || []) {
    if (addOn.addOnCode === addOnAssociation.addOnCode) {
      return true;
    }
  }
  return false;
};

const isAddOnCheckedByDefault = (addOn: AddOn): boolean => addOn.addOnGroup === DAMAGE_INSURANCE_ADDON_GROUP;

const getAddOnAssociationData = (addOnAssociations: AddOnAssociation[], addOnCode: string) => {
  const matchingAddOnAssociation = addOnAssociations.find(association => association.addOnCode === addOnCode);
  return {
    display: matchingAddOnAssociation!.display,
    addOnPurpose: matchingAddOnAssociation!.addOnPurpose,
    addOnAssociationId: matchingAddOnAssociation!.addOnAssociationId,
    addOnAssociationCode: matchingAddOnAssociation!.addOnAssociationCode,
  };
};
export const getAddOnsAndDefaultValues = (
  addOns: AddOn[],
  commercialProduct: CommercialProduct,
  eppCategories: EppCategory[] | undefined,
  isEmployee: boolean,
  productQuantity: number,
  offerEppCategory?: OfferEppCategory,
  user?: AuthenticatedUserState
): ProductDetailsAddOnStateProperties[] => {
  const isSquareTradeAllowed = userHasEmptyOrSMEPriceGroup(user?.segmentPricingGroup);
  const validAddOns = addOns
    .filter(addOn => isDamageInsuranceAddOnShown(addOn, eppCategories, offerEppCategory))
    .filter(addOn => isAddOnShown(addOn, commercialProduct?.addOnAssociations ?? []))
    .filter(addOn => isSquareTradeAllowed || !isSquareTradeAddOn(addOn));
  return (
    validAddOns.map(addOn => {
      const isCheckedByDefault = isAddOnCheckedByDefault(addOn);
      return {
        addOn,
        isChecked: isCheckedByDefault,
        isDisabled: isEmployee && isCheckedByDefault,
        quantity: productQuantity,
        ...getAddOnAssociationData(commercialProduct.addOnAssociations!, addOn.addOnCode),
      };
    }) || []
  );
};

const calculateCommercialProductPayments = (cp: CommercialProduct, quantity = 1, payments: Price): Price => {
  if (isOneTimePayment(cp)) {
    payments.oneTimeCharge = cp.oneTimeCharge! * quantity;
    payments.payments = 1;
  } else {
    payments.monthlyRecurringCharge = cp.monthlyRecurringCharge! * quantity;
    payments.payments = cp.payments;
  }

  return payments;
};

const calculateAddOnPayments = (addOns: ProductDetailsAddOnStateProperties[], payments: Price): Price => {
  const addOnPayments = { ...payments };
  addOns
    .filter(addOn => addOn.isChecked)
    .forEach(addOn => {
      isOneTimePayment(undefined, addOn.addOn)
        ? (addOnPayments.oneTimeCharge =
            valueOrZero(addOnPayments.oneTimeCharge) + valueOrZero(addOn.addOn.addOnOneTimeCharge) * addOn.quantity)
        : (addOnPayments.monthlyRecurringCharge =
            valueOrZero(addOnPayments.monthlyRecurringCharge) +
            valueOrZero(addOn.addOn.addOnMonthlyRecurringCharge) * addOn.quantity);
    });
  return addOnPayments;
};

/**
 * Calculate VAT if applicable
 * VAT is shown to employee users only
 * @param charge
 * @param isVat
 */
export const calculateVatIfApplicable = (charge: number, isVat = false) =>
  isVat ? Math.round(charge * VAT_MULTIPLIER) : charge;

export const calculatePaymentsForSubscriptions = (offer: Offer, cpQuantity: number): Price => {
  const oneTimeCharge = offer.commercialProducts
    .flatMap(commercialProduct => (commercialProduct.oneTimeCharge || 0) * cpQuantity)
    .reduce((previous, current) => (current ? current : previous));
  const monthlyRecurringCharge = offer.commercialProducts
    .flatMap(commercialProduct => (commercialProduct.monthlyRecurringCharge || 0) * cpQuantity)
    .reduce((previous, current) => (current ? current : previous));
  return { monthlyRecurringCharge, oneTimeCharge };
};

export const calculatePaymentsForProducts = (
  cp: CommercialProduct,
  cpQuantity = 1,
  addOns?: ProductDetailsAddOnStateProperties[]
): Price => {
  let payments: Price = { monthlyRecurringCharge: 0, oneTimeCharge: 0 };
  if (cp) {
    payments = calculateCommercialProductPayments(cp, cpQuantity, payments);
  }
  if (addOns?.length) {
    payments = calculateAddOnPayments(addOns!, payments);
  }
  return payments;
};

export const checkIfVatIsRequired = (price: Price, includeVat?: boolean) => {
  if (includeVat === true) {
    return {
      monthlyRecurringCharge: Math.round(valueOrZero(price.monthlyRecurringCharge) * VAT_MULTIPLIER),
      oneTimeCharge: Math.round(valueOrZero(price.oneTimeCharge) * VAT_MULTIPLIER),
      payments: price.payments,
    };
  }
  return price;
};

export const calculatePayments = (
  cp: CommercialProduct,
  offer: Offer,
  cpQuantity = 1,
  addOns?: ProductDetailsAddOnStateProperties[],
  includeVat?: boolean
): Price => {
  const price =
    cp.productType === CommercialProductType.SALES_PRODUCT
      ? calculatePaymentsForSubscriptions(offer, cpQuantity)
      : calculatePaymentsForProducts(cp, cpQuantity, addOns);
  return checkIfVatIsRequired(price, includeVat);
};

export const applyDiscounts = (onlineModel: OnlineModel, discountedPrices: DiscountedPrices[]): OnlineModel => {
  const discountedModel = discountedPrices.find(discount => discount.model === onlineModel.onlineModelCode);
  if (!discountedModel) {
    return onlineModel;
  }
  const newModel = { ...onlineModel };
  newModel.offers = onlineModel.offers.map(offer => {
    const newOffer = { ...offer };
    newOffer.commercialProducts = offer.commercialProducts.map(cp => {
      const newCp = { ...cp };
      const discountedCP = discountedModel.prices.find(
        discountedPrice => discountedPrice.commercialProductCode === cp.commercialProductCode
      );
      if (!discountedCP) {
        return newCp;
      }
      if (discountedCP.oneTimeCharge !== undefined && cp.oneTimeCharge !== undefined) {
        newCp.oneTimeCharge = discountedCP.oneTimeCharge;
      }
      if (discountedCP.monthlyRecurringCharge !== undefined && cp.monthlyRecurringCharge !== undefined) {
        newCp.monthlyRecurringCharge = discountedCP.monthlyRecurringCharge;
      }
      return newCp;
    });
    return newOffer;
  });
  return newModel;
};

const getSelectedAddOnBundles = (
  addOns: ProductDetailsAddOnStateProperties[],
  quantity: number
): SelectedAddOnBundle[] => {
  return addOns
    .filter(addOn => addOn.isChecked)
    .map(addOn => ({
      display: addOn.display,
      addOnPurpose: addOn.addOnPurpose,
      addOnAssociationCode: addOn.addOnAssociationCode,
      addOnAssociationId: addOn.addOnAssociationId,
      addOnCode: addOn.addOn.addOnCode,
      count: quantity,
      isMandatory: addOn.isDisabled,
    }));
};

export const onClickAddToCart = (
  props: ProductDetailsPropsComponentBottomRightSide,
  addOns: ProductDetailsAddOnStateProperties[],
  productPath: string
) => {
  const { addToCart, commercialProduct, isPublicSite, offer, onlineModel, productQuantity } = props;
  const selectedOffer = onlineModel.offers.find(of => of.offerCode === offer.offerCode)!;
  const selectedCommercialProduct = selectedOffer.commercialProducts.find(
    cp => cp.commercialProductCode === commercialProduct.commercialProductCode
  )!;
  const selectedAddOnBundles = getSelectedAddOnBundles(addOns, productQuantity);
  return addToCart({
    onlineModel,
    offer: selectedOffer,
    commercialProduct: selectedCommercialProduct,
    quantity: productQuantity,
    isPublicSite,
    selectedAddOnBundles,
    additionalFields: [],
    productPath,
  });
};

export const getOfferPicture = (pictures: PictureData[], offer?: Offer): PictureData | undefined =>
  offer ? pictures.find(p => p.offerIds?.includes(offer.offerId)) : undefined;
export const getDefaultPaymentTerm = (onlineModelCategory?: string): number => {
  if ((onlineModelCategory as OnlineModelCategory) === OnlineModelCategory.ACCESSORIES) {
    return 1;
  }
  return 24;
};

const employeeVAT = getVatAsString();
const corporateVAT = '0';

export const getEffectiveVAT = (isEmployee: boolean) => (isEmployee ? employeeVAT : corporateVAT);
