import { TypeKeys } from './actionsUtils.js';
import type { ChatResourceLoadStatus } from '../../common/enums.js';

export interface ChatScriptLoadAction {
  scriptLoadStatus: ChatResourceLoadStatus;
  type: TypeKeys.SET_CHAT_SCRIPT_LOAD_STATUS;
}

export const setScriptLoadStatus = (scriptLoadStatus: ChatResourceLoadStatus): ChatScriptLoadAction => ({
  scriptLoadStatus: scriptLoadStatus,
  type: TypeKeys.SET_CHAT_SCRIPT_LOAD_STATUS,
});

export interface ChatStyleLoadAction {
  styleLoadStatus: ChatResourceLoadStatus;
  type: TypeKeys.SET_CHAT_STYLE_LOAD_STATUS;
}

export const setStyleLoadStatus = (styleLoadStatus: ChatResourceLoadStatus): ChatStyleLoadAction => ({
  styleLoadStatus: styleLoadStatus,
  type: TypeKeys.SET_CHAT_STYLE_LOAD_STATUS,
});

export type SnapInsActionTypes = ChatScriptLoadAction | ChatStyleLoadAction;
