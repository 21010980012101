import { AddOnVisibilityOperationType } from '../../common/enums.js';
import { AdditionalServicesCheckoutContent } from '../OrderSubscriptionConfiguration/AdditionalServicesCheckoutContent.js';
import { AuthenticatedUserRole, CommercialProductSubType } from '../../generated/api/models.js';
import { CampaignSelectTile } from './CampaignSelectTile.js';
import { FixedBroadbandInstallationService } from './FixedBroadbandInstallationService.js';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { SELECTED_COMMERCIAL_PRODUCT_INDEX } from '../../common/constants/commonConstants.js';
import { WizardActions } from '../WizardActions/index.js';
import { YritystuplaSelection } from './YritysTuplaSelection.js';
import { clearCampaigns, googleEcommerceAddToCart } from '../../selfservice/actions/index.js';
import { createNettiliteEcommerceItemsFromConfiguredOffer } from '../../selfservice/common/googleEcommerceEvent.js';
import {
  defaultFixedBroadbandProductContent,
  fixedBroadbandProductContent,
} from '../OrderSubscriptionSelection/content/NettiLiteOfferSpecification.js';
import { getAddedAddOns, getGFastDevicePackageAddon } from '../FixedBroadbandCheckout/FixedBroadbandUtils.js';
import { isIpAddressGroupAddOn } from '../../public/site/path/FixedBroadbandOrder/fixedBroadbandAddOnUtil.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type {
  AddOn,
  AddOnVisibility,
  CampaignAssociation,
  CampaignContext,
  Offer,
  OnlineModel,
} from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { CampaignSelectOption } from './CampaignSelectTile.js';
import type { CommercialProductAddOnVisibilitiesMap } from '../../common/utils/addOnVisibilityUtils.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { Dispatch, SetStateAction } from 'react';
import type {
  InstallationServiceAddOnSelection,
  YritystuplaOfferSelection,
} from '../FixedBroadbandCheckout/FixedBroadbandCheckout.js';
import type { IpAddressAddOnSelection } from './FixedBroadbandIpAddOn.js';
import type { OrderConfiguration } from '../OrderSubscriptionConfiguration/FormWrapper.js';

import './FixedBroadbandProductSelection.scss';

export interface FixedBroadbandProductSelectionProps {
  eCommerceProductDetailEvent: () => void;
  onNext: () => void;
  selectedOffer: Offer;
  yritystuplaModel?: OnlineModel;
  configuredOffer: ConfiguredOffer;
  selectedCampaign?: CampaignAssociation;
  campaignVoucher?: string;
  campaignContextsFromVoucher: CampaignContext[];
  campaignOptions: CampaignSelectOption[];
  installationServiceSelection: InstallationServiceAddOnSelection;
  setInstallationServiceSelection: Dispatch<SetStateAction<InstallationServiceAddOnSelection>>;
  ipAddressAddOnSelection: IpAddressAddOnSelection;
  setIpAddressAddOnSelection: Dispatch<SetStateAction<IpAddressAddOnSelection>>;
  setGFastDeviceIncluded: Dispatch<SetStateAction<boolean>>;
  yritystuplaOffer: YritystuplaOfferSelection;
  setYritystuplaOffer: Dispatch<SetStateAction<YritystuplaOfferSelection>>;
  setCampaingAutoSelect: Dispatch<SetStateAction<boolean>>;
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap;
  onAddOnSelectionChange: (addedAddOns: AddedAddon[]) => void;
  publicAddOnVisibilities?: AddOnVisibility[];
}

const gFastDevicePackageAddon = getGFastDevicePackageAddon();

const findOnlineInstallationService = (
  commercialProductCode?: string,
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap
): AddOn | undefined => {
  if (!commercialProductCode) {
    return undefined;
  }

  if (!commercialProductAddOnVisibilities) {
    return undefined;
  }

  const allowedAddOns = commercialProductAddOnVisibilities?.[commercialProductCode];
  const onlineInstallationServiceAddOnCode = '83cda4e8-7131-737a-924d-db1d082019d2';
  const installationServiceAddOnProductName = 'Asennuspalvelu';
  const installationServiceOptions = allowedAddOns?.[installationServiceAddOnProductName];

  return installationServiceOptions?.find(a => a.addOnCode === onlineInstallationServiceAddOnCode);
};

export const FixedBroadbandProductSelection = ({
  eCommerceProductDetailEvent,
  onNext,
  selectedOffer,
  configuredOffer,
  yritystuplaModel,
  selectedCampaign,
  campaignVoucher,
  campaignContextsFromVoucher,
  campaignOptions,
  setInstallationServiceSelection,
  setGFastDeviceIncluded,
  yritystuplaOffer,
  setYritystuplaOffer,
  setCampaingAutoSelect,
  commercialProductAddOnVisibilities,
  onAddOnSelectionChange,
  publicAddOnVisibilities,
}: FixedBroadbandProductSelectionProps) => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const isEmployeeUser = authenticatedUser?.userRole === AuthenticatedUserRole.EMPLOYEE;

  const [initialEventSent, setInitialEventSent] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [installationServiceAddOn, setInstallationServiceAddOn] = useState<AddOn>();
  const productContent =
    fixedBroadbandProductContent().find(product => product.offerCodes.includes(selectedOffer.offerCode)) ||
    defaultFixedBroadbandProductContent;

  const selectedCommercialProduct =
    configuredOffer?.selectedCommercialProducts?.[SELECTED_COMMERCIAL_PRODUCT_INDEX]?.commercialProduct;
  const selectedCommercialProductCode = selectedCommercialProduct?.commercialProductCode;
  const isMobileBroadbandProduct =
    selectedCommercialProduct?.productSubType === CommercialProductSubType.MOBILE_BROADBAND;

  const installationServiceAddOnCampaign = campaignContextsFromVoucher.find(
    campaign => campaign.addOnCode === installationServiceAddOn?.addOnCode
  );

  useEffect(() => {
    setGFastDeviceIncluded(
      selectedOffer.commercialProducts?.some(cp =>
        cp.associatedAddOns?.some(addon => addon.addOnCode === gFastDevicePackageAddon.addOnCode)
      )
    );
  }, [selectedOffer.commercialProducts, setGFastDeviceIncluded]);

  useEffect(() => {
    if (!initialEventSent) {
      eCommerceProductDetailEvent();
      setInitialEventSent(true);
    }
  }, [initialEventSent, eCommerceProductDetailEvent]);

  useEffect(() => {
    const shouldUpdateInstallationServiceAddOn =
      selectedCommercialProductCode && commercialProductAddOnVisibilities?.[selectedCommercialProductCode];
    if (shouldUpdateInstallationServiceAddOn) {
      const installationService = findOnlineInstallationService(
        selectedCommercialProductCode,
        commercialProductAddOnVisibilities
      );

      setInstallationServiceAddOn(installationService);
    }
  }, [selectedCommercialProductCode, commercialProductAddOnVisibilities]);

  // filter out services which needs additional selection and has already been specified explicitly, display IP address addons first
  const preLoadedVisibilities = publicAddOnVisibilities
    ?.filter(visibility => installationServiceAddOn?.addOnCode !== visibility.addOnGuidCode)
    .sort(
      (a, b) =>
        (isIpAddressGroupAddOn(a.addOn?.addOnGroup) ? 0 : 1) - (isIpAddressGroupAddOn(b.addOn?.addOnGroup) ? 0 : 1)
    );

  const methods = useForm<OrderConfiguration>({ mode: 'all', shouldFocusError: true });

  return (
    <div className="of-fixed-bb-product-selection">
      <strong className="ds-display--inline-block ds-margin-bottom--3">{t.X2HQ('Select contract')}</strong>
      <CampaignSelectTile options={campaignOptions} />
      {formSubmitted && selectedCampaign === null && <p className="ds-inputerror">{t.X2HQ('Select contract')}</p>}
      <div className="ds-padding-top--3 ds-margin-top--3">{productContent.body}</div>
      <p className="ds-padding-vertical--3">
        <strong>{t.EY5B('New wireless broadband router will be delivered to defined address.')}</strong>
        {` ${t.U84N(
          'You can install the router by yourself or order Netti Lite installation service along your subscription order.'
        )}`}
      </p>
      <strong className="ds-margin-bottom--2 ds-padding-bottom--1 ds-display--inline-block">
        {t.UU5G('Select additional services')}
      </strong>
      {installationServiceAddOn && (
        <div className="ds-margin-vertical--2">
          <FixedBroadbandInstallationService
            campaignContextsFromVoucher={installationServiceAddOnCampaign}
            campaignVoucher={campaignVoucher}
            addon={installationServiceAddOn}
            showRecommendedDisclaimer={isMobileBroadbandProduct}
            onChange={enabled =>
              setInstallationServiceSelection({
                enabled,
                selectedAddOn: getAddedAddOns([installationServiceAddOn])?.[0],
              } as InstallationServiceAddOnSelection)
            }
          />
        </div>
      )}
      {yritystuplaModel && (
        <div className="ds-margin-vertical--2">
          <YritystuplaSelection
            model={yritystuplaModel}
            yritystuplaOffer={yritystuplaOffer}
            onChange={setYritystuplaOffer}
          />
        </div>
      )}
      {preLoadedVisibilities && (
        <FormProvider {...methods}>
          <form noValidate>
            <AdditionalServicesCheckoutContent
              commercialProduct={selectedCommercialProduct}
              isRingSelected={false}
              cartItemInstanceId={`${SELECTED_COMMERCIAL_PRODUCT_INDEX}`}
              productAddOns={
                configuredOffer?.selectedCommercialProducts?.[SELECTED_COMMERCIAL_PRODUCT_INDEX]?.addedAddOns
              }
              onAddOnSelectionChange={(_cartIndex, addedAddOns) => onAddOnSelectionChange(addedAddOns)}
              preFetchedAddOnVisibilities={preLoadedVisibilities}
              hideHeader={true}
              loadNettiRules={true}
              addOnVisibilityOperationType={AddOnVisibilityOperationType.NEW_SALES}
            />
          </form>
        </FormProvider>
      )}
      <div className="ds-margin-top--5">
        <OrderSummary
          commercialProducts={configuredOffer.selectedCommercialProducts}
          summaryType={OrderSummaryType.DETAILS_CLOSED}
        />
      </div>
      <WizardActions
        classes={['ds-padding-vertical--3']}
        onBackClick={() => history.back()}
        onForwardClick={methods.handleSubmit(() => {
          setFormSubmitted(true);

          // Clear voucher if selected campaign do not contain voucher
          const selectedCampaignOption = campaignOptions.find(option => option.isSelected);
          if (!selectedCampaignOption?.voucher) {
            if (!selectedCampaign) {
              setCampaingAutoSelect(false);
            }

            dispatch(clearCampaigns());
          }

          if (selectedCampaign !== null) {
            if (isEmployeeUser) {
              // If user is logged in as employee, logout and proceed to checkout
              logout(undefined);
            }
            dispatch(googleEcommerceAddToCart(createNettiliteEcommerceItemsFromConfiguredOffer(configuredOffer)));
            onNext();
          }
        })}
      />
    </div>
  );
};
