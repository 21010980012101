import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { fieldCantBeEmptyMsg, noSelectionMsg, t } from '../../i18n/index.js';
import { getContactOptions } from '../../formik/Fields/index.js';
import { getTextError } from '../utils.js';
import { useController, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import type * as CLT from '@design-system/component-library';
import type { Contact as ContactType } from '../../../generated/api/contact.js';

interface ContactProps {
  className?: string;
  contacts: Array<ContactType>;
  name: string;
}

export const Contact = (props: ContactProps) => {
  const name = props.name;
  const contacts = getContactOptions(props.contacts, true);
  const methods = useFormContext();
  const { control, setValue, setError } = methods;
  const rules = { required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } };
  const { field, fieldState, formState } = useController({ name, control, rules });
  const defaultValues = formState.defaultValues;
  const keys = name.split('.');
  const value = keys.reduce((current, key) => (current ? current[key] : undefined), defaultValues);
  const defaultValue = contacts.find(i => i.value === value);
  const error = getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error);

  return (
    <AutoComplete<CLT.SearchItemProps>
      className={classNames('large-min-height', props.className)}
      inputRef={field.ref}
      options={contacts}
      defaultOption={defaultValue}
      getUniqueId={i => i.value}
      getDisplayHtml={i => i.html}
      getDisplayValue={i => i.label}
      onInputBlur={field.onBlur}
      onInputChange={(e, item) => {
        setValue(name, item.value);
        setError(name, { message: undefined });
      }}
      id={`${name}-autocomplete`}
      noOptionsMsg={t.ZW5W(noSelectionMsg)}
      label={t.VYZS('Contact person')}
      error={error}
      config={{ isSortable: false }}
      valueUpdatedFromParent={field.value}
    />
  );
};
