import { Email, Name, PhoneNumber } from '../../../common/react-hook-form/fields/index.js';
import { FormGridFieldset } from '../../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { firstNameMsg, lastNameMsg, paymentContactDetailsMsg, t } from '../../../common/i18n/index.js';

export const BILLING_CONTACT_FIRST_NAME_FIELD_NAME = 'billingContactFirstName';
export const BILLING_CONTACT_LAST_NAME_FIELD_NAME = 'billingContactLastName';
export const BILLING_CONTACT_EMAIL_FIELD_NAME = 'billingContactEmail';
export const BILLING_CONTACT_PHONE_FIELD_NAME = 'billingContactPhoneNumber';

export const BillingContactFieldset = () => {
  return (
    <FormGridFieldset legend={t.IFKT(paymentContactDetailsMsg)}>
      <Name label={t.AIK7(firstNameMsg)} name={BILLING_CONTACT_FIRST_NAME_FIELD_NAME} />
      <Name label={t.Y41S(lastNameMsg)} name={BILLING_CONTACT_LAST_NAME_FIELD_NAME} />
      <Email name={BILLING_CONTACT_EMAIL_FIELD_NAME} />
      <PhoneNumber name={BILLING_CONTACT_PHONE_FIELD_NAME} />
    </FormGridFieldset>
  );
};
