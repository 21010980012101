import * as CL from '@design-system/component-library';
import { ConsolidatedListView } from '../../components/ConsolidatedListView/ConsolidatedListView.js';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table } from '../Table/index.js';
import { DocumentHeader } from '../../generated/api/models.js';
import { Grid } from '../Grid/Grid.js';
import { Icon } from '../Icon/Icon.js';
import { Link } from 'react-router-dom';
import { ShowAllAccountsToggleForListViews } from '../../components/ShowAllAccountsToggle/ShowAllAccountsToggleForListViews.js';
import { billingAccountMsg, companyMsg, t, typeMsg } from '../../common/i18n/index.js';
import { formatSumWithSignum } from '../../common/utils/priceUtils.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getInvoiceDocumentPdfPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { InvoiceDocumentsSearchResponse } from '../../generated/api/models.js';

import './InvoiceDocumentList.scss';

interface InvoiceDocumentListProps {
  documentSearchResults: InvoiceDocumentsSearchResponse[];
  total: number;
}

const getTranslation = (documentType: DocumentHeader.DocumentTypeEnum) => {
  switch (documentType) {
    case DocumentHeader.DocumentTypeEnum.CREDIT_NOTE:
      return t.FCH5('Credit note');
    case DocumentHeader.DocumentTypeEnum.FA_STATEMENT:
      return t.QGCA('Financial statement');
    case DocumentHeader.DocumentTypeEnum.PAYMENT_ARRANGEMENT:
      return t.H103('Payment arrangement confirmation');
    case DocumentHeader.DocumentTypeEnum.REMINDER_LETTER:
      return t.Z6YC('Request for payment');
    case DocumentHeader.DocumentTypeEnum.TERMINATION_LETTER:
      return t.P9Z5('Termination letter');
    default:
      return documentType;
  }
};

export const InvoiceDocumentList = ({ documentSearchResults, total }: InvoiceDocumentListProps) => {
  const { authenticatedUser } = useAuth();
  const searchParams = useSearchParams<DefaultListSearchParams>();

  const columns = [
    { key: 'documentType', label: t.VGFF(typeMsg), sortable: true },
    { key: 'invoiceDocumentId', label: t.IFT9(billingAccountMsg), sortable: true },
    { key: 'sum', label: t.P4RQ('Amount'), sortable: true },
    { key: 'created', label: t.T3N9('Date'), sortable: true },
    { key: 'companyName', label: t.KJTS(companyMsg), sortable: false },
  ];

  const rows =
    documentSearchResults?.map(documentSearchResult => {
      const document = documentSearchResult.result;
      return {
        documentType: (
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to={getInvoiceDocumentPdfPrivateMethod(document.invoiceDocumentId).path}
          >
            <Icon color="blue-600" icon="file-pdf" size="l" type="filled" />
            {getTranslation(document.documentType)}
            <span className="ds-sr-only">&nbsp;PDF</span>
          </Link>
        ),
        invoiceDocumentId: (
          <CL.Description
            className="ds-description__list-item-title-override"
            columns={1}
            items={[
              {
                title: document.billingAccountDisplayId || '-',
              },
            ]}
          />
        ),
        sum: (
          <CL.Description
            columns={1}
            items={[
              {
                title: document.sum ? formatSumWithSignum(document.sum) : '-',
              },
            ]}
          />
        ),
        created: (
          <CL.Description
            columns={1}
            items={[
              {
                title: formatTimestampToDDMMYYYY(document.created),
              },
            ]}
          />
        ),
        companyName: getCompanyName(authenticatedUser, document.accountMasterId),
      };
    }) || [];

  return (
    <Grid>
      <div className="of-invoice-documents">
        <ConsolidatedListView
          authenticatedUser={authenticatedUser}
          toggleElement={<ShowAllAccountsToggleForListViews />}
          listSearch={
            <ListSearch
              className="ds-margin-top--4"
              onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
            />
          }
          listElement={<Table columns={columns} noItemsText={t.BOIN('No invoice documents')} rows={rows} />}
          listPagination={<ListPagination totalItems={total} />}
        />
      </div>
    </Grid>
  );
};
