import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Form, Formik } from 'formik';
import { Message, ProvidedDropdown } from '../../common/formik/index.js';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import {
  askAboutInvoiceMsg,
  categoryMsg,
  companyMsg,
  createSupportCaseFromInvoiceMsg,
  descriptionInquiryVisibleInOmaElisaMsg,
  invoiceInqueryMsg,
  invoiceNumberMsg,
  invoiceRelatedClaimMsg,
  messageMsg,
  paymentDetailsMsg,
  refundMsg,
  reporterMsg,
  selectMsg,
  t,
  writeMessageMsg,
} from '../../common/i18n/index.js';
import { createSupportCaseAuthenticated } from '../../common/fetch.js';
import { pushGAEventToDataLayer } from '../../common/analytics.js';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { CommonError } from '../../common/types/errors.js';
import type { PutSupportCaseResponse, SupportCase } from '../../generated/api/models.js';

import './InvoiceSupportCaseDialog.scss';

export interface InvoiceSupportCaseDialogAttrs {
  featureName: SupportCase.FeatureEnum;
  featureItemId?: string;
  errors?: CommonError[];
  invoiceDisplayId: string;
  creator: string;
  companyName?: string;
  accountMasterId: string;
}

export interface InvoiceSupportCaseDialogDispatchers {
  onCloseDialog: () => void;
  onSubmitDialog: (response: PutSupportCaseResponse, shoInfoDialog: boolean, noSuccessNotification: boolean) => void;
}

export type InvoiceSupportCaseDialogProps = InvoiceSupportCaseDialogAttrs & InvoiceSupportCaseDialogDispatchers;

export const InvoiceSupportCaseDialog = ({
  featureItemId,
  errors,
  invoiceDisplayId,
  creator,
  onCloseDialog,
  onSubmitDialog,
  featureName,
  companyName,
  accountMasterId,
}: InvoiceSupportCaseDialogProps): JSX.Element => {
  const { siteBaseUrl } = useContext(SiteContext);
  const { pathname, search } = useLocation();
  const generalErrors = errors?.filter(error => !error.field) || [];
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  useEffect(() => {
    pushGAEventToDataLayer('VIEW_SUPPORT_CASE_MODAL');
  }, [search]);

  return (
    <DialogWrapper closeable header={t.H4LW(createSupportCaseFromInvoiceMsg)} onCloseDialog={onCloseDialog}>
      <div className="ds-margin-top--4 support-case-dialog">
        <div>
          <span className="title">{t.KJTS(companyMsg)}</span>
          <p className="ds-margin-top--0">{companyName}</p>
        </div>
        <div>
          <span className="title">{t.VWKR(reporterMsg)}</span>
          <p className="ds-margin-top--0">{creator}</p>
        </div>
        <div>
          <span className="title">{t.BRFX(invoiceNumberMsg)}</span>
          <p className="ds-margin-top--0">{invoiceDisplayId}</p>
        </div>
        {generalErrors.map((error, i) => (
          <CL.Disclaimer className="ds-text--m" icon={<CL.Icon icon="warning" />} key={i} text={error.message} />
        ))}

        <Formik
          onSubmit={async values => {
            setIsSubmitInProgress(true);
            const res = await createSupportCaseAuthenticated(
              {
                action: t.AM3R(askAboutInvoiceMsg),
                feature: featureName,
                featureItemId: featureItemId,
                url: `${siteBaseUrl}${pathname}${search}`,
                featureType: values.subject,
                description: values.description,
              },
              accountMasterId || ''
            );
            setIsSubmitInProgress(false);
            onSubmitDialog(res, true, true);
          }}
          initialValues={{ subject: '', description: '' }}
        >
          <Form noValidate>
            <div className="ds-margin-top--3">
              <ProvidedDropdown
                required={true}
                label={t.CV5H(categoryMsg)}
                name="subject"
                placeholderText={t.QRYV(selectMsg)}
                items={[
                  t.EE4N(paymentDetailsMsg),
                  t.AM3R(invoiceInqueryMsg),
                  t.Z9BX(invoiceRelatedClaimMsg),
                  t.R58C(refundMsg),
                ]}
              />
            </div>

            <Message
              placeholder={t.TQLE(writeMessageMsg)}
              required={true}
              name="description"
              label={t.FJLH(messageMsg)}
            />

            <span>{t.ZWKY(descriptionInquiryVisibleInOmaElisaMsg)}</span>
            <ModalCloseAndSubmitButtons onCancel={onCloseDialog} onSubmit={() => {}} submitting={isSubmitInProgress} />
          </Form>
        </Formik>
      </div>
    </DialogWrapper>
  );
};
