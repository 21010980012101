import * as CL from '@design-system/component-library';
import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { continueMsg, customerMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { ChangeEvent, KeyboardEvent } from 'react';
import type { HeaderUserAccount } from '@design-system/component-library';

export interface ShoppingCartWithVoucherCompanySelectorProps {
  userAccounts: CL.HeaderUserAccount[];
  showInDialog: boolean;
}

export const ShoppingCartWithVoucherCompanySelector = ({
  userAccounts,
  showInDialog,
}: ShoppingCartWithVoucherCompanySelectorProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCompanySelectorInputChange = (
    _e: ChangeEvent | KeyboardEvent | React.MouseEvent,
    option: Partial<HeaderUserAccount>
  ) => {
    if (option?.accountMasterId) {
      dispatch(setActiveAccount(option.accountMasterId, true, paths.NEW_SHOPPING_CART, option.name));
    }
  };

  const onContinue = () => {
    // Reset state after company has been selected
    navigate(paths.NEW_SHOPPING_CART, { replace: true });
  };

  const companySelectorInner = (
    <>
      {!showInDialog && <h3 className="ds-margin-top--0">{t.ZC7D(customerMsg)}</h3>}
      <CompanySelector
        className="of-shopping-cart-select-company"
        userAccounts={userAccounts}
        onInputChange={onCompanySelectorInputChange}
      />
    </>
  );

  return showInDialog ? (
    <div className="shopping-cart-with-voucher-company-selector-dialog">
      <DialogWrapper header={t.BR9A('Please select company first')} onCloseDialog={() => {}}>
        {companySelectorInner}
        <div className="ds-display--flex ds-justify-content--flex-end">
          <CL.Button className="" onClick={onContinue}>
            {t.I62A(continueMsg)}
          </CL.Button>
        </div>
      </DialogWrapper>
    </div>
  ) : (
    <div className="ds-background-color--neutral-200 ds-padding-vertical--5">
      <div className="of-shopping-cart-select-company-wrapper">{companySelectorInner}</div>
    </div>
  );
};
